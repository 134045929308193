import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { scroller } from 'react-scroll';
import Dummy from 'dummyjs';

import TemplateMain from '../../basis/basis_t/TemplateMain';
import TopicAndAudience from '../../basis/basis_t/TopicAndAudience';

import Config from '../../Config';

/**
 * Static page: Über Uns
 *
 * @class      ViewUeberUns (name)
 */
class ViewUeberUns extends Component {

  componentDidMount() {
    scroller.scrollTo('scroll_start', {
      duration: 1000,
      smooth: true
    });
  }

  render() {

    let hero_data = {
      img: Config.ImgUeberUns,
      title: 'Über Uns',
      body: 'Der Familienkompass bietet Angebote und Unterstützungen für alle Menschen in Thüringen. Suchen Sie nach relevanten Angeboten und Themen.'
    }

    let body = (
      <div className="text" name="scroll_start">
        <p>Wir sind Städte, Gemeinden und Landkreise, die ihre Aktivitäten bewusst und nachhaltig
        auf die Gestaltung von guten Rahmenbedingungen für die Familien lenken. Deshalb sind wir
        u.a. auch als familiengerechte Kommune vom Verein Familiengerechte Kommune e.V. zertifiziert. </p>

        <p>Damit die Angebote und die mögliche Unterstützung für Familien auch bekannt sind,
        haben wir diesen Familienwegweiser aufgebaut.</p>

        <p>Zu den Familien gehören nicht nur Mutter, Vater, Kind, sondern  auch Oma, Opa oder alleinlebende
        Angehörige und Freunde. Deshalb finden Sie in dem Familienkompass Angebote zu den verschiedensten
        Themen und für alle Altersgruppen.</p>

        <p>Der Familienkompass bietet Ihnen die Möglichkeit, gezielt nach der Unterstützung zu suchen,
        die für Sie wichtig ist! Ob unter Schlagwörtern wie zum Beispiel "Krabbelgruppe" oder „Babyschwimmen“,
        oder einer Abfrage nach einem Thema (z.B. Gesundheit, Leben mit Behinderung, Pflege, Ehrenamt, Beratung)
        finden Sie schnell und unkompliziert einen passenden Vorschlag zu Kursen oder Beratungsangeboten.
        Sie haben die Gelegenheit direkt im Umkreis Ihres Wohn- und Arbeitsortes oder gezielt nach Angeboten
        für die Altersgruppe Ihres Kindes oder gezielt nach einem Kalendertag zu suchen.</p>

        <p>Nicht nur Familien haben die Möglichkeit im Familienkompass Anregungen zu finden,
        sondern auch Mitarbeiter und Fachkräfte aus verschiedensten Arbeitsfeldern. Die große
        Informationsvielfalt bietet Ihnen die Chance, auf Angebote zurückzugreifen, die gezielt für
        Ihre Beratungssituation ausgewiesen sind.</p>

        <p>Geben Sie Ihren Suchbegriff ein und lassen Sie sich überraschen, welche Angebote sie finden.
        Sollten Sie nicht zufrieden sein, dann schicken Sie uns eine E-Mail und teilen uns mit,
        zu welchem Thema Sie Angebote vermissen. Wenn Sie Angebote kennen, die nicht im Familienkompass sind,
        aber durchaus hinein gehören, auch über diese Info freuen wir uns. Bei Fragen oder Anregungen
        zum Umgang oder Inhalt mit dem Familienkompass nutzen Sie unser
        <Link to="/kontakt/"> Kontaktformular</Link>.</p>
      </div>);

    return(
      <TemplateMain showHero={true} heroData={hero_data}>
        <main className="LayoutDefault">
          <div className="row">
            <TopicAndAudience layout="sidebar" />
            <section className="content">
              <section className="ViewUeberUns">
                <div className="wrapper">
                    <div className="container" name="scroll_start">
                        	 <h1 className="title">Über uns/ Über das Portal </h1>
                        {body}
                    </div>
                </div>
              </section>
            </section>
          </div>
        </main>
      </TemplateMain>
	);
  }
}

export default ViewUeberUns;
