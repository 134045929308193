import React, { Component } from 'react';
import Dummy from 'dummyjs';

import {AppContext} from './AppContext';
import Config from '../../Config';

import CreateDetailsProvider from './CreateDetailsProvider';
import SearchFormEnhanced from './SearchFormEnhanced';
import SearchOffersFromProvider from './SearchOffersFromProvider';
import TemplateMain from './TemplateMain';
import TopicAndAudience from './TopicAndAudience';

import MessageSharePartial from '../../common/components/MessageSharePartial';


/**
 * Wrapper/View component for SearchDetails.
 *
 * @class      ViewDetailProvider (name)
 */
class ViewDetailProvider extends Component{

	constructor(props){
		super(props);

		this.c_jsondata = null;

		this.state = {
			providerId: ''
		};
	}

	componentDidMount() {
		//search a dataset_id from the API
		//this.props.match is coming from the Router
		//@see App.js > render + @see https://reacttraining.com/react-router/web/api/match
		if(this.props.match && this.props.match.params.id){
			this.setState({
				providerId: this.props.match.params.id
			});
			this.context.getProviderDetailsFromApi(this.props.match.params.id);
		}
		// if calls ViewPreview
		else if(this.props.p_datasetProvider){
			this.setState({
				providerId: this.props.p_datasetProvider[0].DatasetId
			});
			this.c_jsondata = this.props.p_datasetProvider;
		}
	}

	componentDidUpdate(){
		if(this.props.match && this.state.providerId != this.props.match.params.id)
		{
			if( this.props.match.params.id){
				this.setState({
					providerId: this.props.match.params.id
				}, () => {
					this.context.getProviderDetailsFromApi(this.props.match.params.id);
				});
			}
		}
	}

	render(){
		let details = null;
		let bWatermarkShow = false;
		var providerId = this.state.providerId;

		if(this.c_jsondata)
		{
			details = <CreateDetailsProvider _datasetId={this.c_jsondata[0]}/>
			bWatermarkShow = true;
		}else if(this.context.provider_results){
			details = <CreateDetailsProvider _datasetId={this.context.provider_results[0]}/>
		}
		else{
			details = <MessageSharePartial>Organisation wird geladen ...</MessageSharePartial>;
		}

		let hero_data = {
			img: Config.ImgPlacholder,
			title: 'Organisation',
			//body: Dummy.text(10, 20)
		}

		let returnView = '';
		if(this.state.providerId){
			returnView = (<div className="row">
											<TopicAndAudience layout="sidebar" _providerId={providerId}/>
											<div className="SearchDetails">
												<div className="wrapper">
													{details}
													<SearchOffersFromProvider _providerId={providerId}/>
												</div>
											</div>
										</div>);
		}else{
			bWatermarkShow = false;
			returnView = (<div className="row">
											<TopicAndAudience layout="sidebar" _providerId={providerId}/>
											<div className="SearchDetails">
												<div className="wrapper">
													<MessageSharePartial>Die Organisation wurde nicht gefunden</MessageSharePartial>
													<SearchOffersFromProvider/>
												</div>
											</div>
										</div>);
		}

		return(
      <TemplateMain showHero={true} heroData={hero_data} searchNavTop={true} p_addwatermark={bWatermarkShow}>

        <main className="ViewDetail">
          {returnView}
        </main>

      </TemplateMain>

		);
	}
}
ViewDetailProvider.contextType = AppContext;
export default ViewDetailProvider;
