import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { scroller } from 'react-scroll';
import Dummy from 'dummyjs';

import ConfigCards from '../../ConfigCards';

import {AppContext} from './AppContext';
import TemplateMain from './TemplateMain';
import CreateSearchCardsWrapper from './CreateSearchCardsWrapper';
import TopicAndAudience from './TopicAndAudience';
import ViewThemaFfo from './ViewThemaFfo';

import MessageSharePartial from '../../common/components/MessageSharePartial';


/**
 * Shows the sub page for the selected topic
 *
 * @class      ViewThema (name)
 */
class ViewThema extends Component {


    constructor(props)
    {
        super(props);
        this.searchFromCard = this.searchFromCard.bind(this);
    }

    componentDidMount(prevProps, prevState) {
        scroller.scrollTo('scroll_start', {
            duration: 1000,
            smooth: true
        });
    }

    searchFromCard(filters)
    {
        console.log('searchFromCard',filters);
        this.context.setMultipleFilters(filters, this.context.searchText);
    }

    render() {
        let out = null;
        //this.props.match is coming from the Router
        let topic = this.props.match.params.topic;
        let page_data = ConfigCards.CARDS_LEVEL_ONE[topic];

        //get page data
        if(page_data)
        {

          let siteContent = '';
          let search_cards = page_data.cardhandel_searchcards;
          if(page_data.message){
            siteContent = <MessageSharePartial> {page_data.message} </MessageSharePartial>;
          }else if(search_cards && Object.keys(search_cards).length > 0 ){
            if(topic === "familienfreundliche_orte"){
              return <ViewThemaFfo p_ffoconfigcards={page_data}/>
            }
            //wenn kein besonderer Link gesetzt wurde, dann werden weitere SearchCards oder Filter ausgeführt
            //auch "besondere_orte" wie bei 'familie gifhorn'
            siteContent = (
              <CreateSearchCardsWrapper p_wrapperconfigcards={search_cards}/>
            );

          }

          let hero_data = {
              img: page_data.cardheaderimg,
              title: page_data.cardheadertitle,
              body: page_data.cardheaderbody
          };
          //create page
          out = (
                <TemplateMain heroData={hero_data}>

                     <main className="ViewThema" name="scroll_start">
                      {siteContent}
                    </main>

                    <div className="container">
                        <TopicAndAudience />
                    </div>

                </TemplateMain>
            );
        }
        else
        {
            //redirect to 404
            out = <Redirect to="/404" />;
        }

        return out;
    }
}
ViewThema.contextType = AppContext;
export default ViewThema;
