import React, { Component } from 'react';
import { css } from '@emotion/core';
// Another way to import. This is recommended to reduce bundle size
import BounceLoader from 'react-spinners/BounceLoader';
import ScssVariables from '../../ConfigScss.scss';


/**
 * Loader Animation class
 *
 * @class      BusyAnimationSharePartial (name)
 * @prop 		{string} loading - active || inactive
 */
export default class BusyAnimationSharePartial extends Component {

  render() {

	const size = ScssVariables.loader_size;
	let margin = -size / 2;
  let color = ScssVariables.loader_color;

	const override = css`
		display: block;
		position: fixed;
		z-index: 1;
		top: 50%;
		left: 50%;
		margin: ${margin}px 0 0 ${margin}px;
	`;


    return(
			<div id="BusyAnimationSharePartial" className={this.props.loading ? 'active' : 'inactive'}>
		        <BounceLoader
		          css={override}
		          sizeUnit={"px"}
		          size={size}
		          color={color}
		          loading={this.props.loading}
		        />
		        <div className="busyanimation_background"></div>
			</div>
	    );
  }
}
