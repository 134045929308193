import React from 'react';
import { Redirect, NavLink } from "react-router-dom";

import Config from '../../Config';
import {AppContext} from '../../basis/basis_t/AppContext';
import ApiConnector from '../../common/model/ApiConnector';



// https://codesandbox.io/s/01kl2xmo40
// Aufruf der 'View' mit <SearchProvidersHierarchy />
class SearchProvidersHierarchy extends React.Component {

	constructor(props){
		super(props);

  	this.state = {
  		hierarchieItems: '',
  		selectedProviderItem: ''
  	}

    this.changeUnterorganisationData = this.changeUnterorganisationData.bind(this);
	}

  componentDidMount(){
	    const setProviderListAsLink = data => {
	        data && data.map(item => (
	          item.Title = (<div>
								<span className="link" key={item.Id} onClick={e => this.context.searchDetailProvider(item.Id)}>
									{item.Title}
								</span>
								<span style={{display:'none'}}>{item.Id}</span>
								{/*
									//Probe1
									<span className="link" key={item.Id} onClick={e => this.context.searchDetailProvider(item.id)}>
										{item.Title}
									</span>
										//Probe2
									<span className="link" key={item.Id}
									onClick={e => {this.changeUnterorganisationData(item.Id)}}>
									{item.Title}</span>
										//Probe3
									<NavLink to={"/detailorganisation/" + item.Id}>{item.Title}</NavLink>
							*/}
								</div>
						),
	          item.ChildNodes = item.ChildNodes && setProviderListAsLink(item.ChildNodes)
	      ))
	      return data;
	    };

	    //get UnterOrganisationen for "ProviderId"
	    ApiConnector.getProviders(this.props._providerId, (api_data) => {
				if(api_data){
					//nehme die oberste Organisation aus der Liste raus
				  //var unterOrganitionen = Object.values(api_data)[0];
					//if(unterOrganitionen)
					{
			      this.setState({
			        hierarchieItems: setProviderListAsLink(api_data)
			      });
					}
				}
	    });
  }

  changeUnterorganisationData(pId){
    console.log("changeUnterorganisationData: '" + pId + "'");
    //ToDo:
    //1. hier laden OrganisationsDetails
    //2. alle zugehörige Angebote
		this.setState({
			selectedProviderItem: pId
		});
		this.context.searchDetailProvider(pId);
  }

	render() {

		if(this.state.selectedProviderItem){
			console.log("render: " +this.state.selectedProviderItem);
			let route = "/detailorganisation/"+this.state.selectedProviderItem;
			console.log("route: "+route);
			return <Redirect to={route} push={true}/>
			//return 	<Redirect to="/"  />
		}

		let unterOrganisationen = 'Unterorganisationen werden geladen';
		if(this.state.hierarchieItems){
			unterOrganisationen = (<Tree data={this.state.hierarchieItems} level={0}/>);
		}

		return (
			<div className="SearchProvidersHierarchy">
					<div className="wrapper">
						<h2>Unter- organisationen</h2>
            <section className="providerHierarchy">
	          	{unterOrganisationen}
          	</section>
				</div>
			</div>
		);
	}
}
const Tree = ({data, level}) => (
		// verschiebe alle Ebenen des Listitems von Hierarchie nach
  <ul className={'level_'+level} style={{marginLeft: -(10*(4-level))}}>
    {data && data.map((item, index) => (
      <li key={index}>
        {item.Title}
        {item.ChildNodes && <Tree data={item.ChildNodes} level={(level+1)}/>}
      </li>
    ))}
  </ul>
);

SearchProvidersHierarchy.contextType = AppContext;
//export default withRouter(SearchProvidersHierarchy);
export default SearchProvidersHierarchy;

const exapmleData = [{
  title: "Node 1",
  childNodes: [
    { Title: "Childnode 1.1" },
    {
      Title: "Childnode 1.2",
      ChildNodes: [
        {
          Title: "Childnode 1.2.1",
          ChildNodes: [
            { Title: "Childnode 1.2.1.1" }
          ]
        }, { Title: "Childnode 1.2.2" }
      ]
    }
  ]
}];
