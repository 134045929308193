import React, { Component } from 'react';

import Config from '../Config';
import Helper from '../common/tools/Helper';
// https://react-google-maps-api-docs.netlify.com/
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';


/**
 * This component shows the map for the detail view
 *
 * @class      MapDetail (name)
 * @prop 		{float} lat - lat coordinate
 * @prop 		{float} lng - lng coordinate
 * @prop 		{int} width
 * @prop 		{int} height
 */
class MapDetail extends Component {

	render() {

		return(
			<LoadScript
	        	id="script-loader"
	        	googleMapsApiKey={Config.MAPS_API_KEY}
	      		>
		        <GoogleMap
		            id="map-example"
				    mapContainerStyle={{
				      width: this.props.width,
				      height: this.props.height
				    }}
				    options={{
				    	fullscreenControl: false,
				    	streetViewControl: false
				    }}
				    zoom={17}
				    center={{
				      lat: this.props.lat,
				      lng: this.props.lng
				    }}>
				     <Marker
								label = {this.props.label}
				      	position={{
				        	lat: this.props.lat,
				        	lng: this.props.lng
				      	}}
								onClick={event => Helper.openGmapNavigate(this.props.lat, this.props.lng)}
					    />
		        </GoogleMap>
	      	</LoadScript>

		);
	}
}

export default MapDetail;
