import React, { Component } from 'react';
import TemplateMain from './TemplateMain';
import { Redirect } from "react-router-dom";
import { scroller } from 'react-scroll';

import { AppContext } from './AppContext';

import Config from '../../Config';
import ConfigCards from '../../ConfigCards';

import TopicAndAudience from './TopicAndAudience';
import CreateFfoContextContent from './CreateFfoContextContent';

/**
 * Shows the sub page for the selected topic
 *
 * @class      ViewThemaFfo (name)
 */

 export default class ViewThemaFfo extends Component {

  constructor(props){
    super(props);
    this.m_configCards = ConfigCards;
  }


  componentDidMount(prevProps, prevState) {
    scroller.scrollTo('scroll_start', {
      duration: 1000,
      smooth: true
    });
  }

  render() {
    let out = null;
    //this.props.match is coming from the Router
    let page_data = this.props.p_ffoconfigcards;
    //get page data
    if(page_data){
      let hero_data = {
          img: page_data.cardheaderimg,
          title: page_data.cardheadertitle,
          body: page_data.cardheaderbody
      };
      //create page
      out = (
          <TemplateMain showHero={true} heroData={hero_data} searchNavTop={true} p_showSearchFormEnhanced={false}>
  					<main>
  						<div className="row">
  							<TopicAndAudience layout="sidebar"/>
                <div style={{/*style={{marginTop:'-60px'}} */}} name="scroll_start" />
                <CreateFfoContextContent
                          p_searchcards={page_data.cardhandel_searchcards}
                          p_useregionfilter={page_data.gmapuseregionfilter}/>
  						</div>
  					</main>
  				</TemplateMain>
        );
    }
    else
    {
        //redirect to 404
        out = <Redirect to="/404" />;
    }
    return out;
  }
}
ViewThemaFfo.contextType = AppContext;
