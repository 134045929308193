import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { scroller } from 'react-scroll';
import Dummy from 'dummyjs';

import Config from '../../Config';
import TemplateMain from '../../basis/basis_t/TemplateMain';
import TopicAndAudience from '../../basis/basis_t/TopicAndAudience';

/**
 * Static page: Über Uns
 *
 * @class      ViewUeberUns (name)
 */
class ViewUeberUns extends Component {

  componentDidMount() {
    scroller.scrollTo('scroll_start', {
      duration: 1000,
      smooth: true
    });
  }

  render() {

    let hero_data = {
      img: Config.ImgUeberUns,
      title: 'Über Uns',
      body: 'Der Familienwegweiser bietet Angebote und Unterstützung für alle Menschen im Landkreis Gifhorn. Suchen Sie nach Angeboten, Themen und Veranstaltungen, die Sie interessieren.'
    }

    let body = (
      <div className="text" name="scroll_start">

        <p>
          Der <strong>Familienwegweiser</strong> bietet Angebote und Unterstützung für alle Menschen im Landkreis Gifhorn. Suchen Sie nach Angeboten, Themen und Veranstaltungen, die Sie interessieren.
        </p>


        <div className="ruler"></div>
        <h2>Welche Angebote finden Sie im Familienwegweiser?</h2>
        <p>
          Sie suchen den Austausch mit anderen? Haben Fragen zur Entwicklung Ihres Kindes oder möchten Informationen zur Pflege von Angehörigen haben? Sie benötigen Unterstützung oder Beratung auf Ihrem Weg durch die Schwangerschaft oder möchten eine Selbsthilfegruppe zu einem bestimmten Thema finden?
        </p>
        <p>
          Dann ist der <strong>Familienwegweiser</strong> genau das Richtige für Sie. Das Portal unterstützt Sie online bei der Suche nach einem passenden Angebot in Ihrer Nähe hier im Landkreis Gifhorn.
        </p>


        <div className="ruler"></div>
        <h2>Wie suchen Sie im Familienwegweiser?</h2>
        <p>
          Zu den Familien gehören nicht nur Mutter, Vater, Kind, sondern auch Oma, Opa oder alleinlebende Angehörige und Freunde. Deshalb finden Sie im <strong>Familienwegweiser</strong> Angebote zu den verschiedensten Themen und für alle Altersgruppen.
        </p>
        <h3>Über die Themen</h3>
        <p>
          Die wichtigsten Anliegen von Familien haben wir für Sie schon thematisch zusammengefasst. Sie erhalten dort eine kurze thematische Übersicht und Verknüpfungen zu den Angeboten. Es lohnt sich, die Angebote auch ohne konkretes Anliegen zu durchstöbern.
        </p>
        <h3>Über die Suchfunktion </h3>
        <p>
          Hier haben Sie die Möglichkeit, gezielt nach der Unterstützung zu suchen, die für Sie wichtig ist. Ob unter Schlagwörtern wie zum Beispiel „Babyschwimmen“ oder „Pflegedienst“ sowie einer detaillierten Abfrage nach unterschiedlichen Interessen. Sie haben die Gelegenheit, direkt im Umkreis Ihres Wohn-  und Arbeitsortes und gezielt nach Angeboten für die jeweilige Altersgruppe zu suchen.
        </p>



        <div className="ruler"></div>
        <h2>Für wen ist der Familienwegweiser noch?</h2>
        <p>
          Nicht nur Familien haben die Möglichkeit im Familienwegweiser Anregungen zu finden, sondern auch Mitarbeiter und Fachkräfte aus verschiedensten Arbeitsfeldern.
        </p>
        <p>
          Die große Informationsvielfalt bietet Ihnen die Chance, auf Angebote zurückzugreifen, die gezielt für Ihre Beratungssituation ausgewiesen sind.
        </p>
        <p>
          Geben Sie Ihren Suchbergriff ein und lassen Sie sich überraschen, welche Angebote Sie finden.
        </p>


        <div className="ruler"></div>
        <h2>Fragen – Unterstützung - Anregungen</h2>
        <p>
          Sollten Sie nicht zufrieden sein, dann schicken Sie uns eine E-Mail und teilen Sie uns mit, zu welchem Thema Sie Angebote vermissen. Wenn Sie Angebote oder „Besondere Orte“ kennen, die nicht im <strong>Familienwegweiser</strong> sind, aber durchaus hineingehören. Auch über diese Info freuen wir uns.
        </p>
        <p>
          Bei Fragen oder Anregungen zum Umgang oder Inhalt mit dem <strong>Familienwegweiser</strong> nutzen Sie unser <Link to="/kontakt/">Kontaktformular</Link> oder melden Sie sich telefonisch bei uns.
        </p>
        <br/>
        <p>Viel Spaß mit Ihrem <strong>Familienwegweiser</strong>.</p>

    </div>);

    return(
      <TemplateMain showHero={true} heroData={hero_data}>
        <main className="LayoutDefault">
          <div className="row">
            <TopicAndAudience layout="sidebar" />
            <section className="content">
              <section className="ViewUeberUns">
                <div className="wrapper">
                    <div className="container" name="scroll_start">
                        	 <h1 className="title">Über uns</h1>
                        {body}
                    </div>
                </div>
              </section>
            </section>
          </div>
        </main>
      </TemplateMain>
	);
  }
}

export default ViewUeberUns;
