import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import AppBaseT from '../../basis/basis_t/AppBaseT';

import Config from '../../Config';

import BusyAnimationSharePartial from '../../common/components/BusyAnimationSharePartial';
import ApiConnector from '../../common/model/ApiConnector';
import DateFilter from '../../common/model/DateFilter';
import LocationFilter from '../../common/model/LocationFilter';
import SearchFilter from '../../common/model/SearchFilter';
import SearchFilterOption from '../../common/model/SearchFilterOption';
import Tracker from '../../common/model/Tracker.js';

import {AppContext} from '../../basis/basis_t/AppContext';
import RouteWrapper from '../../basis/basis_t/RouteWrapper';
import LayoutDefault from '../../basis/basis_t/LayoutDefault';
import ViewDetail from '../../basis/basis_t/ViewDetail';
import ViewDetailProvider from '../../basis/basis_t/ViewDetailProvider';
import ViewHome from '../../basis/basis_t/ViewHome';
import ViewKontakt from '../../basis/basis_t/ViewKontakt';
import ViewPreview from '../../basis/basis_t/ViewPreview';
import ViewResults from '../../basis/basis_t/ViewResults';
import ViewThema from '../../basis/basis_t/ViewThema';
import View404 from '../../basis/basis_t/View404';

//override ViewUeberUns
import ViewBesondereOrte from './ViewBesondereOrte';
import ViewDatenschutz from './ViewDatenschutz';
import ViewImpressum from './ViewImpressum';
import ViewNutzungsbedingungen from './ViewNutzungsbedingungen';
import ViewUeberUns from './ViewUeberUns';
import ViewKreisjugendfoerderung from './ViewKreisjugendfoerderung';


export default class App extends AppBaseT {
	constructor(props) {
		super(props);
	}

	render(){
		return (
		  	<div id="view_wrapper">
				<BusyAnimationSharePartial loading={this.state.loader_active}></BusyAnimationSharePartial>
				<Tracker active={Config.TRACKER.active} />
				<AppContext.Provider value={this.state}>
						{/*redirect_to_home */}
						{this.state.redirect}
						<Switch>
							<Route path="/" exact component={ViewHome} />
							<Route path="/detail/:id" component={ViewDetail} />
							<Route path="/detailorganisation/:id" component={ViewDetailProvider} />
							<Route path="/thema/:topic" component={ViewThema} />
							<Route path="/ergebnisse/" exact component={ViewResults} />
							<Route path="/ergebnisse/:page_num" component={ViewResults} />
							<Route path="/preview/" component={ViewPreview} />
							<Route path="/ueber_uns/" component={ViewUeberUns} />
							<RouteWrapper path="/kreisjugendfoerderung/" component={ViewKreisjugendfoerderung} layout={LayoutDefault} />
							<Route path="/besondere_orte/" component={ViewBesondereOrte}/>
							<RouteWrapper path="/kontakt/" component={ViewKontakt} layout={LayoutDefault} />
							<RouteWrapper path="/impressum/" component={ViewImpressum} layout={LayoutDefault} />
							<RouteWrapper path="/datenschutz/" component={ViewDatenschutz} layout={LayoutDefault}/>
							<RouteWrapper path="/nutzungsbedingungen/" component={ViewNutzungsbedingungen} layout={LayoutDefault}/>
							<Route path="/404/" component={View404} />
							<Route component={View404} />
						</Switch>
				</AppContext.Provider>
		  	</div>
		);
	}
}
