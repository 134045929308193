
import React from 'react';


import IImgHeaderLogoMandant from './sites/fgifhorn_t/media/logo.png';

import IImgFooterKommunen from './sites/vorlage_t/media/logos_kommunen.png';
import IImgFooterStaat from './sites/fgifhorn_t/media/logo_mandant.png';
import IImgFooterGebit from './sites/fgifhorn_t/media/logo_gebit.png';

import IImgHeaderHauptbild from './sites/fgifhorn_t/media/header/header_hauptbild.jpg';
import IImgUeberUns from './sites/fgifhorn_t/media/header/header_hauptbild.jpg';//'./sites/vorlage_t/media/header/header_ueberuns.jpg';

export default class Config{

	static isLocalhost = window.location.hostname.toLowerCase().indexOf("localhost")>-1 || window.location.hostname.toLowerCase().indexOf("127.0.0.1")>-1;
	static DEBUG = false || this.isLocalhost;
	static doTRACKER = false; //window.location.hostname.toLowerCase().indexOf("familie-gifhorn.fabis.pro")>-1;

	//*****************************************************
	//*		Familie Gifhorn: Vorbereitung zur Lifestellung
	//*		1. Die Kachel 'Jugendarbeit & Ferienbörse' wird ergänzt wenn die BundesStatistik-Datenbank angebunden wird
	//*****************************************************
	static LASTUPDATE = '2021.02.09';
	static VERSION = '1.0.0.0';
	static TRACKER = {
		url: 'https://analytics.gebit-ms.de/piwik.php',
		id: 0,
		active: this.doTRACKER
	};

	static BEARER_TOKEN = '77a709937b2c40eab4a4287c054f4539';
	static BEARER_TOKEN_QUICKSEARCH = 'e95d358ec6ff4c16bdc01660d24d7af4';
	static MAPS_API_KEY = this.doTRACKER ? "AIzaSyCuLmEW_b0HWVPSucAiy7ipxfU_nPjp4kc" : "AIzaSyAGduDfYNwFPXz3Dks0pJKoHSsbt04nsDQ";

	//LIVE
	static API_URL = 'https://familie-gifhorn.fabis.pro/api';

	// DEV
	//static API_URL = 'https://relaunch.fabis.pro/api';
  //static API_URL = 'http://localhost:58579/api';

	//MAIL
	static MAIL_URL = `${this.API_URL}/sendmail`;
	static CAPTCHA_KEY = "6Ld86ssZAAAAAHBHVqIHSigVVrTnBuwevT94n_zZ";


	// **************************************
	// Centrale Konstanten [Start]
	// **************************************
	static PROJECT_FOLDER_NAME = "fgifhorn_t";

	// Aus dieser Tabelle werden Kommunen für das Kontaktformular geladen
	// Wenn da eine Kommune vorhanden ist, wird die intern für das sendmail() benutzt,
	// sonst muss der Nutzer aktiv eine Kommune auswähöen, an wen die Anfrage gerichtet ist
	static TABLE_KOMMUNEN = "coreKommunen";

	//https://www.npmjs.com/package/http-status-codes
	static REQUESTED_RANGE_NOT_SATISFIABLE = "416";
	static REQUESTED_ERROR_GEOGRAPHY_INVALID = {c_id:"0335",
																							c_message:"Leider konnten keine Angebote mit der Umkreis-Suche gesucht werden. Bitte korrigieren oder ergänzen Sie Ihre Ort/PLZ-Eingabe!"};
	static IconResultSetNeu = 'KeyIconNeu';
	// **************************************
	// Centrale Konstanten [ENDE]
	// **************************************

	static FILTER_IDS = {
		district: "ac22a3b6-ca10-47c1-b276-d6db16959da3", //<=MerkmalTypeId, Regionalsuche
		audience: "3a75e88c-fc5e-4fa8-a953-c3cf52ce8d06", //<=MerkmalTypeId, Zielgruppe
		topic: "303d8bf5-f75d-4693-a690-b4f7824939e0" //Themen MerkmalTypeId, ListenId => '40c081e7-dd56-4156-b735-c335a2e2b7dc'
	};

	//MerkmalTypeId's  for Result Content
	static RESULT_IDS = {
		short_description: '5abc6f7f-df55-45b4-9970-9c72aabb0722',//MerkmalTypeId Kurzbeschreibung des Angebotes;
 		topic: '303d8bf5-f75d-4693-a690-b4f7824939e0',
        region: 'ac22a3b6-ca10-47c1-b276-d6db16959da3',
		address: '469f6f2c-a766-48d4-a01b-f5707c51f3c1',
		//bei allen Mandanten gleich
		event_start_date: '3e0fe833-bc7d-4f40-92ce-4fc558154b1c',
		event_start_time: '4dd8d128-f826-4ccb-b466-8493719d8552',
		event_end_date: '91f69948-50bc-4406-a996-1b9cc39ca1c5',
		event_end_time: 'b2e3a2b3-6e40-4561-a705-bf076d12032b',
		accessibility: '2abccdc1-cd85-4d6e-80da-6116986e0e1c' //MerkmalTypeId:
		//Kontakt-Gruppe von Organisationen
		,contact_group: '9890ed80-2290-427b-a2b9-641edd3a056f'
		// //öffnungszeiten für FFO
		// ,offer_opening_hours: 'f64100b4-31ad-4614-b127-4d40917777ba'
		// ,offer_phone: '4f2fdc99-0f03-49a4-a5fc-c34e5026e6be'
	};
	static RESULT_IDS_PROVIDER = {
		short_description: '219c4afa-0900-482c-ab29-5da0f91a38b5'
	};
	//Diese Merkmaltypen sind Aussnahmen. Sie kommen vom Backend, werden aber nicht in der Detailansicht angezeigt.
	//Sonst werden stumpf alle Merkmale aus den Gruppen in der Ansicht geladen
	static DETAILVIEW_MERKMALTYPE_OFF = ';'+this.RESULT_IDS.event_start_date+';'
																			+this.RESULT_IDS.event_start_time+';'
																			+this.RESULT_IDS.event_end_date+';'
																			+this.RESULT_IDS.event_end_time+';'
																			+this.RESULT_IDS.short_description+';'
																			+'';
	static DETAILVIEW_MERKMALTYPE_OFF_PROVIDER = ';'+this.RESULT_IDS_PROVIDER.short_description+';';

	//css-Klassennamen, z.B. SearchCard befindet sich gerade in CreateSearchCard.js
	static FONT_RESIZE_COMPONENTS = ['SearchCard', 'SearchTopic', 'SearchProvidersHierarchy', 'SearchAudience', 'ResultSet', 'SearchDetails', 'LayoutDefault'];

	//Filtertexte
	static FILTERTEXT_REGION_PLACEHOLDER_HEADER = "Kommune";

	// Medien
	static ImgHeaderLogoMandant = IImgHeaderLogoMandant;
	static TextHeaderToLogoMandantHtml = '';//(<h1>Text am Logo</h1>);

	static HERO_TITLE = "Angebote für die Familie - Ihre Eltern, Kinder, Freunde und für Sie";
	static HERO_BODY = "Finden, was Sie interessiert: heute, morgen, nächste Woche, immer";

	static ImgFooterKommunen = IImgFooterGebit;//IImgFooterKommunen;
	static ImgFooterStaat = IImgFooterStaat;
	static ImgFooterGebit = IImgFooterGebit;
	static FooterKundeText = "umgesetzt durch:"//"Teilnehmende Kommunen:";
	static FooterTextStaat = "gefördert von:";
	static FooterUmsetzungText = "Das Webportal Familie Gifhorn wurde von der GEBIT Münster GmbH & Co. KG in Kooperation mit Landkreis Gifhorn erstellt.";
	static SearchTopicTextContent = (<section className="content"><p><strong>Wir wollen, dass Sie passende Suchergebnisse bekommen. Deshalb haben wir Ihnen unser Angebot nach Themenbereichen gegliedert.</strong></p><p>Für einige der Themengebiete haben wir eigene Startseiten bzw. Themenseiten mit Unterkategorien für Sie erstellt. So finden Sie leicht und schnell was Sie suchen und entdecken auf dem Wege vielleicht auch spannendes Neues!</p></section>);
	static SearchAudienceTextContent = (<section className="content"><p><strong>Wer sucht Angebote für wen? Nach diesem Gesichtspunkt können Sie hier in den Familienwegweiser einsteigen.</strong></p></section>);


	static ImgHeaderHauptbild = IImgHeaderHauptbild;
	static ImgUeberUns = IImgUeberUns;

	static IconResultSetSvgOrt ='KeyIconSvgOrt';
	static IconResultSetSvgOrt_Filled = 'KeyIconSvgOrt_filled';

	static IconSvgRollstuhl ='KeyIconSvgRollstuhl';
	static IconSvgParkplatz ='KeyIconSvgParkplatz';
	static IconSvgWc ='KeyIconSvgWc';
	static IconSvgTaub ='KeyIconSvgTaub';
	static IconSvgBlind ='KeyIconSvgBlind';
	static IconSvgAufzug ='KeyIconSvgAufzug';
	static IconSvgs_Map = {
		'00070': this.IconSvgRollstuhl,
		'00020': this.IconSvgParkplatz,
		'00030': this.IconSvgWc,
		'00040': this.IconSvgTaub,
		'00050': this.IconSvgBlind,
		'00060': this.IconSvgAufzug
	};


	//
	// Konfiguration für Familien freundliche Orte (Kartenansicht)
	//
	static GMAP_PIN_BLUE = [{"gmPinUrl":"/gmaps/pin-blue.png", "gmMenuFontColor":"#006ba3"}];
	static GMAP_PIN_GRAY = [{"gmPinUrl":"/gmaps/pin-gray.png", "gmMenuFontColor":"#666"}];
	static GMAP_PIN_GREEN = [{"gmPinUrl":"/gmaps/pin-green.png", "gmMenuFontColor": "#58ad70"}];//"#afca0b"
	static GMAP_PIN_RED = [{"gmPinUrl":"/gmaps/pin-red.png", "gmMenuFontColor":"#e10528"}];
	static GMAP_PIN_YELLOW = [{"gmPinUrl":"/gmaps/pin-yellow.png", "gmMenuFontColor":"#ffcc00"}];
	static GMAP_PIN_LILA = [{"gmPinUrl":"/gmaps/pin-lila.png", "gmMenuFontColor":"#b83dba"}];
	static GMAP_PIN_GOLD = [{"gmPinUrl":"/gmaps/pin-gold.png", "gmMenuFontColor":"#d9b026"}];
	static GMAP_PIN_WEINROT = [{"gmPinUrl":"/gmaps/pin-burgundy.png", "gmMenuFontColor":"#e10528"}];


	static FAMFREUNDORTS_GM_CENTER_COORDS = { lat: 52.493902, lng: 10.5409053 };
	static FAMFREUNDORTS_GM_DEFAULT_ZOOM = 12;
	static FAMFREUNDORTS_MERKMALID_LAT = "d9af4015-a823-48e8-9039-cab05d7beef8";// X-Koordinate
	static FAMFREUNDORTS_MERKMALID_LONG = "f3368ce3-716b-4950-95f9-c3a8b94ca7bf";// Y-Koordinate
 	static FAMFREUNDORTS_OFFERTYPE_CONFIG = [
		{
			"ConfCategoryKey":"KeySpielplatz",
			"ConfOfferTypeId":"f6f38a88-84b5-4f4e-94b8-d1f769129c17",
			"ConfParentId":"392c3208-95d0-4061-8b06-d477f165ecfe",
			"ConfOfferTypeCategory":"Spielplätze",
			"ConfPinImgId":this.GMAP_PIN_GOLD
		},{
			"ConfCategoryKey":"KeyToilette",
			"ConfOfferTypeId":"6982a947-f3dd-48e7-853a-f9b8e28faa22",
			"ConfParentId":"670d0e17-4cf1-4be8-87b1-67247702ae9d", // = Provider = Organisation
			"ConfOfferTypeCategory":"Öffentliche Toiletten",  				//braucht man das noch ????
			"ConfPinImgId":this.GMAP_PIN_BLUE
		},{
			"ConfCategoryKey":"KeyWickelpunkt",
			"ConfOfferTypeId":"9730a174-04a5-4547-905c-922ac865138c",
			"ConfParentId":"32dce04a-a3b0-4093-ba7b-5d3fe4e948ef",
			"ConfOfferTypeCategory":"Wickelpunkte",
			"ConfPinImgId":this.GMAP_PIN_GRAY
		},{
			"ConfCategoryKey":"KeyStillpunkt",
			"ConfOfferTypeId":"8193d5ea-d042-40c7-b94d-bb6a17149bc8",
			"ConfParentId":"76d4525e-2329-433d-839f-ca610c61922d",
			"ConfOfferTypeCategory":"Stillpunkte",
			"ConfPinImgId":this.GMAP_PIN_LILA
		}
	];

	static FILTER_OFFER_TYPES = "offer_types";

	static QUICKSEARCH_ITEMS = 10;
	static PAGINATION_ITEMS = 5;
	static DISABLE_DEFAULT_DATEFILTER = false;

	static DATASET_PUBLISHED_DAYS_AGO = -28;

	//static KACHEL_COLUMNUMBERS_FFO = "column-4";//column-3
	static KACHEL_COLUMNUMBERS_BESORTE = "column-3";

	// **************************************
	// Nur fuer FamGifhorn
	// **************************************
	static SEARCHRESULTS_PROVIDERS_FOR_SPECIAL_OFFERS ={
			provider_spezial_text: "Ein Angebot des Landkreises Gifhorn",
			provider_spezial_guids:';3DE29337-5882-4032-9DBF-EB22C9E1824D;'
																											 +'E60F8D25-3298-4E12-882D-77B123E14720;'
																											 +'C0F7C2E6-BDAC-4781-9545-4CB5820CFDDB;'
																											 +'EB90C4D9-A075-4BA1-B0FA-30B254A48907;'
																											 +'3E464568-FA01-44AC-946F-25BC7B15965A;'
																											 +'8208CEC0-37C6-45FB-A36D-4026D59C53F2;'
																											 +'2888579C-62D6-429B-9036-9FD9F175077E;'
																											 +'E7A4374D-75C3-417D-B6C2-5D2DBFA3E307;'
																											 +'4016C597-550D-49A9-819D-F6B2058DACA1;'
																											 +'561B0FB2-67AD-4DA6-BF61-9A6EE9D1CB7D;'
																											 +'BCC4889E-3203-4810-81E2-FEB9EECA7A5E;'
																											 +'C056B71C-6021-4518-A197-94524A6C9353;'
																											 +'574E6C73-1885-464C-AC8E-5FE561CF6653;'
																											 +'36B4C167-BA7B-4621-BD2D-DC9EF2FBBDA0;'
																											 +'40A92FBC-17C0-41A5-A54B-EB5DC1A02D88;'
																											 +'AB83D255-EF71-45E4-A123-E7CE0ECEC8E0;'
	}

	}
