import React, { Component } from 'react';
import Config from '../../Config';

import CreateSearchCard from './CreateSearchCard';

export default class CreateSearchCardsWrapper extends Component{
	constructor(props) {
		super(props);
    this.m_config = Config;
	}

  render(){
    let configCards = this.props.p_wrapperconfigcards;

    let search_cards = [];
    if(configCards && Object.keys(configCards).length > 0){
  		let num = 1;
      for(let card_name in configCards){
        let card = configCards[card_name];
        search_cards.push(<CreateSearchCard key={num++} p_configCard={card}/>);
      }
    }else{
      search_cards = this.props.children;
    }


    let elemMsg = '';
    if(this.m_config.TextMainStartMsg !== null && this.m_config.TextMainStartMsg !== window.undefined){
      elemMsg = this.m_config.TextMainStartMsg;
    }


    return (
      <div className='SearchCardsWrapper' >
        <header>
          <h2 className="title"></h2>
          <p className="desc"></p>
        </header>

        <section className="cMainStartMsg">
          {elemMsg}
        </section>

        <div className="search-cards-row">

          {search_cards}

        </div>
      </div>
    );
  }

}
