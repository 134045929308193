import React, { Component } from 'react';
import Select from 'react-select';


//https://codesandbox.io/s/8256j5m3vl?file=/src/index.js:590-649
//https://react-select.com/styles
//https://codesandbox.io/s/nxssq?module=/example.js&file=/example.js:115-118
const customStyles = {
  // DropDownBox Header
  control: (provided, state) => ({
  	...provided,
    border: 'none',
    cursor: 'pointer',
    // borderColor: state.isFocused ? 'grey' : 'transparent',
    // borderRadius: 0,

    // backgroundColor:'white',
    //                 '&:hover': {
    //                   borderColor: 'green'
    //                 },
    //
    //boxShadow: 'none',
    width: '100%',
    minHeight:'36px',
    marginBottom:'4px',
  }),
  option: (provided, state) => ({
    ...provided
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: 'white' //überschreiben hässlichen grau bei den ausgewählten items
    };
  }
}

export default class DropDownReactSelectPartial  extends Component{


  customTheme(theme){
    return {
      ...theme,
      borderRadius: 0,
      colors:{
        ...theme.colors,
        primary25: '#aadaff', //border leichtgrau
        primary:'#008ac2'     //mausover blau
      }
    }
  }

  constructor(props) {
      super(props);

      this.state = {
        select: {
          value: ''
        }
      };
  }
  setValue = value => {
    this.setState(prevState => ({
      select: {
        ...prevState.select,
        value
      }
    }));
  };
  handleChange = value => {
    this.setValue(value);
    if(this.props.p_onchangeCallback){
      this.props.p_onchangeCallback(value);
    }else{
      console.error("DropDownReactSelectPartial: p_onchangeCallback is indefined");
    }
  };

  componentDidUpdate(prevProps, prevState){
    if(prevProps.p_resetValues !== this.props.p_resetValues){
        this.setValue(null);
    }
  }

  render(){
    const { select } = this.state;

    let classExtension = "region";//this.props.p_selectboxkey;
    let placeHolder = this.props.p_placeholder;
    let selectOptions = this.props.p_selectOptions;
    return (<fieldset  className={`fieldset-ffofilter-${classExtension}`}>
                                      <div id={`constrict-${classExtension}`}>
                                        <Select className={`select-${classExtension}`}
                                                classNamePrefix={classExtension}
                                                styles={customStyles}
                                                options={selectOptions}
                                                defaultValue={''}
                                                value={select.value}
                                                theme={this.customTheme}
                                                isMulti={true}
                                                closeMenuOnSelect={false}
                                                isSearchable={true}
                                                autoFocus={false}
                                                placeholder="Region auswählen"
                                                noOptionsMessage={() => 'kein Treffer gefunden'}
                                                name="select-district"
                                                onChange={this.handleChange} />
                                      </div>
                                  </fieldset>);
  }
}

/* DropdownBox kann ein- und ausgeblendet werden
// <fieldset className="detailsearch fieldset-detailsearch-4" style={{border: "1px solid #ccc"}}>
//             <a className=""
//                 data-toggle="collapse"
//                 href="#constrict-4"
//                 role="button"
//                 aria-expanded="true"
//                 aria-controls="constrict-4">
//                   <div className="col expander">Regionen</div>
//             </a>
//             <div className="collapse show" id="constrict-4">
//               <Select className="c4-select" classNamePrefix="c4" styles={customStyles} options={regionOptions}
//                       value={this.state.district}
//                       isMulti={true}
//                       placeholder="Region auswählen"
//                       noOptionsMessage={noOptionsFnctMessage}
//                       name="select-district"
//                       onChange={options => {this.handlerRegionFilter(options)}} />
//             </div>
// </fieldset>
*/
