import React, { Component } from 'react';
import { Link } from "react-router-dom";

import TemplateMain from './TemplateMain';
import TopicAndAudience from './TopicAndAudience';

/**
* Shows a 404 Page with the Main Template.
*
* @class      View404 (name)
*/
class View404 extends Component {
    render() {
        return(
            <TemplateMain>

                <main className="View404">
                    <h1 className="text-center mt-5">Diese Seite existiert leider nicht.</h1>

                    <Link to="/">&gt;&gt; zurück zur Homepage</Link>
                    <TopicAndAudience/>
                </main>

            </TemplateMain>
        );
    }
}

export default View404;
