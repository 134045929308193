import React from 'react';
import {AppContext} from './AppContext';

import Config from '../../Config';
import Helper from '../../common/tools/Helper';

export default class SearchTopic extends React.Component {

	constructor(props){
		super(props);

		this.topics = null;
	}

	searchTopic(option_id){
		//Filter ähnlich ConfigCards aufbauen:
		let filter = [{[Config.FILTER_IDS['topic']]: [option_id]}];
		this.context.callApiSearcResultshFromCardFiltersOr(filter);
	}

	createTopicLinks(){
		let topic_filter = this.context.filters[Config.FILTER_IDS['topic']];
		let option;
		this.topics = [];

		if(topic_filter){
			for(let id in topic_filter.options)
			{
				option = topic_filter.options[id];
				this.topics.push(<span className="link" key={id} onClick={e => {this.searchTopic(id)}}>{option.value}</span>);
			}
		}
	}

	render() {
		this.createTopicLinks();

		return (
			<div className="SearchTopic">
				<div className="wrapper">
					<h2>Themen-Suche</h2>
					<div className="row">
						{/*<section className="content">*/}
							{Config.SearchTopicTextContent}
						<section className="topics">
							{this.topics}
						</section>
					</div>
				</div>
			</div>
		);
	}
}
SearchTopic.contextType = AppContext;
