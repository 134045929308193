import React from 'react';

import SearchAudience from './SearchAudience';
import SearchProvidersHierarchy from '../../common/components/SearchProvidersHierarchy';
import SearchTopic from './SearchTopic';

export default class TopicAndAudience extends React.Component {

	// props.layout can be "block" (default) or "sidebar"

	render() {
		let layout_class = this.props.layout;
		if(!layout_class)
		{
			layout_class = "block";
		}

		let providerId = this.props._providerId;
		let searchProviders = '';
		if(providerId){
			searchProviders = (<SearchProvidersHierarchy _providerId={providerId} />);
		}

		return (
			<div className={"TopicAndAudience " +  layout_class}>
				<div className="row">
					{searchProviders}
					<SearchTopic />
					<SearchAudience />
				</div>
			</div>
		);
	}
}
