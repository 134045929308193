import React, { Component } from 'react';
import { scroller } from 'react-scroll';
import $ from 'jquery';

import Config from '../../Config';
import ConfigCards from '../../ConfigCards';

import CreateSearchCard from './CreateSearchCard';
import CreateSearchCardsWrapper from './CreateSearchCardsWrapper';

import Helper from '../../common/tools/Helper';
// Attempted import error: does not contain a default export (imported as 'TestContext').
// import FfoContext ... => import {FfoContext} ...
import {FfoContext} from '../../common/components/FfoContext';
import FfoContextGmapPartial from '../../common/components/FfoContextGmapPartial';
import GmapMarkerInfoModel from '../../common/model/GmapMarkerInfoModel';
import MessageSharePartial from '../../common/components/MessageSharePartial';
import ApiConnector from '../../common/model/ApiConnector';
import HelperFilter from '../../common/model/HelperFilter';
import ResultHelper from '../../common/results/ResultHelper';

export default class CreateFfoContextContent  extends Component{

  constructor(props){
    super(props);
    this.m_static_ResultHelper = ResultHelper;
    this.m_static_HelperFilter = HelperFilter;
    this.m_config = Config;
    this.m_config_offerTypeConfDatas = this.m_config.FAMFREUNDORTS_OFFERTYPE_CONFIG;
    this.m_config_resultIds = this.m_config.RESULT_IDS;


    // alle Markers, die lokal für die Anzeige gefiltert werden, werden ein mal initialisiert
    this.m_markers_offersdatas_all = [];

    this.m_filterRegionSelectedValues = null;

    // reference auf die Dom-componente von FfoContextGmapPartial - Child, damit man WindowDetails
    this.m_refFfoContextGmapPartialWithWindowDetail = React.createRef();
    //eventhandler zur Anzeige von GmapWindowDetailPartial-Componente für die nähere Inforamtioneneines Markers
    this.handlerChildOnClickShowWindowDetail = this.handlerChildOnClickShowWindowDetail.bind(this);



    // event-function für die Filterung aus den Kacheln
    this.handlerCardsUpdateFilterParentId = this.handlerCardsUpdateFilterParentId.bind(this);
    // event-function für die Filterung aus der GmapMenu
    this.handlerMenuUpdateFilterParentId = this.handlerMenuUpdateFilterParentId.bind(this);
    // event-function für die Filterung nach den Regionen aus dem DropDownBox
    this.handlerRegionFilter = this.handlerRegionFilter.bind(this);

    this.state = {
      s_ffoShowMarkers: null,
      s_menuPoimap_element_ref: null,

      // Region Filter
      s_reactSelectRegionOptions_all: null,
      s_resetRegionFilterToogle: false,

			handlerCardsUpdateFilterParentId: this.handlerCardsUpdateFilterParentId,
      handlerMenuUpdateFilterParentId: this.handlerMenuUpdateFilterParentId,
			handlerRegionFilter: this.handlerRegionFilter
    };
    this.m_cardsffo = this.initCardsFfo();
  }
  initCardsFfo(){
    let letSearchcards = this.props.p_searchcards;

    let search_cards = [];
    if(letSearchcards && Object.keys(letSearchcards).length > 0 ){
  		let num = 1;
      for(let card_name in letSearchcards){
        let card = letSearchcards[card_name];
        search_cards.push(<CreateSearchCard key={num++}
                                      p_configCard={card}
                                      p_classextension="column-3"
                                      p_cardlinktext="Orte anzeigen"
                                      p_callback={this.handlerCardsUpdateFilterParentId}
                                      p_callbackparam={card.cardhandel_ffo['ConfParentId']}
                          />);
      }
    }
    return <CreateSearchCardsWrapper>{search_cards}</CreateSearchCardsWrapper>;;
  }

  componentDidMount(prevProps, prevState) {
    //Angebote zu allen FamFreunOrten aus der Api holen
    ApiConnector.getFamFreundOrteOffers((api_offerdata) => {
      let markers = this.initAllMarkersWithOffersDatas(api_offerdata);
      this.m_markers_offersdatas_all = markers;
      console.log("FFO Angebote from Backend:",api_offerdata);
      this.setState({
         s_ffoShowMarkers: markers
      });
    });
    if(this.props.p_useregionfilter ){
      ApiConnector.getListItems(this.m_config.FILTER_IDS.district, (api_data) => {
        this.setState({
           s_reactSelectRegionOptions_all: this.initDropDownRegionOptions(api_data)
        });
      });
    }
  }
  /* bereitet einmalig alle MarkersInfos als GmapMarkerInfoModel für die GmapFfoPartial vor **/
  initAllMarkersWithOffersDatas(pFfoApiOffers){
  	if(pFfoApiOffers && pFfoApiOffers.length > 0){
      //create markers
      let markers = [];
      if(this.m_config_offerTypeConfDatas === window.undefined){
        this.m_config_offerTypeConfDatas = this.m_config.FAMFREUNDORTS_OFFERTYPE_CONFIG;
      }

      let ffoCount = pFfoApiOffers.length;
      for(let i=0;i < ffoCount;i++){
				let dataset = pFfoApiOffers[i];
				let configFfoData = this.m_config_offerTypeConfDatas.find(offerTypeItem => offerTypeItem.ConfParentId.toUpperCase() === dataset.ParentId.toUpperCase());
        if(configFfoData === window.undefined){
          console.log(">>> DevError[FFO] "+i+"["+ffoCount+"] Offer has Parent", dataset.DatasetId, dataset.ParentId );
          break;
        }

        let showAdress = true;
				let dsLat = 0, dsLong = 0;
        dsLat = this.m_static_ResultHelper.searchMerkmalId(dataset, this.m_config.FAMFREUNDORTS_MERKMALID_LAT);
        dsLat = this.m_static_ResultHelper.getFirstValue(dsLat);
				if(dsLat && dsLat !== "0"){
				//zuerst die Koordinaten aus den Merkmalen auslesen, falls diese gibt
	         dsLong = this.m_static_ResultHelper.searchMerkmalId(dataset, this.m_config.FAMFREUNDORTS_MERKMALID_LONG);
	         dsLong = this.m_static_ResultHelper.getFirstValue(dsLong);
           showAdress = false;
				}else{
					//sonst aus dem Header
					dsLat = dataset.Latitude;
					dsLong = dataset.Longitude;
				}

        let adressHtmlInfoDetails = '';
        let adressHtmlInfo = '';
        if(showAdress){
  				let merkmalAdresse = this.m_static_ResultHelper.searchMerkmalId(dataset, this.m_config_resultIds['address']);
  				let dsAdresse = this.m_static_ResultHelper.getAddress(merkmalAdresse);
          adressHtmlInfoDetails = (<div><strong>Adresse:</strong><p>{dsAdresse.street}<br/>{dsAdresse.city}</p></div>);
          adressHtmlInfo = (<p>{dsAdresse.street}<br/>{dsAdresse.city}</p>);
        }

        let merkmalRegion = this.m_static_ResultHelper.searchMerkmalId(dataset, this.m_config_resultIds['region']);
				let dsARegionValue = this.m_static_ResultHelper.getFirstValue(merkmalRegion);
				let dsARegionToken = this.m_static_ResultHelper.getFirstFieldData(merkmalRegion);

				// let merkmalOpeningHours = this.m_static_ResultHelper.searchMerkmalId(dataset, this.m_config_resultIds['offer_opening_hours']);
				// let dsOpeningHours = this.m_static_ResultHelper.getFirstValue(merkmalOpeningHours);
				// <p>Öffnungszeiten:</p>
				// <p>{dsOpeningHours}</p>
				// let merkmalphone = this.m_static_ResultHelper.searchMerkmalId(dataset, this.m_config_resultIds['offer_phone']);
				// let dsPhone = this.m_static_ResultHelper.getFirstValue(merkmalphone);
				// <p>Telefon:</p>
				// <p>{dsPhone}</p>
				let dsMarkerInfoDetails = <div className="cGmapMarkerInfoWindowDetails" p_stylegmapwindowdetailpartial={{borderTop: `10px solid ${configFfoData.ConfPinImgId[0].gmMenuFontColor}`}}>
																		<h3>{dataset.ShortName}</h3>
																		{adressHtmlInfoDetails}
																		<strong>Region</strong>
																		<p>{dsARegionValue}</p>
																	</div>;

        // style={/*{background: `white`,padding: '10px', }*/}
				let dsMarkerInfoHtml = <div className="cGmapMarkerInfoWindow">
																	<h3 title="Navigation starten" className="link" style={{cursor:'pointer'}} onClick={(e) => Helper.openGmapNavigate(dsLat, dsLong)}>{dataset.ShortName}</h3>
																	{adressHtmlInfo}
																	<a className="link" onClick={(e) => this.handlerChildOnClickShowWindowDetail(dsMarkerInfoDetails)} >
                                  mehr Informationen</a>
														</div>;



				let marker = new GmapMarkerInfoModel(
												dataset.DatasetId,
												dataset.ParentId,
												dsLat,
												dsLong,
												dataset.ShortName,
												configFfoData.ConfPinImgId[0].gmPinUrl,
                        configFfoData.ConfPinImgId[0].gmMenuFontColor,
												dsMarkerInfoHtml,
												dsMarkerInfoDetails,
                        dsARegionToken);
			  markers.push(marker);
      }
      return markers;
    }
	}
  /* bereitet eimalig den Filterauswahl für die Regionen vor **/
  initDropDownRegionOptions(pApiRegionen){
    // let filterSearchWithOptions = this.m_static_HelperFilter.convertApiItemsToSearchWithOptions(this.m_config.FILTER_IDS, 'district', pApiRegionen);
    // let filterRegionOptions = this.m_static_HelperFilter.selectFirstLevelFromSearchFilterOption(filterSearchWithOptions);
    // return this.m_static_HelperFilter.convertFilterWithOptionsToSelect(filterRegionOptions);
    let filterSearchWithOptions = this.m_static_HelperFilter.convertApiItemsToSearchOptions(pApiRegionen);
    let filterRegionOptions = this.m_static_HelperFilter.selectFirstLevelFromOptions(filterSearchWithOptions);
    let selectOptions = this.m_static_HelperFilter.convertOptionsToSelect(filterRegionOptions);
    return selectOptions;
  }


  handlerChildOnClickShowWindowDetail(pMarkerDatasetId){
    if(this.m_refFfoContextGmapPartialWithWindowDetail){
      //event weiterleiten an das Kindelement
      this.m_refFfoContextGmapPartialWithWindowDetail.onClickShowWindowDetail(pMarkerDatasetId);
    }
  }

  /* alle Filter werden zurückgesetzt
  * und Marker mit allen FFOs nach einer Kategory (ParentId) gefiltert angezeigt **/
  handlerCardsUpdateFilterParentId(pProviderParentId){
    if(this.state.s_menuPoimap_element_ref){
  		let inputs = this.state.s_menuPoimap_element_ref.getElementsByTagName('input');
  		let inputsChecked = $(this.state.s_menuPoimap_element_ref).find('input:checked');

      //// 1.deaktiviere event, braucht nicht mehr, da es onclick()- und nicht onchange()-Event registriert ist
      //// window.removeEventListener('handleCheckbox', this.context.handlerMenuUpdateFilterParentId, false);
      //// 2. set unchecked alle checkbox's
      let lengthInput = inputs.length;
      for(let i = 0; i < lengthInput; i++){
      	inputs[i].checked = false;
      }
      //// 3. aktiviere event
      //// window.addEventListener('handleCheckbox', this.context.handlerMenuUpdateFilterParentId, false);
      // 4. set checkbox mit value pProviderParentId checked
      // durchlaufe alle MenuInputs
      lengthInput = inputs.length;
      for(let i = 0; i < lengthInput; i++){
      	let filter = `poiprovider-${pProviderParentId}`;
      	let varid = inputs[i].id
        // und finde zu der Kachel die zutrefende Menuauswahl
      	if( varid === filter ){
      		inputs[i].checked = true;
      		let allMarkers = this.m_markers_offersdatas_all;

            let markersShow = [];
            if(allMarkers){
              // durchlaufe alle Markers
              let lengthMarkers = allMarkers.length;
          		for (let i=0;i < lengthMarkers;i++) {
          			let marker = allMarkers[i];
                // und finde alle Marker, die zu der Ausgewählter ParentId aus der Kachel gehören
          			if(marker.m_parentId.toUpperCase() === pProviderParentId.toUpperCase()){
            			markersShow.push(marker);
          			}
          		}
            }else{
              alert("FFO muss noch konfiguriert werden !");
              console.error("CreateFfoContextContent: zu der ProviderParentId["+pProviderParentId+"] wurden keine Orte gefunden");
            }
            // setze die lokal gespeicherte RegionFilter-Werte zurück
            this.m_filterRegionSelectedValues = null;
        	  this.setState({
              // aktualisiere Marker, die angezeigt werden müssen
        			s_ffoShowMarkers: markersShow,
              // setze den ReactSelect-RegionFilter zurück (clear)
              s_resetRegionFilterToogle: !this.state.s_resetRegionFilterToogle,
        		});

          break;
        }
      }
    }
    scroller.scrollTo('scroll_gmap', {
      duration: 1000,
      smooth: true
    });
  }

  //wird vom child-componente aufgerufen
  handlerMenuUpdateFilterParentId(e){
    let pPoiParentId = e.target.value;
		let pChecked = e.target.checked;


		let markersShow = [...this.state.s_ffoShowMarkers]; //clone|copy state variable|object
		if(pChecked){
      //finde zuerst alle Merkers zu der ParentId:
      let markersForParent = this.applySelectFilterGmapMenu(this.m_markers_offersdatas_all, pPoiParentId);
      let markersRegional = this.applySelectFilterRegion(markersForParent);

      markersShow = markersShow.concat(markersRegional);
			this.setState({
				s_ffoShowMarkers: markersShow
			});
		}else{
			let markersNew = [];
      let lengthMarkersShow = markersShow.length;
			for (let i=0;i < lengthMarkersShow;i++) {
				let marker = markersShow[i];
				if(marker.m_parentId.toUpperCase() !== pPoiParentId.toUpperCase()){
					markersNew.push(marker);
				}
			}
			this.setState({
				s_ffoShowMarkers: markersNew
			});
		}
  }
  //Regionfilter wird als Hauptfilter in der GrundVomponente gesteuert
  handlerRegionFilter(pRegionOptionsSelected){
    // aktualisiere zuerst die RegionSelectedValues
    this.m_filterRegionSelectedValues = pRegionOptionsSelected;
    // und suche alle treffen zu den Werten
    let resultMarkersFilteredRegion = this.applySelectFilterRegion(this.m_markers_offersdatas_all);
    let resultMarkersForShow = this.applySelectFilterGmapMenu(resultMarkersFilteredRegion);

    this.setState({
       s_ffoShowMarkers: resultMarkersForShow
    });
  }
  applySelectFilterRegion(pMarkersForRegionFilters){
    let selectedRegionOptions = this.m_filterRegionSelectedValues;
    let resultMarkersFilteredRegion = [];
    if(selectedRegionOptions && selectedRegionOptions.length > 0){
      for(let i = 0; i < selectedRegionOptions.length; i++){
        let markersFilteredRegion = pMarkersForRegionFilters.filter(item => item.m_regionToken === selectedRegionOptions[i].value);
        if(markersFilteredRegion && markersFilteredRegion.length > 0){
            resultMarkersFilteredRegion = resultMarkersFilteredRegion.concat(markersFilteredRegion);
        }
      }
    }else{
      resultMarkersFilteredRegion = pMarkersForRegionFilters;
    }
    return resultMarkersFilteredRegion;
  }
  applySelectFilterGmapMenu(pMarkersForParentIdFilter, pParentIdValuE = -1){
    let resultMarkersForShow = [];

    if(this.state.s_menuPoimap_element_ref && pMarkersForParentIdFilter){
    	let inputs = this.state.s_menuPoimap_element_ref.getElementsByTagName('input');
    	let inputsChecked = $(this.state.s_menuPoimap_element_ref).find('input:checked');

      //fülle serchedParentIdS mit den ParentIdS, die als Filter aus der Menu angewendet werden müssen
      let serchedParentIdS = '';
      if(pParentIdValuE === -1){
        let lengthInput = inputs.length;
        for(let i = 0; i < lengthInput; i++){
          let inputElement = inputs[i];
          let inputId = inputElement.id.toUpperCase();
          if(inputElement.checked){
            serchedParentIdS += `;${inputId};`;
          }
        }
      }else{
        serchedParentIdS = `;POIPROVIDER-${pParentIdValuE.toUpperCase()};`;
      }

      let markersCount = pMarkersForParentIdFilter.length;
      // durchlaufe alle nach Region gefilterten Markers und
      //suche nur mit den richtigen ParantIds für die Anzeige raus
      for(let i = 0; i < markersCount; i++){
        let marker = pMarkersForParentIdFilter[i];
        let markersParentId = marker.m_parentId.toUpperCase();

        if(serchedParentIdS.indexOf(`;POIPROVIDER-${markersParentId};`) > -1){
          resultMarkersForShow.push(marker);
        }
      }
    }


    return resultMarkersForShow;
  }

  render() {
    let msgLoading = <MessageSharePartial>Familien freundliche Orte werden geladen . . .</MessageSharePartial>;


    //DOM-reference um den Event handlerChildOnClickShowWindowDetail an das Childcomponente weiterzuleiten
    let loadGmap = (<FfoContextGmapPartial name="scroll_gmap"
                      ref = {c => (this.m_refFfoContextGmapPartialWithWindowDetail = c)}
                    />);

    return (
      //https://de.reactjs.org/docs/context.html
      <FfoContext.Provider value={this.state}>
        <div className="body-stage-row">
            {this.m_cardsffo}
            <div name="scroll_gmap"/>
            {loadGmap}
        </div>
      </FfoContext.Provider>
    );
  }
}
