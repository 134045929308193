import React, { Component } from 'react';
import queryString from 'query-string';

import Config from '../../Config';
import ApiConnector from '../../common/model/ApiConnector';

import ViewDetail from './ViewDetail';
import ViewDetailProvider from './ViewDetailProvider';

import MessageSharePartial from '../../common/components/MessageSharePartial';

/**
 * This component shows a preview of a detail page for the backend.
 * It looks for a hashcode in the url and make an API search with the hashcode.
 * If there is no hashcode, it looks for a datasetid in the url.
 *
 * @class      ViewPreview (name)
 */
class ViewPreview extends Component {

	constructor(props){
		super(props);

		this.state = {
			search_results: '',
			loader_active: false
		};

		this.url_location = "";

		//This binding is necessary to make `this` work in the callback
		this.receiveSearchResult = this.receiveSearchResult.bind(this);
		this.receiveError = this.receiveError.bind(this);
	}

	componentDidMount(){
		//props location is coming from React Router
		this.url_location = this.props.location;

		let url_params = queryString.parse(this.url_location.search);

		this.showLoader(true);

		//if hashcode is found, search for hashcode preview - otherwise try datasetid
		if(url_params.hashcode){
  			ApiConnector.searchHashcode(url_params.hashcode, this.receiveSearchResult, this.receiveError);
    	}
	}

	showLoader(active = true)
	{
		this.setState({
			loader_active: active
		});
	}

	receiveSearchResult(json_data){
		if(!json_data)
			json_data = {};

		this.showLoader(false);

		this.setState({
			search_results: json_data,
    	results_to_show: 'detail'
    });
	}

	receiveError(error){
		this.showLoader(false);
		console.log('ViewDetail Error', error);
	}

	render() {
    let loaderText = (<MessageSharePartial>Die Vorschau wird geladen . . .</MessageSharePartial>);
    if(this.state.search_results && this.state.search_results.length > 0){
      if(this.state.search_results[0].DatasetSubType==="Offer"){
        return (<ViewDetail p_datasetOffer={this.state.search_results} />);
      }else if(this.state.search_results[0].DatasetSubType==="Organisation"){
        return (<ViewDetailProvider p_datasetProvider={this.state.search_results} />);
      }
    }
    else if(this.state.search_results.ID && this.state.search_results.ID === Config.REQUESTED_RANGE_NOT_SATISFIABLE){
      loaderText = (<MessageSharePartial>Die Vorschauanzeige ist abgelaufen, bitte erneut anfordern</MessageSharePartial>);
    }
		return(
			<div id="view-detail" className="container">
        <header>{loaderText}</header>
			</div>
		);

	}
}

export default ViewPreview;
