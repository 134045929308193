import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Mailto from 'react-protected-mailto';
import { scroller } from 'react-scroll';

import TemplateMain from '../../basis/basis_t/TemplateMain';

/**
 * Static page: Datenschutz
 *
 * @class      ViewDatenschutz (name)
 */
class ViewDatenschutz extends Component {

  componentDidMount() {
    scroller.scrollTo('scroll_start', {
      duration: 1000,
      smooth: true
    });
  }

  render() {

    return(
      <section className="ViewDatenschutz" name='scroll_start'>
        <div className="wrapper">
          <div className="container">
            <h1>
                Datenschutzerklärung
            </h1>

            <p>
              Wir freuen uns, dass Sie unsere Webseiten besuchen und bedanken uns für Ihr Interesse an unserem Portal für den <strong>„Familienkompass</strong>“ in Thüringen.
            </p>
            <p>
              Datenschutz ist für uns nicht nur gesetzliche Verpflichtung, sondern ein wichtiges Instrument zur Erhöhung der Transparenz bei unserem täglichen Umgang mit Ihren persönlichen Daten. Der Schutz Ihrer personenbezogenen Daten (im Folgenden kurz „Daten“) ist uns ein großes und sehr wichtiges Anliegen. Nachfolgend möchten wir Sie daher ausführlich darüber informieren, welche Daten bei Ihrem Besuch unseres Internetauftritts und Nutzung unserer dortigen Angebote erhoben und wie diese von uns im Folgenden verarbeitet oder genutzt werden, ebenso wie, welche begleitenden Schutzmaßnahmen wir auch in technischer und organisatorischer Hinsicht getroffen haben.
            </p>


            <h1>1.	Verantwortliche Stelle im Sinne des Datenschutzgesetzes</h1>
            <p>Die Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
            <p><a href="https://www.altenburgerland.de/sixcms/detail.php?id=10322&_footer_nav_id=3203&_lang=de"
                target="_blank"
              >Landkreis Altenburger Land
            </a></p>
            <p><a href="https://www.gera.de/sixcms/detail.php?id=228244&_footer_nav_id=228246&_lang=de"
                target="_blank"
              >Stadt Gera
            </a></p>
            <p><a href="https://rathaus.jena.de/de/impressum"
                target="_blank"
              >Stadt Jena
            </a></p>
            <p><a href="https://www.kyffhaeuser.de/kyf/index.php/impressum.html"
                target="_blank"
              >Landkreis Kyffhäuser Kreis
            </a></p>
            <p><a href="https://www.saale-orla-kreis.de/de/impressum.html"
                target="_blank"
              >Saale-Orla-Kreis
            </a></p>
            <p><a href="https://www.kreis-slf.de/landratsamt/"
                target="_blank"
              >Landkreis Saalfeld-Rudolstadt
            </a></p>




            <h1>
                <a name="_Kontaktdaten_Datenschutzbeauftragte"></a>
                <a name="_Datenschutzbeauftragter"></a>
                2.	Erhebung und Verwendung Ihrer Daten
            </h1>
            <p>
              Umfang und Art der Erhebung und Verwendung Ihrer Daten unterscheidet sich danach, ob Sie unser Angebot nur zum Abruf von Informationen besuchen oder die von uns angebotenen Leistungen in Anspruch nehmen:
            </p>


            <h2>a.	 Informatorische Nutzung</h2>
            <p>
              Für die nur informatorische Nutzung unserer Webseiten ist es grundsätzlich nicht erforderlich, dass Sie personenbezogene Daten angeben.
            </p>
            <p>
              Vielmehr erheben und verwenden wir in diesem Fall nur diejenigen Ihrer Daten, die uns Ihr Internetbrowser automatisch übermittelt, wie etwa:
            </p>
            <p>
              -	Datum und Uhrzeit des Abrufs einer unserer Webseiten
              <br/>-	Ihren Browsertyp
              <br/>-	die Browser-Einstellungen
              <br/>-	das verwendete Betriebssystem
              <br/>-	die von Ihnen zuletzt besuchte Seite
              <br/>-	die übertragene Datenmenge und der Zugriffsstatus (Datei übertragen, Datei nicht gefunden etc.) sowie
              <br/>-	Ihre IP-Adresse.
            </p>
            <p>
              Diese Daten erheben und verwenden wir bei einem informatorischen Besuch ausschließlich in nicht-personenbezogener Form. Dies erfolgt, um die Nutzung der von Ihnen abgerufenen Webseiten überhaupt zu ermöglichen, zu statistischen Zwecken sowie zur Verbesserung unseres Internetangebots. Die IP-Adresse speichern wir nur für die Dauer Ihres Besuchs, eine personenbezogene Auswertung findet nicht statt.
            </p>


            <h2>b.	 Ihre Betroffenenrechte als Kunde</h2>
            <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
            <ul>
              <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,
              </li><li>Berichtigung unrichtiger personenbezogener Daten,
              </li><li>Löschung Ihrer bei uns gespeicherten Daten,
              </li><li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen,
              </li><li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und
              </li><li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben.
              </li>
            </ul>
            <p>
              Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.
            </p>
            <p>
              Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige Aufsichtsbehörde wenden. Ihre zuständige Aufsichtsbehörde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen Verletzung. Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter:
              <a  href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                  target="_blank"> https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
            </p>


            <h2>c.	 Kontaktformular</h2>
            <p>
              Die im Kontaktformular erhobenen Angaben zu Name, E-Mail und ggf. Telefonnummer (freiwillig) sowie Ihrer Nachricht werden von uns nur zum Zweck der Beantwortung Ihrer Anfrage verwendet. Nach Abschluss der Bearbeitung werden die Angaben gelöscht. Lediglich im Rahmen der gesetzlich vorgeschriebenen Archivierung des E-Mailverkehrs (E-Mails sind Geschäfts- und Handelsbriefe) werden Daten darüber hinaus für max. 10 Jahre aufbewahrt.
            </p>
            <p>
              Ihre Daten werden zu vorgenanntem Zweck ggf. an uns unterstützende Dienstleister weitergegeben, die wir selbstverständlich sorgfältig ausgewählt haben. Dabei kann es sich um technische Dienstleister oder uns beim Versand unterstützende Dienstleister handeln.
            </p>
            <p>
              Die Weitergabe Ihrer Daten an andere Dritte erfolgt ansonsten nur, wenn wir dazu gesetzlich verpflichtet sind.
            </p>

            <h2>
                <a name="_Einsatz_von_Cookies"></a>
                d.	Einsatz von Cookies
            </h2>
            <p>
              Für unser Portal nutzen wir die Technik der Cookies. Cookies sind kleine Textdateien, die im Rahmen Ihres Besuchs unserer Webseiten von unserem Webserver an Ihren Browser gesandt und von diesem auf Ihrem Rechner für einen späteren Abruf vorgehalten werden.
            </p>
            <p>
                <strong> Diese Website nutzt Cookies in folgendem Umfang:</strong>
            </p>
            <p>1.	Transiente Cookies (temporärer Einsatz)</p>
            <p>2.	Persistente Cookies (zeitlich beschränkter Einsatz)</p>
            <p>3.	Third-Party Cookies (von Drittanbietern)</p>
            <p>
                <strong>Cookies können grundsätzlich in zwei Kategorien unterteilt werden:</strong>
            </p>
            <p>
              Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf die Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder Sie den Browser schließen.
            </p>
            <p>
              Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.
            </p>
            <p>
              Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie jedoch darauf hin, dass Sie dann eventuell nicht alle Funktionen dieser Website nutzen können.
            </p>
            <p>
                Mehr Informationen wie Cookies durch spezielle Browser-Plugins abgelehnt werden können, finden Sie auch in der Einleitung zum Kapitel
                <a href="#_Eingebundene_Dienste"> Eingebundene Dienste </a>
                weiter unten in unserer Datenschutzerklärung.
            </p>
            <p>
              Eine Erhebung oder Speicherung personenbezogener Daten in Cookies findet in diesem Zusammenhang durch uns nicht statt. Wir setzen auch keine Techniken ein, die durch Cookies anfallende Informationen mit Nutzerdaten verbinden.
            </p>
            <p>
              Mehr Information rund um das Thema „Cookies“ erhalten Sie im Portal der Technischen Universität Berlin - Verbraucher Sicher Online:
            </p>
            <p>
              <a  href="https://www.datenschutz.org/cookies/"
                  target="_blank"
              >https://www.datenschutz.org/cookies/
              </a>.
            </p>





            <h2>
                <a name="_Webanalyse"></a>
                e.	Webanalyse
            </h2>
            <p>
              Uns ist es wichtig, unsere Webseiten möglichst optimal auszugestalten und damit für unsere Besucher attraktiv zu machen. Dazu ist es nötig, dass wir wissen, welche Teile davon wie bei unseren Besuchern ankommen. Dazu setzen wir folgende Technologien ein.
            </p>
            <h2>f.	Newsletter</h2>
            <p>
              Einen Newsletter können Sie über unser Portal nicht abonnieren.
            </p>



            <h2>g.	Matomo (ehemals PIWIK)</h2>
            <p>
              Für unsere Webseiten verwenden wir Matomo / PIWIK, dabei handelt es sich um einen sogenannten Webanalysedienst, der uns die anonyme Auswertung Ihres Nutzungsverhaltenen auf unseren Webseiten ermöglicht. Matomo / Piwik verwendet Cookies, die auf Ihrem Computer in Ihrem aktuellen Browser gespeichert werden. Zu diesem Zweck werden die erzeugten Nutzungsinformationen (einschließlich Ihrer gekürzten IP-Adresse) an unseren Server übertragen. Ihre IP-Adresse wird bei diesem Vorgang umgehend anonymisiert, sodass Sie als Nutzer für uns anonym bleiben. Die durch ein Cookie erzeugten Informationen über Ihre Nutzung unserer Webseiten werden nicht an Dritte weitergegeben. Sie können die Verwendung der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern.
              <br/>
              Wenn Sie mit der Speicherung und Auswertung dieser Daten nicht einverstanden sind, dann können Sie dieser nachfolgend per Mausklick jederzeit widersprechen. In diesem Fall wird in Ihrem Browser ein sog. Opt-Out-Cookie abgelegt, was zur Folge hat, dass motomo / Piwik keinerlei Sitzungsdaten erhebt.
            </p>
            <iframe
      				style={{border:'0', maxHeight:'100px', width:'inherit'}}
              src="https://analytics.gebit-ms.de/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=&fontSize=&fontFamily="
              />
            <p>
              <strong>Achtung: </strong>
              Wenn Sie Ihre Cookies löschen, so hat dies zur Folge, dass auch das Opt-Out-Cookie gelöscht wird und ggf. von Ihnen erneut aktiviert werden muss.
            </p>



            <h2>
                <a name="_Eingebundene_Dienste"></a>
                h.	Eingebundene Dienste
            </h2>
            <p>
              Zur Gestaltung unseres Portals und der Bereitstellung von Zusatzfunktionen, binden wir folgende externe Dienste ein.
            </p>
            <p>
              Alle im Folgenden beschriebenen Dienste lassen sich durch den Einsatz spezieller Plugins für Ihren Browser deaktivieren oder konkreter, die notwendige Verbindung zu den entsprechenden Servern wird unterbunden. Beachten Sie jedoch, dass der Einsatz solcher Tools mit Einbußen im bekannten Alltags-Komfort verbunden sein könnte – denn vieles funktioniert anschließend nicht mehr so wie Sie es üblicherweise erwarten.
            </p>
            <p>
              Interessierten Lesern wird der Einsatz von Tools wie
              <a href="https://noscript.net/" target="_blank"> NoScript </a>,
              <a href="https://de.wikipedia.org/wiki/UBlock_Origin" target="_blank"> uBlock Origin </a>
              oder uMatrix empfohlen. Es gibt viele Tools, die den Internetverkehr generell sicherer gestalten möchten, jedoch sind diese nicht automatisch auch datenschutzfreundlich.
            </p>
            <p>
              Diese Tools können übrigens, richtig konfiguriert, auch die bereits angesprochene Webanalyse oder nutzungsbasierte Werbenetzwerke und sonstige automatisch im Hintergrund ausgelöste Verbindungen zu Drittanbietern generell für alle von Ihnen besuchte Webseiten unterbinden.
            </p>



            <h2>i.	Google Maps</h2>
            <p>
              Auf unseren Webseiten binden wir Inhalte von Google Maps ein, um Ihnen den genauen Standort des Angebotes oder des Anbieters vereinfacht darstellen zu können. Dadurch wird automatisch eine Verbindung von Ihrem Browser zum Google Maps Server aufgebaut und somit auch Ihre IP-Adresse übermittelt. Sollten Sie parallel zum Besuch unserer Webseiten auch bei Google mit einem vorhandenen Benutzerkonto eingeloggt sein, kann Google womöglich auch den Besuch unserer Webseiten Ihrem Nutzerverhalten zuordnen. Zudem können weitere Cookies durch Google an Ihren Browser gesendet werden. Auf diese Vorgehensweise haben wir keinen Einfluss und erhalten auch keine Informationen von Google über die übermittelten Inhalte.
            </p>
            <p>
              Anbieter ist die Google Inc. 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.
            </p>
            <p>
              Weitere Informationen zum Datenschutz bei der Google Inc. finden Sie unter:
              <a href="https://www.google.com/intl/de/policies/privacy/"> https://www.google.com/intl/de/policies/privacy/ </a>
            </p>


            <h2>
                <a name="_Nutzungsbasierte_Online-Werbung"></a>
                <a name="_Social_Media_Plugins"></a>
                j.	Social Media Plugins
            </h2>
            <p>
                Wir verwenden derzeit keine Social Media Plugins.
            </p>



            <h1>3.	Verarbeitung Ihrer personenbezogenen Daten in Staaten außerhalb der EU und des EWR</h1>
            <p>
              Eine Verarbeitung Ihrer personenbezogenen Daten in Staaten außerhalb der Europäischen Union und des Europäischen Wirtschaftsraumes findet nicht statt, mit Ausnahme der Google Maps Anwendung
            </p>
            <p>
              <strong>Hinweis:</strong>
              Wir und auch die uns ggf. unterstützenden Anbieter von Webanalysen, eingebundenen Diensten sowie nutzungsbasierter Online-Werbung können Sie nicht anhand Ihrer IP-Adresse identifizieren. Erst ein Abgleich Ihrer IP-Adresse mit Daten Ihres Internet-Providers ermöglicht eine personenbezogene Zuordnung.
            </p>
            <h2>
                <a name="_Rechtsgrundlagen_der_Datenverarbeit"></a>
                4.	Datensicherheit
            </h2>
            <p>
              Wir setzen zudem technische und organisatorische Sicherheitsmaßnahmen ein, um anfallende oder erhobene personenbezogene Daten zu schützen, insb. gegen zufällige oder vorsätzliche Manipulation, Verlust, Zerstörung oder gegen den Angriff unberechtigter Personen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
            </p>
            <p>
              Die Übertragung Ihrer personenbezogenen Daten wird dabei per SSL-Technik (https) verschlüsselt, um Zugriffe unberechtigter Dritter zu verhindern.
            </p>



            <h1>5.	 Kommunikation per E-Mail  </h1>
            <p>
              Bei der Kommunikation per E-Mail kann die vollständige Datensicherheit von uns nicht gewährleistet werden, sodass wir Ihnen bei Informationen mit hohem Geheimhaltungsbedürfnis den Postweg empfehlen.
            </p>

            <h1>6.	 Datenschutzbeauftragter</h1>

            <strong>Landkreis Altenburger Land</strong>
            <p className="mb-5">
              Landratsamt Altenburger Land<br/>
              Datenschutzbeauftragter<br/>
              Lindenaustraße 9<br/>
              04600 Altenburg<br/>
              Telefon: 03447 586-250<br/>
              E-Mail: datenschutz(at)altenburgerland.de<br/>
            </p>

            <strong>Stadt Gera</strong>
            <p className="mb-5">
              Thüringer Landesbeauftragten für den Datenschutz und die Informationsfreiheit (TLfDI)<br/>
              Postfach 900455<br/>
              99107 Erfurt<br/>
              <a href="https://www.tlfdi.de" target="_blank">https://www.tlfdi.de</a>
            </p>

            <strong>Stadt Jena</strong>
            <p className="mb-5">
              Melanie Pesch<br/>
              Am Anger 15<br/>
              07743 Jena, oder<br/>
              Postfach 10 03 38, 07703 Jena<br/>
            </p>
            <p className="mb-5">
              Tel. 03641 49-2113<br/>
              Fax 03641 49-2114<br/>
              datenschutz(at)jena.de<br/>
            </p>

            <strong>Landkreis Kyffhäuser Kreis</strong>
            <p>Fabian Heyne</p>
            <p className="mb-5">
              Landratsamt Kyffhäuserkreis<br/>
              Datenschutzbeauftragter<br/>
              Markt 8<br/>
              99706 Sondershausen<br/>
              Deutschland<br/>
            </p>

            <strong>Saale-Orla-Kreis</strong>
            <p className="mb-5">
              Landratsamt Saale-Orla-Kreis<br/>
              Datenschutzbeauftragter<br/>
              Oschitzer Straße 4<br/>
              07907 Schleiz<br/>
              Telefon: 03663/488-915<br/>
              Telefax: 03663/488-510<br/>
              E-Mail: datenschutz(at)lrasok.thueringen.de
            </p>

            <strong>Landkreis Saalfeld-Rudolstadt</strong>
            <p className="mb-5">
            Landratsamt Saalfeld-Rudolstadt<br/>
            Datenschutzbeauftragter<br/>
            Schloßstraße 24<br/>
            07318 Saalfeld<br/>
            datenschutzbeauftragter(at)kreis-slf.de<br/>
            Tel. 03671 823 559<br/>
            Fax. 03671 823 560<br/>
            </p>


            <br/>
            <br/>
            <p>Stand: 1. September 2020</p>

          </div>
        </div>
      </section>
	);
  }
}

export default ViewDatenschutz;
