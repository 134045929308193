import React, { Component } from 'react';
import TemplateMain from './TemplateMain';
import TopicAndAudience from './TopicAndAudience';

/**
 * This is the main template component and renders the Header + Footer and the @children in between.
 *
 * @class      LayoutDefault (name)
 * @prop 		{object} heroData - {img, title, body}
 */
class LayoutDefault extends Component {
  render() {
    return(
    	<TemplateMain showHero={true}>

			<main className="LayoutDefault">
				<div className="row">
					<TopicAndAudience layout="sidebar" />
					<section className="content">
						{this.props.children}
					</section>
				</div>
			</main>
			
		</TemplateMain>
	);
  }
}

export default LayoutDefault;