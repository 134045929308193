import React from 'react';

export default class GmapMarkerInfoModel{
  /*
  * pLat: "50.897295800"
  * pLng: "12.086999500"
  */
	constructor(pDatasetId, pParentId, pLat, pLng, pTitle, pIconUrl, pColorHtml, pMarkerInfoHtml, pMarkerInfoDetailHtml, pRegionToken) {

    let lat = parseFloat(pLat);//("50.897295800");
    let lng = parseFloat(pLng);//("12.086999500");

    //if ((lat != NaN) && (lng != NaN)) {
    //let  markerPosition = new window.google.maps.LatLng(lat,lng);

		this.m_lat = lat;
    this.m_lng = lng;
		this.m_title = pTitle;
		this.m_windowInfoHtml = pMarkerInfoHtml ? pMarkerInfoHtml : (<div>bitte pMarkerInfoHtml in GmapMarkerInfoModel setzen</div>);
		this.m_iconUrl = this.getIconForGmapPin(pIconUrl);
		this.m_colorHtml = pColorHtml;
    this.m_datasetId = pDatasetId.toUpperCase();
    this.m_parentId = pParentId.toUpperCase();
		this.m_windowInfoDetailsHtml = pMarkerInfoDetailHtml ? pMarkerInfoDetailHtml : (<div>bitte pMarkerInfoDetailHtml in GmapMarkerInfoModel setzen</div>);
		this.m_regionToken = pRegionToken;
	}

	getIconForGmapPin(pPinImgPath){
		if(pPinImgPath !== null && pPinImgPath !== window.undefined){
			let iconScalar = { height:37,width:22 };
			return{
					url: pPinImgPath,
					scaledSize: iconScalar
			}
		}
		return '';
	}
}
