import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Mailto from 'react-protected-mailto';
import { scroller } from 'react-scroll';

import TemplateMain from '../../basis/basis_t/TemplateMain';

/**
 * Static page: Datenschutz
 *
 * @class      ViewDatenschutz (name)
 */
class ViewDatenschutz extends Component {

  componentDidMount() {
    scroller.scrollTo('scroll_start', {
      duration: 1000,
      smooth: true
    });
  }

  render() {

    return(
      <section className="ViewDatenschutz" name='scroll_start'>
        <div className="wrapper">
          <div className="container">
            <h1>
                Datenschutzerklärung
            </h1>
            <p>Stand: 9. Oktober 2020</p>
            <p>
                Wir freuen uns, dass Sie unsere Webseiten besuchen und bedanken uns für Ihr Interesse an unserem Portal Familienwegweiser im Landkreis Gifhorn.
            </p>
            <p>
                Datenschutz ist für uns nicht nur gesetzliche Verpflichtung, sondern ein wichtiges Instrument zur Erhöhung der Transparenz bei unserem täglichen Umgang mit Ihren persönlichen Daten. Der Schutz Ihrer personenbezogenen Daten (im Folgenden kurz „Daten“) ist uns ein großes und sehr wichtiges Anliegen. Nachfolgend möchten wir Sie daher ausführlich darüber informieren, welche Daten bei Ihrem Besuch unseres Internetauftritts und Nutzung unserer dortigen Angebote erhoben und wie diese von uns im Folgenden verarbeitet oder genutzt werden, ebenso wie, welche begleitenden Schutzmaßnahmen wir auch in technischer und organisatorischer Hinsicht getroffen haben.
            </p>


            <h2>Verantwortliche Stelle im Sinne des Datenschutzgesetzes</h2>
            <p>
                Die Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:
            </p>
            <p className="mb-5">
            Landkreis Gifhorn<br/>
            - der Landrat -<br/>
            Schlossplatz 1<br/>
            38518 Gifhorn<br/>
            Tel.: +49 (0)5371 82 – 0<br/>
            Fax: + 49 (0)5371 82 – 357<br/>
            E-Mail: landkreis(at)gifhorn.de
            </p>



            <h2>Erhebung und Verwendung Ihrer Daten</h2>
            <p>
                Umfang und Art der Erhebung und Verwendung Ihrer Daten unterscheidet sich danach, ob Sie unser Angebot nur zum Abruf von Informationen besuchen oder die von uns angebotenen Leistungen in Anspruch nehmen:
            </p>

            <h3>Informatorische Nutzung</h3>
            <p>
                Für die nur informatorische Nutzung unserer Webseiten ist es grundsätzlich nicht erforderlich, dass Sie personenbezogene Daten angeben.
            </p>
            <p>
                Vielmehr erheben und verwenden wir in diesem Fall nur diejenigen Ihrer Daten, die uns Ihr Internetbrowser automatisch übermittelt, wie etwa:
            </p>
            <ul>
                <li>Datum und Uhrzeit des Abrufs einer unserer Webseiten
                </li><li>Ihren Browsertyp
                </li><li>die Browser-Einstellungen
                </li><li>das verwendete Betriebssystem
                </li><li>die von Ihnen zuletzt besuchte Seite
                </li><li>die übertragene Datenmenge und der Zugriffsstatus (Datei übertragen, Datei nicht gefunden etc.) sowie
                </li><li>Ihre IP-Adresse.
                </li>
            </ul>
            <p>
              Diese Daten erheben und verwenden wir bei einem informatorischen Besuch ausschließlich in nicht-personenbezogener Form. Dies erfolgt, um die Nutzung der von Ihnen abgerufenen Webseiten überhaupt zu ermöglichen, zu statistischen Zwecken sowie zur Verbesserung unseres Internetangebots. Die IP-Adresse speichern wir nur für die Dauer Ihres Besuchs, eine personenbezogene Auswertung findet nicht statt. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. e) DSGVO iVm. § 3 NDSG.
            </p>



            <h3>Anlegen eines Profils als Anbieter</h3>
            <p>
              Sie können sich als Ansprechpartner*in eines Sportvereins, einer Selbsthilfegruppe, einer Mütterorganisation, einer Beratungsstelle, einer Kindertagesstätte, einer sonstigen Vereinigung oder als Tagespflegebetreuungsperson auf unserer Webseite registrieren, um Angebote mit Familienbezug zu veröffentlichen („Anbieter“). Für die Registrierung als Anbieter ist die Angabe Ihres Vor- und Nachnamens, Ihrer Anschrift, Ihrer E-Mail-Adresse und Telefonnummer sowie der Name der Institution erforderlich, für die Sie sich registrieren. Wir verarbeiten Ihre Daten, um Ihnen einen Zugang zum Familienwegweiser einzurichten und speichern Ihre Daten so lange Ihr Zugang zum Familienwegweiser besteht. Sie können Ihren Zugang jederzeit löschen lassen. Rechtsgrundlagen für die Datenverarbeitung sind Art. 6 Abs. 1 lit. b) DSGVO sowie Art. 6 Abs. 1 lit. e) DSGVO iVm. § 3 NDSG.
            </p>

            <h3>Ihre Betroffenenrechte als Kunde</h3>
            <ul>
                <li>Als betroffene Person haben Sie nach der DSGVO folgende Rechte, soweit deren jeweilige gesetzliche Voraussetzungen vorliegen: Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,
                </li><li>Berichtigung unrichtiger personenbezogener Daten,
                </li><li>Löschung Ihrer bei uns gespeicherten Daten,
                </li><li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen,
                </li><li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und
                </li><li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben.
                </li><li>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Ihrer personenbezogenen Daten, die auf Grundlage von Art. 6 Abs. 1 lit. e) DSGVO erfolgt, Widerspruch einzulegen. Diese personenbezogenen Daten werden dann nicht mehr für diese Zwecke verarbeitet, es sei denn, es können zwingende schutzwürdige Gründe für die Verarbeitung nachgewiesen werden, die Ihre Interessen, Rechten und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
              </li>
            </ul>
            <p>
              Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.
            </p>
            <p>
              Geltendmachung Ihrer Rechte: Wenden Sie sich zur Ausübung aller Ihrer zuvor genannten Rechte bitte über das Kontaktformular, per E-Mail etc. oder postalisch an die oben angegebene Anschrift. Bitte stellen Sie dabei sicher, dass uns eine eindeutige Identifizierung Ihrer Person möglich ist.
            </p>
            <p>
              Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige Aufsichtsbehörde wenden. Ihre zuständige Aufsichtsbehörde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen Verletzung. Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter:
            </p>
            <p>
              <a
                 href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                 target="_blank">
                 https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
              </a>
               .
            </p>




            <h3>Kontaktformular</h3>
            <p>
              Die im Kontaktformular erhobenen Angaben (Vor- und Nachname, Anschrift, E-Mail-Adresse und Telefonnummer, Institution) sowie Ihre Nachricht werden von uns nur zum Zweck der Beantwortung Ihrer Anfrage verwendet. Nach Abschluss der Bearbeitung werden die Angaben gelöscht. Lediglich im Rahmen der gesetzlich vorgeschriebenen Archivierung des E-Mailverkehrs (E-Mails sind Geschäfts- und Handelsbriefe) werden Daten darüber hinaus für max. 10 Jahre aufbewahrt. Rechtsgrundlagen für die Datenverarbeitung sind Art. 6 Abs. 1 lit. b) DSGVO sowie Art. 6 Abs. 1 lit. e) DSGVO iVm. § 3 NDSG.
            </p>
            <p>
              Ihre Daten werden zu vorgenanntem Zweck ggf. an uns unterstützende Dienstleister weitergegeben, die wir selbstverständlich sorgfältig ausgewählt haben. Dabei kann es sich um technische Dienstleister oder uns beim Versand unterstützende Dienstleister handeln.
            </p>
            <p>
              Die Weitergabe Ihrer Daten an andere Dritte erfolgt ansonsten nur, wenn wir dazu gesetzlich verpflichtet sind.
            </p>




            <h2>Einsatz von Cookies</h2>
            <p>
              Für unser Portal nutzen wir die Technik der Cookies. Cookies sind kleine Textdateien, die im Rahmen Ihres Besuchs unserer Webseiten von unserem Webserver an Ihren Browser gesandt und von diesem auf Ihrem Rechner für einen späteren Abruf vorgehalten werden.
            </p>


            <h3>Diese Website nutzt Cookies in folgendem Umfang:</h3>
            <p>
                1.	Transiente Cookies (temporärer Einsatz)
            </p>
            <p>
                2.	Persistente Cookies (zeitlich beschränkter Einsatz)
            </p>
            <p>
                3.	Third-Party Cookies (von Drittanbietern)
            </p>



            <h3>Cookies können grundsätzlich in zwei Kategorien unterteilt werden:</h3>
            <p>
              Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf die Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder Sie den Browser schließen.
            </p>
            <p>
              Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.
            </p>
            <p>
              Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie jedoch darauf hin, dass Sie dann eventuell nicht alle Funktionen dieser Website nutzen können.
            </p>
            <p>
              Mehr Informationen wie Cookies durch spezielle Browser-Plugins abgelehnt werden können,
              finden Sie auch in der Einleitung zum Kapitel
              <a href="#_Eingebundene_Dienste"> Eingebundene Dienste </a>
              weiter unten in unserer Datenschutzerklärung.
            </p>
            <p>
              Eine Erhebung oder Speicherung personenbezogener Daten in Cookies findet in diesem Zusammenhang durch uns nicht statt. Wir setzen auch keine Techniken ein, die durch Cookies anfallende Informationen mit Nutzerdaten verbinden.
            </p>
            <p>
              Mehr Information rund um das Thema „Cookies“ erhalten Sie im Portal Datenschutz.org Datenschutz in Deutschland & EU:
            </p>
            <p>
              <a
                 href="https://www.datenschutz.org/cookies/"
                 target="_blank">
                 https://www.datenschutz.org/cookies/
              </a>
              .
            </p>



            <h2>Webanalyse</h2>
            <p>
              Uns ist es wichtig, unsere Webseiten möglichst optimal auszugestalten und damit für unsere Besucher attraktiv zu machen. Dazu ist es nötig, dass wir wissen, welche Teile davon wie bei unseren Besuchern ankommen. Dazu setzen wir folgende Technologien ein.
            </p>




            <h2>Matomo (ehemals PIWIK)</h2>
            <p>
              Für unsere Webseiten verwenden wir Matomo / PIWIK, dabei handelt es sich um einen sogenannten Webanalysedienst, der uns die anonyme Auswertung Ihres Nutzungsverhaltenen auf unseren Webseiten ermöglicht. Matomo / Piwik verwendet Cookies, die mit Ihrer Einwilligung auf Ihrem Computer in Ihrem aktuellen Browser gespeichert werden. Zu diesem Zweck werden die erzeugten Nutzungsinformationen (einschließlich Ihrer gekürzten IP-Adresse) an unseren Server übertragen. Ihre IP-Adresse wird bei diesem Vorgang umgehend anonymisiert, sodass Sie als Nutzer für uns anonym bleiben. Wir analysieren Ihr Nutzungsverhalten auf unserer Webseite mithilfe entsprechender Cookies nur dann, wenn sie uns zuvor Ihre ausdrückliche Einwilligung erteilt haben und löschen Informationen zu Ihrem Nutzungsverhalten in diesem Fall nach […]. Die durch ein Cookie erzeugten Informationen über Ihre Nutzung unserer Webseiten werden nicht an Dritte weitergegeben. Sie können die Verwendung der Cookies zudem durch eine entsprechende Einstellung Ihrer Browser Software verhindern. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. a) DSGVO.
            </p>
            <iframe
      				style={{border:'0', maxHeight:'100px', width:'inherit'}}
              src="https://analytics.gebit-ms.de/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=&fontSize=&fontFamily="
              />
            <p>
              <strong>Achtung: </strong>
              Wenn Sie Ihre Cookies löschen, so hat dies zur Folge, dass auch das Opt-Out-Cookie gelöscht wird und ggf. von Ihnen erneut aktiviert werden muss.
            </p>



            <h2>
              <a name="_Eingebundene_Dienste"></a>
              Eingebundene Dienste
            </h2>
            <p>
              Zur Gestaltung unseres Portals und der Bereitstellung von Zusatzfunktionen, binden wir folgende externe Dienste ein.
            </p>
            <p>
              Alle im Folgenden beschriebenen Dienste lassen sich durch den Einsatz spezieller Plugins für Ihren Browser deaktivieren oder konkreter, die notwendige Verbindung zu den entsprechenden Servern wird unterbunden. Beachten Sie jedoch, dass der Einsatz solcher Tools mit Einbußen im bekannten Alltags-Komfort verbunden sein könnte – denn vieles funktioniert anschließend nicht mehr so wie Sie es üblicherweise erwarten.
            </p>
            <p>
                Interessierten Lesern wird der Einsatz von Tools wie
                <a href="https://noscript.net/" target="_blank"> NoScript </a>,
                <a href="https://de.wikipedia.org/wiki/UBlock_Origin" target="_blank"> uBlock Origin </a>
                oder uMatrix empfohlen. Es gibt viele Tools,
                die den Internetverkehr generell sicherer gestalten möchten, jedoch sind diese nicht automatisch auch datenschutzfreundlich.
            </p>
            <p>
              Diese Tools können übrigens, richtig konfiguriert, auch die bereits angesprochene Webanalyse oder nutzungsbasierte Werbenetzwerke und sonstige automatisch im Hintergrund ausgelöste Verbindungen zu Drittanbietern generell für alle von Ihnen besuchte Webseiten unterbinden.
            </p>





            <h2>Google Maps</h2>
            <p>
              Auf unseren Webseiten binden wir nach Ihrer vorherigen Einwilligung Inhalte von Google Maps ein, um Ihnen den genauen Standort des Angebotes oder des Anbieters vereinfacht darstellen zu können. Dadurch wird eine Verbindung von Ihrem Browser zum Google Maps Server aufgebaut und somit auch Ihre IP-Adresse übermittelt. Sollten Sie parallel zum Besuch unserer Webseiten auch bei Google mit einem vorhandenen Benutzerkonto eingeloggt sein, kann Google womöglich auch den Besuch unserer Webseiten Ihrem Nutzerverhalten zuordnen. Zudem können weitere Cookies durch Google an Ihren Browser gesendet werden. Auf diese Vorgehensweise haben wir keinen Einfluss und erhalten auch keine Informationen von Google über die übermittelten Inhalte. Wir binden Inhalte von Google Maps nur dann ein, wenn sie uns hierzu zuvor Ihre ausdrückliche Einwilligung erteilt haben. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. a) DSGVO.
            </p>
            <p>
              Anbieter ist die Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA. Mit der Google Inc. haben wir Standardvertragsklauseln abgeschlossen, um sicherzustellen, dass das durch die DSGVO gewährleistete Schutzniveau für natürliche Personen nicht untergraben wird. Eine Kopie können Sie unter der oben angegebenen Anschrift anfordern.
            </p>
            <p>Weitere Informationen zum Datenschutz bei der Google Inc. finden Sie unter:</p>
            <p><a  href="https://www.google.com/intl/de/policies/privacy/" target="_blank">
                 https://www.google.com/intl/de/policies/privacy/
            </a></p>





            <h2>Verarbeitung Ihrer personenbezogenen Daten in Staaten außerhalb der EU und des EWR</h2>
            <p>
              Eine Verarbeitung Ihrer personenbezogenen Daten in Staaten außerhalb der Europäischen Union und des Europäischen Wirtschaftsraumes findet nicht statt, mit Ausnahme der Google Maps Anwendung
            </p>




            <h2>Hinweis:</h2>
            <p>
              Wir und auch die uns ggf. unterstützenden Anbieter von Webanalysen, eingebundenen Diensten sowie nutzungsbasierter Online-Werbung können Sie nicht anhand Ihrer IP-Adresse identifizieren. Erst ein Abgleich Ihrer IP-Adresse mit Daten Ihres Internet-Providers ermöglicht eine personenbezogene Zuordnung.
            </p>


            <h2>Datensicherheit</h2>
            <p>
              Wir setzen zudem technische und organisatorische Sicherheitsmaßnahmen ein, um anfallende oder erhobene personenbezogene Daten zu schützen, insb. gegen zufällige oder vorsätzliche Manipulation, Verlust, Zerstörung oder gegen den Angriff unberechtigter Personen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
            </p>
            <p>
              Die Übertragung Ihrer personenbezogenen Daten wird dabei per SSL-Technik (https) verschlüsselt, um Zugriffe unberechtigter Dritter zu verhindern.
            </p>




            <h2>Kommunikation per E-Mail</h2>
            <p>
              Bei der Kommunikation per E-Mail kann die vollständige Datensicherheit von uns nicht gewährleistet werden, sodass wir Ihnen bei Informationen mit hohem Geheimhaltungsbedürfnis den Postweg empfehlen.
            </p>


            <h2>Datenschutzbeauftragter</h2>
            <p className="mb-5">
              Dr. Gregor Scheja<br/>
              Scheja und Partner Rechtsanwälte mbB<br/>
              Adenauerallee 136<br/>
              53113 Bonn<br/>
              Telefon: 0228/2272260<br/>
              Kontakt: <a href="https://www.scheja-partner.de/kontakt/kontakt.html " target="_blank">https://www.scheja-partner.de/kontakt/kontakt.html</a>
            </p>





          </div>
        </div>
      </section>
	);
  }
}

export default ViewDatenschutz;
