import React, { Component } from 'react';

import {AppContext} from '../../basis/basis_t/AppContext';
import ApiConnector from '../../common/model/ApiConnector';
import CreateOffersFromProvider from './CreateOffersFromProvider';
import MessageSharePartial from '../../common/components/MessageSharePartial';

/**
 * Wrapper/View component with MainTemplate for the SearchResultsOrganisation.
 *
 * @class      SearchOffersFromProvider (name)
 */
class SearchOffersFromProvider extends Component{
	constructor(props) {
		super(props);

		this.state = {
			search_results: '',
			results_to_show: 'list',
			s_providerId: ''
		};

		//This binding is necessary to make `this` work in the callback
		this.receiveSearchResultText = this.receiveSearchResultText.bind(this);
		this.receiveError = this.receiveError.bind(this);
	}

	componentDidMount(){
		this.setStateProvider();
	}
	componentDidUpdate(prevProps){
		if(prevProps._providerId != this.props._providerId)
		{
			this.setStateProvider();
		}
	}
	setStateProvider(){
		if(this.props._providerId){
			//wir müssen hier den state setzen damit die render-Funktion aufgerufen wird
			this.setState({
				s_providerId: this.props._providerId
			}, () => {
				this.checkProviderId(this.props._providerId);
			});
		}
	}
	checkProviderId(pId){
		let filters = [];
		let filter = ApiConnector.buildOrganisationFilter(pId);
		filters.push(filter);

		if(filter){
			ApiConnector.searchText('', this.receiveSearchResultText, this.receiveError, filters);
		}
	}
	receiveSearchResultText(json_data){
		this.setState({
			search_results: json_data,
			results_to_show: 'list'
		});
	}
	receiveError(error){
		console.log('ViewPreview Error');
		console.log(error);
	}


	render(){
		let result_view = null;
		if(this.state.search_results)
		{
			result_view = <CreateOffersFromProvider _offersJsonData={this.state.search_results} />
		}
		else{
			result_view = <MessageSharePartial>Ergebnisse werden gesucht ... </MessageSharePartial>;
		}

		return(
					<div className="SearchOffersFromProvider">
					{result_view}
					</div>
		);
	}
}
SearchOffersFromProvider.contextType = AppContext;
export default SearchOffersFromProvider;
