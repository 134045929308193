//https://codesandbox.io/s/n05jm6j0jm?file=/src/index.js:914-1434
import React from "react";
import ReactDOM from "react-dom";
import { renderToString } from 'react-dom/server'
// https://react-google-maps-api-docs.netlify.com/
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import $ from 'jquery';

import Config from '../../Config';

export default class GmapFfoPartial extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      s_gmapUpdate: false,
      s_marker_info_window: null
    };

    this.m_bounds = null;

    this.m_mapGM = null;
    this.m_markers = null;

    this.m_BenutzerMeldung = "Orte werden geladen . . .";
  }

  componentDidMount(){
    this.m_markers = this.createGoogleMapMarker(this.props.p_markers);
  }

  componentWillUpdate(nextProps, nextState){
    if(this.props.p_markers !== nextProps.p_markers)
    {
      try{
        this.setState({s_gmapUpdate: !this.state.s_gmapUpdate});
        this.m_markers = this.createGoogleMapMarker(nextProps.p_markers);
        let elemGmap = $('.gmapFfo');
        let mapDim = {
                    height:  elemGmap.height(),
                    width:   elemGmap.width()
        }
        this.calculateBounds(this.m_markers, mapDim);
        this.m_BenutzerMeldung = "";
      }catch (e) {
        console.error("Error in GmapFfoPartial: ", e);
        this.m_BenutzerMeldung = "Die Karte konnte nicht geladen werden. Versuchen Sie bitte erneut."
        return;
      }
    }
  }
  createGoogleMapMarker(pMarkers){
    if(pMarkers){
      let googleMapMarkers = [];
      try {
        let markers = pMarkers;
        let markerCount = markers.length;

        for (let i=0;i < markerCount;i++){
          let markerInfo = markers[i];
          if ((markerInfo.m_lat != NaN) && (markerInfo.m_lng != NaN)) {
            //let  markerPosition = new googleWindow.maps.LatLng(,markerInfo.m_lng);
            googleMapMarkers.push(<Marker
    								key = {markerInfo.m_datasetId}
    								position = {{ lat: markerInfo.m_lat, lng: markerInfo.m_lng }}
                    icon= {markerInfo.m_iconUrl}
    								onClick={event => this.markerOnClick(event, markerInfo)}
    							></Marker>);
          }
        }
      } catch (e) {
        //die bounds können nicht zuverlässig gesetzt werden
        //so wie new window.google. Somit kann keine Karte geladen werden
        console.error("Error in GmapFfoPartial: ", e);
        return;
      } finally {

      }
      return googleMapMarkers;
    }
  }
  markerOnClick(event, pMarkerForInfo){
		let lat = parseFloat(pMarkerForInfo.m_lat);
		let lng = parseFloat(pMarkerForInfo.m_lng);
		let marker_info_window = (
				<InfoWindow
				  options = {{
				  	pixelOffset: {
				  		width: 0,
				  		height: -20
				  	}
				  }}
			      position={{
			      	lat: lat,
			      	lng: lng
			      }}
			      onCloseClick={e => this.closeInfoWindow(e)}
			    >
			      {pMarkerForInfo.m_windowInfoHtml}
			    </InfoWindow>
			);

    // //zoom & pan map
		// if(this.m_mapGM)
		// {
		// 	this.m_mapGM.setZoom(17);
		// 	this.m_mapGM.panTo({lat,lng});
		// }

    this.setState({
    	s_marker_info_window: marker_info_window
    });
	}

  closeInfoWindow(event){
		this.setState({
			s_marker_info_window: null
		});
	}


  createBoundsForMarkers(pMarkers) {
    var bounds = new window.google.maps.LatLngBounds();
    if(bounds && this.m_mapGM){
      if(pMarkers && pMarkers.length>0){
        $.each(pMarkers, function() {
            bounds.extend(this.props.position);
        });
      }else{
        //bounds.center(Config.FAMFREUNDORTS_GM_CENTER_COORDS);
      }
    }
    return bounds;
  }
  /*Beispiel: http://jsfiddle.net/john_s/BHHs8/6/**/
  getBoundsZoomLevel(bounds, mapDim) {

      var WORLD_DIM = { height: 256, width: 256 };
      var ZOOM_MAX = 21;

      function latRad(lat) {
          var sin = Math.sin(lat * Math.PI / 180);
          var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
          return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
      }

      function zoom(mapPx, worldPx, fraction) {
          return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
      }

      var ne = bounds.getNorthEast();
      var sw = bounds.getSouthWest();

      var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

      var lngDiff = ne.lng() - sw.lng();
      var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;


      var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
      var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

      return Math.min(latZoom, lngZoom, ZOOM_MAX);
  }

  calculateBounds(pGmapMarkers, pGmapSize){
      let bounds = this.createBoundsForMarkers(pGmapMarkers);
      let letZoom = 15;
      if(pGmapMarkers && pGmapMarkers.length > 0)
      {
        letZoom = (bounds) ? this.getBoundsZoomLevel(bounds, pGmapSize) : 0;
      }
      console.log("letZoom",letZoom);
      this.m_mapGM.fitBounds(bounds);
      this.m_mapGM.setZoom(letZoom);
      this.m_bounds = this.m_mapGM.fitBounds(bounds);
  }

  render() {
    let msgMarkersEmpty = this.m_BenutzerMeldung;
      if(this.m_markers === null || this.m_markers  === window.undefined || this.m_markers.length === 0){
        //wenn keine Marker gesetzt sind, setze den Kartenausschnitt auf die Defaultvalues
        if(this.m_mapGM !== null){
          this.m_mapGM.setZoom(Config.FAMFREUNDORTS_GM_DEFAULT_ZOOM);
          this.m_mapGM.setCenter(Config.FAMFREUNDORTS_GM_CENTER_COORDS);
        }
      }

      return (
        <div className="gmapFfo" >
          <div className='mapviewer'>
            <p className="title" style={{display:'none'}}>
              Die Karte zeigt die Suchtreffer
            </p>
            <LoadScript
                id="script-loader"
                googleMapsApiKey={Config.MAPS_API_KEY}
                >
                  <GoogleMap
                      id = "idGmapFfo"
                      onLoad={map => {
                          this.m_mapGM = map;
                      }}
                      // props für die GoogleMap kommen aus FfoContextGmapPartial
                      // beinhalten center, zoom, containersize = mapContainerStyle
                      {...this.props}
                  >
                    { this.m_markers }
                    { this.state.s_marker_info_window }
                  </GoogleMap>
              </LoadScript>
              <span style={{float:'right'}}>{msgMarkersEmpty}</span>
          </div>
        </div>
      );
  }
}
