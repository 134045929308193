import React, { Component } from 'react';
import Mailto from 'react-protected-mailto';
import { scroller } from 'react-scroll';

import TemplateMain from '../../basis/basis_t/TemplateMain';

/**
 * Static page: Impressum
 *
 * @class      ViewImpressum (name)
 */
class ViewImpressum extends Component {
  componentDidMount() {
    scroller.scrollTo('scroll_start', {
        duration: 1000,
        smooth: true
    });
  }

  render() {
    return(
        <section className="ViewImpressum" name='scroll_start'>
          <div className="wrapper">
            <div className="container">
              <h1>Impressum</h1>
              <p>Stand: 01.09.2020</p>
              <h3>Angaben gemäß § 5 TMG:</h3>

              <br/><h4>verantwortlich für den Inhalt</h4>
              <address>
                <p><b>Landratsamt Altenburger Land</b><br />
                Lindenaustraße 9<br />
                04600 Altenburg<br />
                Telefon: 03447 586-250<br />
                E-Mail: oeffentlichkeitsarbeit(at)altenburgerland.de<br />
                </p>
                <p><b>Stadt Gera</b><br />
                  Kornmarkt 12<br />
                  07545 Gera<br />
                  Telefon: 0365 8 38 11 01<br />
                  E-Mail: kommunikation(at)gera.de<br />
                </p>
                <p><b>Stadt Jena</b><br />
                  Am Anger 15<br />
                  07743 Jena<br />
                  Tel. 03641 49-0<br />
                  E-Mail: buero-ob(at)jena.de<br />
                </p>
                <p><b>Landratsamt Kyffhäuserkreis</b><br />
                  Markt 8<br />
                  99706 Sondershausen<br />
                  Telefon: 03632 / 741-0<br />
                  E-Mail: pressestelle(at)kyffhaeuser.de<br />
                </p>
                <p><b>Landratsamt Saale-Orla-Kreis</b><br />
                  Oschitzer Straße 4<br />
                  07907 Schleiz<br />
                  Telefon: 03663/488-0<br />
                  E-Mail: poststelle(at)lrasok.thueringen.de<br />
                </p>
                <p><b>Landratsamt Saalfeld-Rudolstadt</b><br />
                  Schloßstraße 24<br />
                  07318 Saalfeld<br />
                  Telefon: 03671 823 559<br />
                  E-Mail: poststelle(at)kreis-slf.de<br />
                </p>
              </address>

              <br/><h4>Vertreten durch</h4>
              <p>-	Landratsamt Altenburger Land
                <br />-	Oberbürgermeister Julian Vonarb (Stadt Gera)
                <br />-	Christian Schmidt (Stadt Jena)
                <br />-	Landrätin Antje Hochwind-Schneider (Landratsamt Kyffhäuserkreis)
                <br />-	Landrat Thomas Fügmann (Saale-Orla-Kreis)
                <br />-	Landrat Marko Wolfram (Saalfeld-Rudolstadt)
              </p>


              <br/><h3>1. Redaktion:</h3>
              <p>GEBIT Münster GmbH &amp; Co. KG  <br />
                Corrensstraße 80  <br />
                48149 Münster  <br />
                <br />
                Kontakt für Fragen zum Internetauftritt   <br />
                Hotline: 0251 / 20 888 252  <br />
                E-Mail: fabis (at) gebit-ms.de  <br />
              </p>



              <br/><h3>2. Nutzungsbedingungen</h3>
              <p>Die Nutzung ist für alle Nutzer und die Anbieter von Angeboten und Dienstleistungen für Familien/Fachkräfte kostenlos. Die im Portal Familienkompass Thüringen enthaltenen Angaben und Informationen wurden von den Auditoren der beteiligten Kommunen sorgfältig recherchiert und geprüft. Wir sind bemüht dieses Informationsangebot stetig zu erweitern und zu aktualisieren. Für Anbieter, <a href="https://www.familienkompass.info" target="_blank">www.familienkompass.info</a> zur eigenständigen Datenerfassung nutzen, gelten folgende Nutzungsbedingungen.
              </p>


              <br/><h3>3. Technischer Betrieb und Umsetzung (Backend)</h3>
              <p>GEBIT Münster GmbH &amp; Co. KG, Corrensstraße 80, 48149 Münster
              <a href="https://www.gebit-ms.de" target="_blank">www.gebit-ms.de</a>
              </p>


              <br/><h3>4. Technische Umsetzung (Frontend)</h3>
              <p>CodeMonkz Software Entwicklung Inh. Jacky Weinert, Kühlebornweg 1, 12167 Berlin
              <a href="https://www.codemonkz.de" title="CodeMonkz Software Entwicklung">www.codemonkz.de</a>
              </p>

              <br/><h3>5. Gestaltung</h3>
              <p>NOLTE / HAMMER Agentur für Kommunikation und Design, Krüselblick 4, 48341 Altenberge
              <br/><a href="https://www.nolte-hammer.de" target="_blank">www.nolte-hammer.de</a></p>

              <br/><h3>6. Quellenangabe für die verwendeten Bilder</h3>
              <p>Bildquellen: iStock und Stocksy</p>


              <br/><h3>7. Haftungsausschluss (Disclaimer)</h3>
              <br/><h4>a. Haftung für Inhalte</h4>
              <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
              </p>

              <br/><h4>b. Haftung für Links</h4>
              <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
              </p>
              <p>Bei direkten oder indirekten Verweisen auf fremde Internetseiten ("Links" – in erster Linie die der Anbieter, die ihre Angebote auf Familienkompass Thüringen einstellen), die außerhalb des Verantwortungsbereiches der GEBIT Münster liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem die GEBIT Münster von den Inhalten Kenntnis hat und es ihr technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.
              </p>

              <br/><h4>c. Urheberrecht</h4>
              <p>Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind!
              </p>
              <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
              </p>
              <p>Das Copyright für veröffentlichte, von der GEBIT Münster selbst erstellte Objekte bleibt alleine bei der GEBIT Münster. Eine Vervielfältigung oder Verwendung solcher Grafiken und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung der GEBIT Münster nicht gestattet.
              </p>

              <br/><h3>8. Inhalt des Onlineangebotes</h3>
              <p>Die GEBIT Münster übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit, Qualität, jederzeitige Verfügbarkeit der bereitgestellten Informationen und für das Auftreten anderweitiger technischer Störungen. Haftungsansprüche gegen die GEBIT Münster, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen.
              </p>
              <p>Alle Angebote sind freibleibend und unverbindlich. Die GEBIT Münster behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
              </p>
            </div>
          </div>
        </section>
	);
  }
}

export default ViewImpressum;
