import React from 'react';

/**
 * This is the context for all FFO components,
 * which makes it possible to call these functions from everywhere in the app without passing them down.
 */
export const FfoContext = React.createContext({

  // gefilterte Markers für GmapFfoPartial
  s_ffoShowMarkers: null,
  // steuerung der Filterung von GmapMenu durch seine DOM-Reference in CreateFfoContextContent.js
  // wird in FfoContextGmapPartial initialisiert
  s_menuPoimap_element_ref: null,

  // alle vorgefertigte Options für React-Select werden an DropDownReactSelectPartial-Componente mit props weitergegeben
  s_reactSelectRegionOptions_all: null,
  // state-variable, um die Filter beim DropDownReactSelectPartial-componente mit dem props.p_resetValues zurück zu setzen,
  //ausloser verändertes Wert der s_resetRegionFilterToogle,
  s_resetRegionFilterToogle: false,

  // callBack für CreateSearchCard
  handlerCardsUpdateFilterParentId: () => {},
  // FfoContextGmapPartial ruft die Methode vom parent CreateFfoContextContent auf
  handlerMenuUpdateFilterParentId: () => {},
  // p_onchangeCallback von FfoContextGmapPartial an DropDownReactSelectPartial
  handlerRegionFilter: () => {}
});
