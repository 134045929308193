import React, { Component } from 'react';

import Config from '../Config';
import Helper from '../common/tools/Helper';
//@see https://react-google-maps-api-docs.netlify.com/
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';


/**
 * This component renders the API results on a google map
 *
 * @class      MapResult (name)
 * @prop 		{string} zoomToID - the DatasetId of the "angebot" to zoom in on
 * @prop 		{json} datasets - API results to show
 * @prop 		{function} callBack - to make an API call and show the details of a dataset
 * @prop 		{int} width
 * @prop 		{int} height
 */
class MapResult extends Component {

    constructor(props) {
    	super(props);

    	this.state = {
    		info_window: null
    	};

		this.marker_coordinates = null;
    	this.map = null;
    	this.show_map = false;

    	this.ref_map = React.createRef();
    	this.listenToScroll = this.listenToScroll.bind(this);
    	this.stickyY = null;
	}

	componentDidUpdate(prevProps){
		if(this.props.zoomToID && prevProps.zoomToID !== this.props.zoomToID){
			this.zoomTo(this.props.zoomToID);
		}
		else if(this.map && !this.state.info_window)
			this.calculateBounds();
	}

	componentDidMount() {
		window.addEventListener('scroll', this.listenToScroll);
	}

	componentWillUnmount() {
 		window.removeEventListener('scroll', this.listenToScroll);
	}

	/**
	 * makes sure, that the map is sticky while scrolling
	 */
	listenToScroll(){

		let card_container = document.getElementsByClassName("inner tileview")[0];
		if(!card_container)
		{
			card_container = document.getElementsByClassName("SearchResultsWithMap")[0];
		}

		if(this.ref_map.current && card_container)
		{
			let card_contaner_rect = card_container.getBoundingClientRect();
			// let max_height = card_contaner_rect.height;
			let rect = this.ref_map.current.getBoundingClientRect();

			if(rect.y <= 0)
			{
				this.ref_map.current.classList.add('sticky');
				if(!this.stickyY)
					this.stickyY = window.scrollY;
			}

			if(this.stickyY && window.scrollY < this.stickyY)
			{
				this.stickyY = null;
				this.ref_map.current.classList.remove('sticky');
			}			
		}
	}

	/**
	 * zooms to Dataset
	 *
	 * @param      {string}  id - DatasetId
	 */
	zoomTo(id)
	{
		//get dataset
		let dataset;
		for(dataset of this.props.datasets)
		{
			if(dataset.DatasetId === id)
				break;		
		}
		if(dataset.Longitude != '0' && dataset.Latitude != '0')
		{
			this.markerOnClick(null, dataset);
		}
	}

	markerOnClick(event, dataset)
	{

		let lat = parseFloat(dataset.Latitude);
		let lng = parseFloat(dataset.Longitude);

		let info_window = (
				<InfoWindow
				  options = {{
				  	pixelOffset: {
				  		width: 0,
				  		height: -20
				  	}
				  }}
			      position={{
			      	lat: lat,
			      	lng: lng
			      }}
			      onCloseClick={e => this.closeInfoWindow(e)}
			    >
			      <div style={{
			        background: `white`,
			        padding: '0px'
			      }}>
              <h3 title="Navigation starten" className="link" style={{cursor:'pointer'}} onClick={(e) => Helper.openGmapNavigate(lat, lng)}>{dataset.LongName}</h3>
			        <p>{dataset.ParentLongName}</p>
			        <button type="button" className="btn btn-primary" onClick={() => this.props.callBack(dataset.DatasetId)}>Details</button>
			      </div>
			    </InfoWindow>
			);

		//zoom & pan map
		if(this.map)
		{
			this.map.setZoom(17);
			this.map.panTo({lat,lng});			
		}

		this.setState({
			info_window: info_window
		});
	}

	closeInfoWindow(event)
	{
		this.setState({
			info_window: null
		});
	}

	calculateBounds()
	{
    //calculate bounds for centering the view
    if(window.google && window.google.maps){
		  const bounds = new window.google.maps.LatLngBounds();
		  let lat, lng;
			let coordinates_same_pos = true;

			if(this.marker_coordinates.length){
				//store first coordinates for "same pos" check
				lat = this.marker_coordinates[0].lat;
				lng = this.marker_coordinates[0].lng;

				//extend bounds & check for same pos
				for(const mcoords of this.marker_coordinates){
					if(!(mcoords.lat === lat && mcoords.lng === lng)){
						coordinates_same_pos = false;
					}

					bounds.extend(mcoords);
				}

				if(coordinates_same_pos){
				 	//in this case all markers are on the same spot
					this.map.setZoom(17);
					this.map.panTo({lat,lng});
				}
				else if(bounds){
					this.map.fitBounds(bounds);
				}
			}

    }
	}

  //ToDo delete ? (20200810)
	// getShowMap(){
	// 	//todo: this does not work on time >> convert into callback
	// 	return this.show_map;
	// }

	render() {

		let latitude, longitude;
		let markers = [];
		let map_view = null;
		this.marker_coordinates = [];

		for(const [counter, dataset] of this.props.datasets.entries())
		{
			latitude = parseFloat(dataset.Latitude);
			longitude = parseFloat(dataset.Longitude);

			// create Marker for Maps when not 0/0
			if(latitude || longitude)
			{
				markers.push(<Marker
								key = {"marker" + counter}
								position = {{
									lat: latitude,
									lng: longitude
								}}
								onClick={event => this.markerOnClick(event, dataset)}
							></Marker>);

				//store coordinates for center calculation
				this.marker_coordinates.push({
						lat: latitude,
						lng: longitude
					});
			}
		}

    let gmapTitle = "";
		if(markers.length > 0){
      gmapTitle = "Die Karte zeigt die Suchtreffer";
    }else if( this.props.datasets.length > 0){
      //wenn bei den Suchergebnissen keine Markers gesetzt sind, setze den Kartenausschnitt auf die Defaultvalues
      if(this.map != null){
        this.map.setZoom(14);
        this.map.setCenter(Config.FAMFREUNDORTS_GM_CENTER_COORDS);
      }
      //und gebe eine kurze Meldung aus. Wird unter der Karte angezeigt
      gmapTitle = "Die Suchtreffer konnten auf der Karte nicht dargestellt werden";
    }

    if(this.props.datasets.length){
  		map_view = (
  				<div className="MapResult" ref={this.ref_map}>
  					<div className='mapviewer'>
  						<p className="title">
  							{gmapTitle}
  						</p>
  						<LoadScript
  					    	id="script-loader"
  					    	googleMapsApiKey={Config.MAPS_API_KEY}
  					  		>
  					        <GoogleMap
  					            id="map-results"
  							    mapContainerStyle={{
  							      width: this.props.width,
  							      height: this.props.height
  							    }}
  							    options={{
  							    	mapTypeControl: false,
  							    	fullscreenControl: false,
  							    	streetViewControl: false,
  							    }}
  							    zoom={15}
  							    onLoad={map => {
  							    		this.map = map;
  							    		this.calculateBounds();
  									}}
  							    >

  								{ markers }
  								{ this.state.info_window }
  					        </GoogleMap>
  					  	</LoadScript>
  					</div>
  				</div>
  			);
			  this.show_map = true;
      }
		else {this.show_map = false; }

		return map_view;
	}
}

export default MapResult;
