import React, { Component } from 'react';

import Config from '../../Config';
import {FfoContext} from './FfoContext'; // use: this.context.XXX function|variable from FfoContext

import GmapFfoPartial from './GmapFfoPartial';
import GmapWindowDetailPartial from './GmapWindowDetailPartial';
import DropDownReactSelectPartial from './DropDownReactSelectPartial';

export default class FfoContextGmapPartial extends Component{

	constructor(props){
		super(props);
    this.m_offerTypeConfDatas = Config.FAMFREUNDORTS_OFFERTYPE_CONFIG;

    this.m_menuPoimap = '';
    this.m_menuPoimap_element_ref = '';

		this.m_styleBorderCurCotegory = '';

		this.m_ref_windowDetailPartial = React.createRef();
		this.state = {
			s_doUpdate: false,
		};
	}

	onClickShowWindowDetail(dsMarkerInfoDetails){
	  if(this.m_ref_windowDetailPartial){
			//event weiterleiten an das Kindelement
	    //ChildComponente soll sich selbst aktualisieren
	    this.m_ref_windowDetailPartial.updateChildrenComponentsFromParent(dsMarkerInfoDetails, dsMarkerInfoDetails.props.p_stylegmapwindowdetailpartial);
	  }
	}


	componentDidMount(){
    this.m_menuPoimap = this.initFillMenuPoiGM();
	}
  initFillMenuPoiGM() {
    let offerTypesConfDatas = this.m_offerTypeConfDatas;
		let submenus = [];
    for (let i=0;i < offerTypesConfDatas.length;i++) {
			let offerTypeItem = offerTypesConfDatas[i];
			let poiProviderId = `poiprovider-${offerTypeItem.ConfParentId}`;
			let fontColor = offerTypeItem.ConfPinImgId[0].gmMenuFontColor;
			submenus.push (
		  	<li key={offerTypeItem.ConfParentId} >
					<input id={poiProviderId}
									type="checkbox"
									defaultChecked={true}
									data-poiclass="' + offerTypeItem.ConfParentId + '"
									value={offerTypeItem.ConfParentId}
									onClick={this.context.handlerMenuUpdateFilterParentId}/>
					<label vor={poiProviderId} style={{color:fontColor}}>
            {offerTypeItem.ConfOfferTypeCategory}<br/>
					</label>
				</li>
		  );
		}
    //<input id="' + genFieldId + '" type="checkbox" data-poiclass="' + offerTypeItem.ConfOfferTypeId + '" value={offerTypeItem.ConfOfferTypeId} onChange={this.handleChangeMenuPoimapItem}/>
		let listElement = (<ul>{submenus}</ul>);
		let navElement = (<nav ref={c => (this.context.s_menuPoimap_element_ref = c)}>{listElement}</nav>);
		return navElement;
	}



	render(){
		let regionfilter = null;
		if(this.context.s_reactSelectRegionOptions_all !== null){
			regionfilter = (<div className="ffoGmapFilterCol">
				<DropDownReactSelectPartial
								p_onchangeCallback={options => {this.context.handlerRegionFilter(options)}}
								p_selectOptions={this.context.s_reactSelectRegionOptions_all}
								p_resetValues={this.context.s_resetRegionFilterToogle}
				/>
			</div>);
		}

		let cOnlyFFO = '';
		if(regionfilter === null){
				cOnlyFFO = "cWithoutRegionFilter";
		}


		return(
      <section className="cFfoContextGmapPartial">
        <div className="wrapper">
				<div className= {`ffoGmapMenuContainerRow ${cOnlyFFO}`}>
						<div className="ffoGmapMenuCol">{this.m_menuPoimap}</div>
						{regionfilter}
					</div>
					<GmapWindowDetailPartial 	ref={c => (this.m_ref_windowDetailPartial = c)}
																		p_style={{}}/>
					<GmapFfoPartial
											p_markers = {this.context.s_ffoShowMarkers}
											options={{
												mapTypeControl: false,
												fullscreenControl: false,
												streetViewControl: false
											}}
											mapContainerStyle={{
												//width: 600, //ohne gesetzte width wird diese 100% gesetzt
												height: 450		//ToDo StyleVariable für Mandanten ?
											}}
						/>
        </div>
      </section>
		);
	}
}
// Weise einen contextType zu, um den aktuellen FfoConext zu lesen.
// React wird den nahestehensten Theme Provider darüber finden und dessen Wert lesen.
FfoContextGmapPartial.contextType = FfoContext;
