import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Mailto from 'react-protected-mailto';
import { scroller } from 'react-scroll';

import TemplateMain from '../../basis/basis_t/TemplateMain';

/**
 * Static page: Impressum
 *
 * @class      ViewImpressum (name)
 */
class ViewImpressum extends Component {
  componentDidMount() {
    scroller.scrollTo('scroll_start', {
        duration: 1000,
        smooth: true
    });
  }

  render() {
    return(
        <section className="ViewImpressum" name='scroll_start'>
          <div className="wrapper">
            <div className="container">
              <h1>Impressum</h1><br/>
              <h3>Stand: 01.10.2020</h3>


              <br/>
              <h2>verantwortlich für den Inhalt</h2>
              <p className="mb-5">
              Landkreis Gifhorn<br/>
              - Der Landrat -<br/>
              Schlossplatz 1<br/>
              38518 Gifhorn<br/>
              Tel.: +49 (0)5371 82 – 0<br/>
              Fax: +49 (0)5371 82 – 357<br/>
              E-Mail: landkreis(at)gifhorn.de
              </p>
              <p>
                Der Landkreis Gifhorn ist eine Körperschaft des öffentlichen Rechts. Er wird vertreten durch den Landrat Dr. Ebel.
                <br />Ansprechpartner für den Internetauftritt <a href="www.familienwegweiser-gifhorn.de" target="_blank">www.familienwegweiser-gifhorn.de</a>:
                <br />Herr Stelling (Abteilungsleitung FB 4.1) und Frau Busse (Projektkoordinatorin FB 4.1).
              </p>

              <br/>
              <h2>Redaktion</h2>
              <p className="mb-5">
              Landkreis Gifhorn<br/>
              Fachbereich Jugend<br/>
              Birgit Busse<br/>
              Schlossplatz 1<br/>
              38518 Gifhorn<br/>
              Tel.: 05371/ 82 – 176<br/>
              E-Mail: familie@gifhorn.de
              </p>



              <h2>Nutzungsbedingungen</h2>
              <p className="mb-5">
              Die Nutzung ist für alle Nutzer und die Anbieter von Angeboten und Dienstleistungen für Familien kostenlos. Die im Familienwegweiser enthaltenen Angaben und Informationen wurden vom Landkreis Gifhorn sorgfältig recherchiert und geprüft. Wir sind bemüht dieses Informationsangebot stetig zu erweitern und zu aktualisieren. Für Anbieter, welche <a href="www.familienwegweiser-gifhorn.de" target="_blank">www.familienwegweiser-gifhorn.de</a> zur eigenständigen Datenerfassung nutzen, gelten folgende <Link to="/nutzungsbedingungen/">Nutzungsbedingungen</Link>.
              </p>



              <h2>Technischer Betrieb und Umsetzung (Backend)</h2>
              <p className="mb-5">
              GEBIT Münster GmbH & Co. KG, Corrensstraße 80, 48149 Münster
              <br/>
              www.gebit-ms.de<br/>
              </p>


              <h2>Technische Umsetzung (Frontend)</h2>
              <p className="mb-5">
              GEBIT Münster GmbH & Co. KG
              </p>


              <h2>Gestaltung</h2>
              <p className="mb-5">
              GEBIT Münster GmbH & Co. KG
              </p>


              <h2>Bildnachweise</h2>
              <p className="mb-5">
              istock<br/>
              Fotoarchiv Landkreis Gifhorn<br/>
              Privat
              </p>



              <h2>1. Inhalt des Onlineangebotes</h2>
              <p className="mb-5">
                Der Landkreis Gifhorn übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit, Qualität, jederzeitige Verfügbarkeit der bereitgestellten Informationen und für das Auftreten anderweitiger technischer Störungen. Haftungsansprüche gegen den Landkreis Gifhorn, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Landkreises Gifhorn kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
                <br/>
                Alle Angebote sind freibleibend und unverbindlich. Der Landkreis Gifhorn behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
              </p>




              <h2>2. Verweise und Links</h2>
              <p className="mb-5">
                Bei direkten oder indirekten Verweisen auf fremde Internetseiten („Links“ – in erster Linie die der Anbieter, die ihre Angebote auf <a href="www.familienwegweiser-gifhorn.de" target="_blank">www.familienwegweiser-gifhorn.de</a> einstellen), die außerhalb des Verantwortungsbereiches des Landkreises Gifhorn liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Landkreis Gifhorn von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.
                <br/>
                Der Landkreis Gifhorn erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der verknüpften Seiten hat der Landkreis Gifhorn keinerlei Einfluss. Diese Feststellung gilt für alle innerhalb des Internetangebotes <a href="www.familienwegweiser-gifhorn.de" target="_blank">www.familienwegweiser-gifhorn.de</a> gesetzten Verweise. Sofern von Ihnen illegale, fehlerhafte oder unvollständige Inhalte bemerkt werden, bittet der Landkreis Gifhorn Sie um sofortige Mitteilung (familie(at)gifhorn.de). Eine Prüfung erfolgt dann unverzüglich.
              </p>



              <h2>3. Urheber- und Kennzeichenrecht</h2>
              <p className="mb-5">
                Der Landkreis Gifhorn ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken und Texte zu beachten, von ihm selbst erstellte Grafiken und Texte zu nutzen oder auf lizenzfreie Grafiken und Texte zurückzugreifen.
              <br/>
                Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind!
              </p>
              <p>
                Das Copyright für veröffentlichte, vom Landkreis Gifhorn selbst erstellte Objekte bleibt alleine beim Landkreis Gifhorn. Eine Vervielfältigung oder Verwendung solcher Grafiken und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Landkreises Gifhorn nicht gestattet.
              </p>


              <br/>
              <h2>4. Datenschutz</h2>
              <p className="mb-5">
                Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (emailadresse Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Eine Weitergabe an Dritte erfolgt nicht. Weitere Informationen zum Datenschutz entnehmen Sie bitte unserer DATENSCHUTZERKLÄRUNG.
              </p>

              <h2>5. Cookies</h2>
              <p className="mb-5">
                Um Ihnen die Internet-Nutzung und die Kommunikation zu vereinfachen, nutzen wir verschiedene Technologien, u.a. sogenannte „Cookies“. Hierbei handelt es sich um kleine Textdateien (max. 4KB groß) welche unsere Website an Ihren Browser schickt und die auf Ihrem PC abgelegt werden, um sie dort zu speichern.
              </p>
              <p className="mb-5">
                Zweck dieser Cookies sind die Speicherung Ihrer Anmeldedaten, sofern Sie dauerhaft als registrierter Benutzer im Portal angemeldet bleiben möchten, auch wenn Sie zwischen durch andere Internetangebote aufsuchen oder den Browser schließen. Die bei Ihnen von uns stammenden Cookies verlieren nach 16 Stunden ihre Gültigkeit.
              </p>
              <p className="mb-5">
                Falls Sie es wünschen, können Sie eine Speicherung von Cookies über Ihren Web-Browser generell unterdrücken oder entscheiden, ob Sie per Hinweis eine Speicherung wünschen oder nicht. Die Nichtannahme von Cookies hat dann lediglich zur Folge, dass die Anmeldedaten nicht gespeichert werden können.
              </p>

            </div>
          </div>
        </section>
	);
  }
}

export default ViewImpressum;
