import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { scroller } from 'react-scroll';

import TemplateMain from '../../basis/basis_t/TemplateMain';
import { AppContext } from '../../basis/basis_t/AppContext';
import ApiConnector from '../../common/model/ApiConnector';

import ConfigCards from '../../ConfigCards';

import CreateResultCardsGmap from '../../basis/basis_t/CreateResultCardsGmap';
import TopicAndAudience from '../../basis/basis_t/TopicAndAudience';

/**
 * Shows the sub page for the selected topic
 *
 * @class      ViewBesondereOrte (name)
 */

 export default class ViewBesondereOrte extends Component {

  constructor(props){
    super(props);
    this.m_configCards = ConfigCards;
    this.m_pagedata = this.m_configCards.SITE_BESONDERE_ORTE;

    this.state = {
      s_apiResults: null
    }
  }


  componentDidMount(prevProps, prevState) {
    scroller.scrollTo('scroll_start', {
      duration: 1000,
      smooth: true
    });

    //Offertype für BO anwenden und results wie FFO anzeigen
    let filter_and_options = this.m_pagedata.cardhandel_filters;

    ApiConnector.getBesondereOrte(
      (api_data) => { this.setState({s_apiResults: api_data});},
      (errorMsg) => {console.log('CreateResultCardsGmap Error: ', errorMsg);}
    );
  }

  render() {
    let out = null;

    let outResultElem = '';
    if(this.state.s_apiResults !== null){
      outResultElem = (<CreateResultCardsGmap
                              p_serachresults={this.state.s_apiResults}
                              p_pagedata={this.m_pagedata} />);
    }else{
      outResultElem = (<p>Ergebnisse werden geladen ...</p>);
    }

    //get page data
    if(this.m_pagedata){
      let hero_data = {
          img: this.m_pagedata.cardheaderimg,
          title: this.m_pagedata.cardheadertitle,
          body: this.m_pagedata.cardheaderbody
      };
      //create page
      out = (
            <TemplateMain showHero={true} heroData={hero_data} searchNavTop={true} p_showSearchFormEnhanced={false}>
    					<main>
    						<div className="row">
    							<TopicAndAudience layout="sidebar"/>
                  <div style={{/*style={{marginTop:'-60px'}} */}} name="scroll_start" />
                  {outResultElem}
    						</div>
    					</main>
    				</TemplateMain>
          );
    }else{
        //redirect to 404
        out = <Redirect to="/404" />;
    }
    return out;
  }
}
ViewBesondereOrte.contextType = AppContext;
