//start with polyfill for internet explorer
// import 'react-app-polyfill/ie9';
//other polyfills for IE
// import 'core-js/features/string/includes';
// import 'core-js/features/string/starts-with';
// import 'core-js/features/string/ends-with';
// import 'core-js/features/array/includes';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";

//import site
import App from './sites/fgifhorn_t/App.js';

import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/js/bootstrap.js';


//Config SCSS
import "./ConfigScss.scss";

ReactDOM.render(<Router><App /></Router>, document.getElementById('sitewrapper'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
