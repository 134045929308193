import React, { Component } from 'react';

import {AppContext} from './AppContext';


import SearchResults from './SearchResults';
import TemplateMain from './TemplateMain';
import TopicAndAudience from './TopicAndAudience';

import MessageSharePartial from '../../common/components/MessageSharePartial';

/**
 * Wrapper/View component with MainTemplate for the SearchResults.
 *
 * @class      ViewResults (name)
 */
class ViewResults extends Component{

	render(){

		let result_view = null;
		if(this.context.search_results)
		{
			result_view = <SearchResults results={this.context.search_results} />
		}
		else{
			result_view = <MessageSharePartial>Ergebnisse werden gesucht ...</MessageSharePartial>;
		}

		return(
				<TemplateMain showHero={true} searchNavTop={true}>

					<main className="ViewResults">
						<div className="main-row">
							<TopicAndAudience layout="sidebar" />
							{result_view}
						</div>
					</main>


				</TemplateMain>
		);
	}
}
ViewResults.contextType = AppContext;
export default ViewResults;
