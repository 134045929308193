import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Mailto from 'react-protected-mailto';
import { scroller } from 'react-scroll';

import TemplateMain from '../../basis/basis_t/TemplateMain';

/**
 * Static page: Impressum
 *
 * @class      ViewKreisjugendfoerderung (name)
 */
class ViewKreisjugendfoerderung extends Component {
  componentDidMount() {
    scroller.scrollTo('scroll_start', {
        duration: 1000,
        smooth: true
    });
  }

  render() {
    return(
        <section className="ViewKreisjugendfoerderung ViewStatic" name='scroll_start'>
          <div className="wrapper">
            <div className="container">
              <h1>Die Kreisjugendförderung</h1>
              <p>
                Die Kreisjugendförderung gestaltet ihre <strong>Kinder- und Jugendarbeit nach §11 SGB VIII</strong> in enger Zusammenarbeit mit den 10 Jugendförderungen der kreisangehörigen Gebietseinheiten und den Trägern der freien Jugendarbeit (verbandliche Jugendarbeit) des Landkreises Gifhorn.
              </p>
              <p>
                Kinder- und Jugendarbeit wird im Landkreis Gifhorn von unzähligen Vereinen und Verbänden angeboten. Im Landkreis Gifhorn sind in den sieben Samtgemeinden (Boldecker Land, Brome, Hankensbüttel, Isenbüttel, Meinersen, Papenteich, Wesendorf), der Einheitsgemeinde Sassenburg und den Städten Gifhorn und Wittingen, ca. 25 hauptamtliche Jugendpfleger*innen tätig. Dazu kommt eine Vielzahl von pädagogischen Mitarbeiter*innen und ehrenamtlich Engagierten.
              </p>
              <p>
                Gemeinsam bieten Sie örtliche Ferienspaßaktionen, Sportangebote, Elternarbeit, Seminare, Sucht- und Gewaltprävention, Freizeiten, sowie Jugendzentren und offene Jugendtreffs im Landkreis Gifhorn an. Die Jugendpflegen arbeiten, zum Wohle der Kinder- und Jugendlichen des Landkreises Gifhorn, in Absprache mit den Schulsozialpädagoginnen und -pädagogen und den weiteren Mitarbeiter*innen der Schulen. Diese Arbeit unterstützt der Landkreis durch finanzielle Personalkostenzuwendungen, Fortbildungen, eigene Projekte der Kinder- und Jugendarbeit, sowie durch Netzwerkarbeit.
              </p>
              <p>
                Anregungen, Kritik und Fragen gern an <a href="mailto:bernhard.schuhose@gifhorn.de">bernhard.schuhose@gifhorn.de</a>.
              </p>
              <h3>Die Kreisjugendförderung legt die Schwerpunkte ihrer Arbeit wie folgt:</h3>
              <ul>
                <li>Sucht- und Gewaltprävention in enger Zusammenarbeit mit den Schulen im Landkreis 
                  <br/><a href={process.env.PUBLIC_URL + '/pdf/Praeventionskonzept.pdf'}>&gt;&gt; PDF Präventionskonzept</a>
                </li>
                <li>Interkulturelle/ internationale Jugendarbeit in unterstützender Tätigkeit für Friederike Rotermund (<a href="mailto:friederike.rotermund@gifhorn.de">friederike.rotermund@gifhorn.de</a>)</li>
                <li>Beratung von jungen Menschen</li>
                <li>Themenspezifische und individuell gestaltete Fortbildungen für Mitarbeiter*innen der Jugendpflegen und Jugendförderungen im Landkreis, die interessierte Öffentlichkeit, sowie für in der Jugendarbeit tätigen Menschen. <br/>Teilen Sie Ihre Themenwünsche gern unter <a href="mailto:bernhard.schuhose@gifhorn.de">bernhard.schuhose@gifhorn.de</a> mit.</li>
                <li>Angebote in Sport-, Erlebnis- und Theaterpädagogik </li>
                <li>Ausleihe von und Einweisung in erlebnispädagogische Ausrüstung (z.B. Niederseilgarten, Interaktionsspielzeug, Theaterarbeit, Wald-Tage), sowie gemeinsame Konzipierung von Angeboten.</li>
                <li>Ausleihe eines 9-Sitzer Transporters (Führerscheinklasse B)
                    <br/> <a href={process.env.PUBLIC_URL + '/pdf/Richtlinien_und_Ausleihbedingungen_für_das_Erlebnismobil_des_Landkreis_Gifhorn.pdf'}>&gt;&gt; PDF „Richtlinien Ausleihbedingungen“</a>
                </li>
                <li>Temporäre Vergabe von Software für digitale Konferenzen </li>
                <li>Jugendschutz</li>
                <li>Der Jugendschutz des Landkreis Gifhorn versteht seine Aufgabe darin einer Gefährdung von Kindern und Jugendlichen durch Information, Beratung und erzieherische Impulsen vorzubeugen, sowie einen kompetenten Umgang mit jugendrelevanten und aktuellen Gefährdungen zu fördern.</li>
                <li><a href={process.env.PUBLIC_URL + '/pdf/Jugendschutz.pdf'}>&gt;&gt; PDF Jugendschutz</a></li>
                <li><a href={process.env.PUBLIC_URL + '/pdf/Checkliste_Veranstaltungen.pdf'}>&gt;&gt; PDF Checkliste Veranstaltungen</a></li>
                <li>Netzwerkarbeit auf Landkreisebene wie z.B. die Gründung von Arbeitskreisen, Bündnissen und die allgemeine Vernetzung von Institutionen</li>
                <li>Konzeptionelle Präventionsberatung für Institutionen
                    <br/><a href={process.env.PUBLIC_URL + '/pdf/Praeventionsverstaendnis.pdf'}>&gt;&gt; PDF Präventionsverständnis der Kreisjugendförderung</a>
                </li>
                <li>Förderung verbandlicher Jugendarbeit bei allen Belangen der Jugendarbeit 
                    <br/><a href={process.env.PUBLIC_URL + '/pdf/Richtlinie_über_die_Foerderung_der_Jugendarbeit_im_Landkreis_Gifhorn.pdf'}>&gt;&gt; PDF‘s „Richtlinien zur Förderung“ und Anträge</a>
                </li>
                <li>Jugendleiter*innen Card <br/> Für weitere Infos besuchen Sie bitte die Internetseite des Landesjugendring unter www.ljr.de. Bei Fragen zu Ausbildungsterminen wenden Sie sich bitte an <a href="mailto:bernhard.schuhose@gifhorn.de">bernhard.schuhose@gifhorn.de</a>. </li>
                <li>PDF Liste mit den Vergünstigungen für Juleicacartinhaber*innen </li>
                <li>Vermittlung von Trainer*innen und Kooperationspartner*innen in Bereichen wie Selbstverteidigung und Kampfsport, Erlebnispädagogik, Musik, Theater, Medien und vielem mehr.</li>                
              </ul>

              <h3>Hier findet ihr weitere interessante Anlaufstellen und Angebote zum Thema Jugendarbeit:</h3>
              <ul>
                <li><a href="https://www.ljr.de" target="_blank">Landesjugendring (www.ljr.de)</a></li>
                <li><a href="https://www.juleica.de" target="_blank">JuLeiCa (www.juleica.de)</a></li>
                <li>Landesjugendamt Hannover</li>
                <li>Landeszentrale für politische Bildung Niedersachsen</li>
                <li>Landesstelle Jugendschutz Niedersachsen</li>
                <li>Gifhorner-Anti-Gewalt Akademie</li>
                <li><a href="https://www.b-e-j.de" target="_blank">Erziehungsberatungsstelle Gifhorn (www.b-e-j.de)</a></li>
                <li><a href="https://www.diakonie-wolfsburg.de/suchthilfe/" target="_blank">Sucht und Drogenberatungsstelle Gifhorn (www.diakonie-wolfsburg.de/suchthilfe/)</a></li>
                <li><a href="https://www.awo-gf.de/CM/">Selbsthilfegruppen (www.awo-gf.de/CM/)</a></li>
                <li>Dialog e.V.</li>
                <li>Präventionsbündnis für ein friedfertiges Gifhorn</li>
                <li>PDF Liste mit weiteren Links ?</li>
              </ul>

              <h2>Kontakt</h2> 
              <p>
                Ihre Frage(n) an die <br/>
                <strong>Kreisjugendförderung</strong><br/>
                Bernhard Schuhose<br/>
                Telefon: 05371 82 805 / Handy: 01728281837  <br/>
                Raum 148<br/>
                E-Mail: <a href="mailto:bernhard.schuhose@gifhorn.de">bernhard.schuhose@gifhorn.de</a> ; <a href="mailto:kreisjugendförderung@gifhorn.de">kreisjugendförderung@gifhorn.de</a><br/>                
              </p>

              <p>
                Ihre Frage(n) an die <br/>
                <strong>Internationale / interkulturelle Jugendarbeit</strong> <br/>
                Friederike Rotermund <br/>
                Telefon: 05371 82 792 <br/>
                Raum 149 <br/>
                E-Mail <a href="mailto:friederike.rotermund@gifhorn.de">friederike.rotermund@gifhorn.de</a> ; <a href="mailto:kreisjugendförderung@gifhorn.de">kreisjugendförderung@gifhorn.de</a>                
              </p>

              <p>Besuchen Sie gern auch die Seite des Präventionsbündnis für eine friedfertiges Gifhorn unter <a href="https://www.praeventionsbuendnis-gifhorn.jimdofree.com" target="_blank">www.praeventionsbuendnis-gifhorn.jimdofree.com</a>.</p>

            </div>
          </div>
        </section>
  );
  }
}

export default ViewKreisjugendfoerderung;



