import React from 'react';
import Config from '../../Config';
import {AppContext} from './AppContext';

export default class SearchAudience extends React.Component {

	constructor(props){
		super(props);

		this.audience = null;
	}

	searchTopic(option_id){
		//Filter ähnlich ConfigCards aufbauen:
		let filter = [{[Config.FILTER_IDS['audience']]: [option_id]}];
	  this.context.callApiSearcResultshFromCardFiltersOr(filter);
	}

	createAudienceLinks(){
		let audience_filter = this.context.filters[Config.FILTER_IDS['audience']];
		let option;
		this.audience = [];

		if(audience_filter){
			for(let id in audience_filter.options)
			{
				option = audience_filter.options[id];
				this.audience.push(<span className="link" key={id} onClick={e => {this.searchTopic(id)}}>{option.value}</span>);
			}
		}
	}

	render() {
		this.createAudienceLinks();

		return (
			<div className="SearchAudience">
				<div className="wrapper">
					<h2>Alles für:</h2>
					{/*<section className="content">*/}
						{Config.SearchAudienceTextContent}
					<section className="audience">
						{this.audience}
					</section>
				</div>
			</div>
		);
	}
}
SearchAudience.contextType = AppContext;