import React, { Component } from 'react';
import { scroller } from 'react-scroll';
import Mailto from 'react-protected-mailto';

import TemplateMain from './TemplateMain';
import KontaktFormSharePartial from '../../common/components/KontaktFormSharePartial';



/**
 * Static page: Kontakt
 * The form data is submitted via the ApiConnector
 *
 * @class      ViewKontakt (name)
 */
export default class ViewKontakt extends Component {

  componentDidMount() {
    scroller.scrollTo('scroll_start', {
            duration: 1000,
            smooth: true
    });
  	window.addEventListener('scroll', this.listenToScroll);
  }
  render() {
    let formular = (<KontaktFormSharePartial p_btnReset = {true}/>);

    let kontaktText = '';
          // ( <div className="kontakttext">
          //   Alternativ wenden Sie sich gern direkt an Frau Zimmermann, Abt. Jugend und Familie, 02373 903-157, <Mailto email="j.zimmermann@menden.de"/>, Neumarkt 5, 58706 Menden.
          // </div>);

    return(
      <section className="ViewKontakt" name="scroll_start">
        <div className="wrapper">
          <div className="container">
            <h2 className="">Bei Fragen oder Anregungen stehen wir Ihnen gerne zur Verfügung</h2>
            <br/>
            <h4>Kontaktformular</h4>
            <br/>
            <p>Bitte füllen Sie alle mit * markierten Felder (Pflichtfelder) aus.</p>
            {formular}
            {kontaktText}
          </div>
        </div>
      </section>
      );
  }
}
