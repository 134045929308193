import React, { Component } from 'react';
import Dummy from 'dummyjs';

import {AppContext} from './AppContext';
import Config from '../../Config';

import SearchDetails from './SearchDetails';
import TemplateMain from './TemplateMain';
import TopicAndAudience from './TopicAndAudience';
import MessageSharePartial from '../../common/components/MessageSharePartial';


/**
 * Wrapper/View component for SearchDetails.
 *
 * @class      ViewDetail (name)
 */
class ViewDetail extends Component{
	constructor(props){
		super(props);

		this.c_jsondata = null;
	}

	componentDidMount() {
		//search a dataset_id from the API
		//this.props.match is coming from the Router
		//@see App.js > render + @see https://reacttraining.com/react-router/web/api/match
		if(this.props.match && this.props.match.params.id){
				this.context.getDetailsFromApi(this.props.match.params.id);
		}
		// if calls ViewPreview
		else if(this.props.p_datasetOffer){
			this.c_jsondata = this.props.p_datasetOffer;
		}
	}

	render(){
		let details = null;
		let bWatermarkShow = '';
		if(this.c_jsondata)
		{
			details = <SearchDetails dataset={this.c_jsondata[0]}/>
			bWatermarkShow = true;
		}else if(this.context.searchdetail_results){
			details = <SearchDetails dataset={this.context.searchdetail_results[0]}/>
		}
		else{
			details = <MessageSharePartial>Angebot wird geladen ...</MessageSharePartial>;
		}

		let hero_data = {
			img: Config.ImgPlacholder,
			// title: 'Angebot',
			// body: Dummy.text(10, 20)
		}

		return(
				<TemplateMain showHero={true} heroData={hero_data} searchNavTop={true} p_addwatermark={bWatermarkShow}>

					<main className="ViewDetail">
						<div className="row">
							<TopicAndAudience layout="sidebar"/>
							{details}
						</div>
					</main>

				</TemplateMain>
		);
	}
}
ViewDetail.contextType = AppContext;
export default ViewDetail;
