import React, { Component } from 'react';

import {AppContext} from './AppContext';
import TemplateMain from './TemplateMain';
import TopicAndAudience from './TopicAndAudience';

import ConfigCards from '../../ConfigCards';
import CreateSearchCardsWrapper from './CreateSearchCardsWrapper';


/**
 * This is the main view with SearchForm, QuickResult and SearchCards
 *
 * @class      ViewHome (name)
 */
class ViewHome extends Component{

	render(){

		return(
				<TemplateMain showHero={true}>

					<main className="ViewHome">
						<CreateSearchCardsWrapper p_wrapperconfigcards={ConfigCards.CARDS_HOME}/>
						<TopicAndAudience layout="block" />
					</main>

				</TemplateMain>
		);
	}
}
ViewHome.contextType = AppContext;
export default ViewHome;
