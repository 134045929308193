import React from 'react';
import Config from './Config';


//Bilder fürs Header
// import headerBeratung from './sites/vorlage_t/media/header/header_beratung.jpg';
// import headerBildung from './sites/vorlage_t/media/header/header_bildung.jpg';
// import headerFfo from './sites/vorlage_t/media/header/header_ffo.jpg';
// import headerFreizeit from './sites/vorlage_t/media/header/header_freizeit.jpg';
// import headerGesundheit from './sites/vorlage_t/media/header/header_gesundheit.jpg';
// import headerHilfe from './sites/vorlage_t/media/header/header_hilfe.jpg';
// import headerKinderbetreuung from './sites/vorlage_t/media/header/header_kinderbetrueung.jpg';
// import headerVeranstaltungen from './sites/vorlage_t/media/header/header_veranstatlungen.jpg';

//Bilder fürs HomeCards
import imgCardHomeFfOrte from './sites/fgifhorn_t/media/themen/thema_ffo.jpg';
import imgCardHomeGeburt from './sites/fgifhorn_t/media/themen/thema_geburt.jpg';
import imgCardHomeFreizeit from './sites/fgifhorn_t/media/themen/thema_freizeit.jpg';
import ImgCardHomeGeburt from './sites/fgifhorn_t/media/themen/thema_geburt.jpg';
import ImgCardHomeGesundheit from './sites/fgifhorn_t/media/themen/thema_gesundheit.jpg';
import imgCardHomeJugendarbeit from './sites/fgifhorn_t/media/themen/thema_jugendarbeit.jpg';
import imgCardHomeKinderbetreuung from './sites/fgifhorn_t/media/themen/thema_kinderbetreuung.jpg';
import imgCardHomeBeratung from './sites/vorlage_t/media/themen/thema_beratung.jpg';
import imgCardHomeBildung from './sites/fgifhorn_t/media/themen/thema_bildung.jpg';
import imgCardHomeLebenInAlter from './sites/fgifhorn_t/media/themen/thema_lebenimalter.jpg';
import imgCardHomeNorfall from './sites/fgifhorn_t/media/themen/thema_notfall.jpg';
import imgCardHomeNatur from './sites/fgifhorn_t/media/themen/thema_natur.jpg';
import imgCardHomeBesondereOrte from './sites/fgifhorn_t/media/themen/thema_besondereorte.jpg';
import imgCardHomeKunst from './sites/fgifhorn_t/media/themen/thema_kunst.jpg';


import IImgFfoSpielplatz from './sites/fgifhorn_t/media/themen/ffo_spielplatz.jpg';
import IImgFfoToilette from './sites/fgifhorn_t/media/themen/ffo_toilette.jpg';
import IImgFfoStillpunkt from './sites/fgifhorn_t/media/themen/ffo_stillpunkt.jpg';
import IImgFfoWickelpunkt from './sites/fgifhorn_t/media/themen/ffo_wickelpunkt.jpg';
import IImgFfoGuteFee from './sites/vorlage_t/media/themen/ffo_gutefee.jpg';
import IImgFfoElterncafe from './sites/vorlage_t/media/themen/ffo_elterncafe.jpg';



// import imgCardBesOrte_78ba5014 from './sites/fgifhorn_t/media/img/78ba5014-663e-4dd9-bb42-52727bb31e16.jpg';
// import imgCardBesOrte_94c0a505 from './sites/fgifhorn_t/media/img/94c0a505-646d-49a5-b51c-8764fe5ec8a1.jpg';
// import imgCardBesOrte_9becfbd2 from './sites/fgifhorn_t/media/img/9becfbd2-1b31-48ce-8720-527a88fd7243.jpg';
// import imgCardBesOrte_da79dbd0 from './sites/fgifhorn_t/media/img/da79dbd0-9924-4586-886a-bb266761742f.jpg';
// import imgCardBesOrte_dc057e34 from './sites/fgifhorn_t/media/img/dc057e34-ff0a-496e-8213-5f422a4d1f09.jpg';
// import imgCardBesOrte_de4e41f2 from './sites/fgifhorn_t/media/img/de4e41f2-4b35-4de8-a2fe-508b3c60c1d4.jpg';
// import imgCardBesOrte_e56d0ae5 from './sites/fgifhorn_t/media/img/e56d0ae5-73dd-435f-b714-8cbd42e30502.jpg';


export default class ConfigCards{

  //man braucht eine statische Variablen, damit diese von anderen js-Dateien geladen werden können
  //sonst für die lokale verwendung, braucht man diese nicht
  static ImgFfoSpielplatz = IImgFfoSpielplatz;
  static ImgFfoToilette = IImgFfoToilette;
  static ImgFfoStillpunkt = IImgFfoStillpunkt;
  static ImgFfoWickelpunkt = IImgFfoWickelpunkt;
  static ImgFfoGuteFee = IImgFfoGuteFee;
  static ImgFfoElterncafe = IImgFfoElterncafe;

  static ImgHomeFfo = imgCardHomeFfOrte;
  static ImgHomeBesondereOrte = imgCardHomeBesondereOrte;
  // static ImgCardsBesOrte_78ba5014 = imgCardBesOrte_78ba5014;
  // static ImgCardsBesOrte_94c0a505 = imgCardBesOrte_94c0a505;
  // static ImgCardsBesOrte_9becfbd2 = imgCardBesOrte_9becfbd2;
  // static ImgCardsBesOrte_da79dbd0 = imgCardBesOrte_da79dbd0;
  // static ImgCardsBesOrte_dc057e34 = imgCardBesOrte_dc057e34;
  // static ImgCardsBesOrte_de4e41f2 = imgCardBesOrte_de4e41f2;
  // static ImgCardsBesOrte_e56d0ae5 = imgCardBesOrte_e56d0ae5;

  /**
  * [coreListItems] where ListId = '5f232d80-d7a0-4f41-9b79-26aaf5d38f54'
  * eine Json-Liste mit zur Verfügung stehenden Tokens aus dem Backend
  * hier sind nur die 'token'-Werte für eine Lesbarkeit wichtig
  */
  static MERKMAL_LISTE_THEMEN = {
    1:{token:'1', description: 'Arbeitswelt, Ausbildung & Beruf'},
    2:{token:'2', description: 'Begleitung & Unterstützung'},
    3:{token:'3', description: 'Bildung & Lernen'},
    4:{token:'4', description: 'Entlastung'},
    5:{token:'5', description: 'Erholung/Entspannung/Wohlfühlen'},
    6:{token:'6', description: 'Erziehung'},
    7:{token:'7', description: 'Familie & Partnerschaft'},
    8:{token:'8', description: 'Finanzielle & materielle Versorgung'},
    9:{token:'9', description: 'Freizeit'},

    10:{token:'10', description: 'Gesundheit'},
    11:{token:'11', description: 'Handwerk & Technik'},
    12:{token:'12', description: 'Integration & Inklusion'},
    13:{token:'13', description: 'Kunst/Kultur/Musik/Museen'},
    14:{token:'14', description: 'Leben im Alter'},
    15:{token:'15', description: 'Leben mit Handicap'},
    16:{token:'16', description: 'Medien(pädaogik)'},
    17:{token:'17', description: 'Mobilität & Verkehr'},
    18:{token:'18', description: 'Natur & Umwelt'},
    19:{token:'19', description: 'Notfall/Krise'},
    20:{token:'20', description: 'Pflege'},
    21:{token:'21', description: 'Politik & Gesellschaft'},
    22:{token:'22', description: 'Prävention & Vorsorge'},
    23:{token:'23', description: 'Recht & Finanzen'},
    24:{token:'24', description: 'Religion'},
    25:{token:'25', description: 'Rettungs- & Hilfstechniken'},
    26:{token:'26', description: 'Soziale Kontakte'},
    27:{token:'27', description: 'Sport & Spiel'},
    28:{token:'28', description: 'Sprache'},
    29:{token:'29', description: 'Sucht'},


    30:{token:'30', description: 'Täglicher Bedarf'},
    31:{token:'31', description: 'Tod & Trauer'},
    32:{token:'32', description: 'Tradition & Brauchtum'},
    33:{token:'33', description: 'Wohnen'},
  };
  static MERKMAL_LISTE_ZIELGRUPPE = {
    1:{token:'1', description: 'Alleinerziehende'},
    2:{token:'2', description: 'Babys'},
    3:{token:'3', description: 'Eltern'},
    4:{token:'4', description: 'Erwachsene'},
    5:{token:'5', description: 'Fachkräfte'},
    6:{token:'6', description: 'Familien'},
    7:{token:'7', description: 'Jugendliche'},
    8:{token:'8', description: 'Kinder'},
    9:{token:'9', description: 'Kleinkinder'},
    10:{token:'10', description: 'Menschen mit Behinderung'},
    11:{token:'11', description: 'Mütter'},
    12:{token:'12', description: 'Schwangere und werdende Eltern'},
    13:{token:'13', description: 'Väter'},
    14:{token:'14', description: '60 plus'},
  };

  /**
    * Konfiguration der Kacheln für die Startseite
    * title(Nodename):{
    *   @cardimg: Bild für die Kachel (570x470)
    *   @cardhandel_link_to: Seitenurl zu den Unterkacheln, Route. Dies entspricht Nodename in CARDS_LEVEL_ONE
    *   @cardhandel_filters: Startet direkt eine Suche mit den aufgelistet Filtern
    *   @cardhandek_redirect_to: öffnet eine statische Seite
    *   @cardbody: kurze Beschreibung
    * }
    */
  static CARDS_HOME = {
      'cardid_1': {
        cardtitle: 'Schwangerschaft, Geburt & Baby',
        cardimg: imgCardHomeGeburt,
        cardbody: 'Von der Hebammenzentrale über die Beratungsstelle bis zur Krabbelgruppe'
        ,cardhandel_filters: [
          {[Config.FILTER_IDS['topic']]: ['7','24','5','10','22','8','23','4','26','2','17','15','30','6']},
          {[Config.FILTER_IDS['audience']]: ['2','12']}
        ]
      },
      'cardid_2':{
        cardtitle: 'Kinderbetreuung',
        cardimg: imgCardHomeKinderbetreuung,
        cardbody: 'Alles rund um Kindertagespflege, Krippe, Kita & Schulkind-Betreuung'
        ,cardhandel_filters:[
          {[Config.FILTER_IDS['topic']]: ['7','27','24','5','18','10','22','28','4','26','2','12','6']},
          {[Config.FILTER_OFFER_TYPES]: ['BC840E4F-F973-4C26-A4EA-C2E507947AE5', '5CD29B68-66F2-4A7C-A161-97AC0C59F6EE']}
        ]
      },
      'cardid_3': {
        cardtitle: 'Bildung & Schule',
        cardimg: imgCardHomeBildung,
        cardbody: 'Schulen und Bildungsangebote unterschiedlicher Träger und Institutionen'
        ,cardhandel_filters: [
          {[Config.FILTER_IDS['topic']]: ['7','16','24','11','21','3','29','28','2','12','1','6']},
          {[Config.FILTER_OFFER_TYPES]: ['5A92A2F9-A02C-49B8-AF07-A0DDD5988A1D','2D168119-A011-4EF0-B677-2E4706C53150','9AD50DAC-D11B-4740-9336-825111761920']}
        ]
      },
      'cardid_4': {
        cardtitle: 'Sport & Freizeit',
        cardimg: imgCardHomeFreizeit,
        cardbody: 'Freizeitangebote im Landkreis Gifhorn: Vereine, Veranstaltungen und vieles mehr'
        ,cardhandel_filters: [
          {[Config.FILTER_IDS['topic']]: ['7','27','32','5','21','18','13','9','10','22','26','12','14']},
          {[Config.FILTER_OFFER_TYPES]: ['AF09E6FF-3064-4BB1-815A-7AE72D0A0017','58BA4031-092F-4870-A993-4D40340874FE','E368ABF5-7D87-432A-ADC4-04203CB1BA38','28ECEAF3-D642-45AB-A31D-897DD55A586C']}
        ]
      },
      'cardid_5': {
        cardtitle: 'Leben im Alter',
        cardimg: imgCardHomeLebenInAlter,
        cardbody: 'Gut und selbstbestimmt leben im Alter – Angebote von Nachbarschaftshilfe bis zur Pflege'
        ,cardhandel_filters: [
          {[Config.FILTER_IDS['topic']]: ['7','32','5','21','18','8','33','25','4','26','19','2','17','15','20','14','31','30']},
          {[Config.FILTER_IDS['audience']]: ['14']},
          {[Config.FILTER_OFFER_TYPES]: ['0FE03302-4BDF-4043-BF2D-7B14CC4A1889','338A16A1-933E-42B8-BACC-C3BE2AC3DFCF','CE1F84D9-FEB8-40FE-808B-CB63D27AA844']}
        ]
      },
      'cardid_6': {
        cardtitle: 'Kunst-Kultur-Musik',
        cardimg: imgCardHomeKunst,
        cardbody: 'Angebote und Veranstaltungen zum Sehen, Hören und Selbermachen'
        ,cardhandel_filters: [
          {[Config.FILTER_IDS['topic']]: ['11','5','21','18','13','9','3','26','14']},
          {[Config.FILTER_OFFER_TYPES]: ['EFECEE5B-9927-4E8A-BD46-EDCBC7938D1A','845DA043-3DC4-486A-88E6-BBCF78E61A81']}
        ]
      },
      'cardid_7': {
        cardtitle: 'Gesundheit',
        cardimg: ImgCardHomeGesundheit,
        cardbody: 'Gesundheit geht uns alle an! An Vorsorge denken, fit bleiben und kompetente Unterstützung finden'
        ,cardhandel_filters: [
          {[Config.FILTER_IDS['topic']]: ['7','27','5','3','10','22','29','8','33','25','26','19','2','15','12','20','14','30']},
          {[Config.FILTER_OFFER_TYPES]: ['0FE03302-4BDF-4043-BF2D-7B14CC4A1889','338A16A1-933E-42B8-BACC-C3BE2AC3DFCF','CE1F84D9-FEB8-40FE-808B-CB63D27AA844','FD377DAB-5A1B-4699-8EDB-182D603F1138']}
        ]
      },
      'cardid_8': {
        cardtitle: 'Jugendarbeit & Ferienbörse',
        cardimg: imgCardHomeJugendarbeit,
        cardbody: 'Angebote der Kinder- und Jugendarbeit Veranstaltungen, Ferienbörse',
        cardhandel_link_to: '/thema/jugendarbeit'
      },
      'cardid_9': {
        cardtitle: 'Notfall & Krise',
        cardimg: imgCardHomeNorfall,
        cardbody: 'Beratungs- und Unterstützungsangebote unterschiedlicher Träger und Institutionen, Selbsthilfegruppen '
        ,cardhandel_filters: [
          {[Config.FILTER_IDS['topic']]: ['24','10','22','29','8','23','33','25','4','26','19','2','15','12','20','14','31','1','30','6']}
        ]
      },
      'cardid_10': {
        cardtitle: 'Natur & Nachhaltigkeit',
        cardimg: imgCardHomeNatur,
        cardbody: 'Alles zum Lokalen Einkaufen, Repair-Cafés, Gruppen und Veranstaltungen rund um Natur- und Umweltschutz'
        ,cardhandel_filters: [
          {[Config.FILTER_IDS['topic']]: ['7','27','32','5','21','18','9','3','10','22','33','28','26','15','12','31','30']}
        ]
      },
      'cardid_11': {
        cardtitle: 'Besondere Orte',
        cardimg: imgCardHomeBesondereOrte,
        cardhandel_redirect_to: '/besondere_orte/',
        cardbody: 'Ganz besondere Geheimtipps aus der Region- von Menschen für Menschen'
      },
      'cardid_12': {
        cardtitle: 'Familienfreundliche Orte',
        cardimg: imgCardHomeFfOrte,
        cardhandel_link_to: '/thema/familienfreundliche_orte',
        cardbody: 'Für dringende Bedürfnisse und für jede Altersgruppe: Stillpunkte, Wickelpunkte, Spielplätze und öffentliche Toiletten'
      }
    };

  /*
      * 'link_to': für die Route
      * @cardheadertitle: Headertitle
      * @cardheaderbody: Headertext
      * @cardheaderimg: headerImg
      * Title der Unterkachel
      * @cardimg: Bild der Unterkachel
      * @cardbody: Text der Unterkachel
      *
      * Optionen für Aktionen des Buttons 'mehr anzeigen' (darf ein von folgenden angewendet werden):
      * @cardhandel_searchcards: Unterthemen
      * @cardhandel_filters: einen bestimmten Filter anwenden
      * @message: eine Mitteilung ausgeben, z.B. falls die Unterseite in der Entwicklung sich befindet
      * @filter_selection: wenn ein DropDownBox mit einem Filter aus dem AppConntext geladen werden soll
      **/
  static CARDS_LEVEL_ONE = {
    'familienfreundliche_orte':{
      cardheadertitle: this.cardTitleFfo,//<span>Familien&shy;freundliche Orte</span>,
      cardheaderbody: 'Für dringende Bedürfnisse und für jede Altersgruppe: Stillpunkte, Wickelpunkte, Spielplätze und öffentliche Toiletten',
      cardheaderimg: this.ImgHomeFfo,
      gmapuseregionfilter: false, //true
      //message: "Die Seite mit den familienfreundlichen Orten befinden sich im Aufbau.",
      cardhandel_searchcards: {
            "1"//Config.FAMFREUNDORTS_OFFERTYPE_CONFIG.find(item => item.ConfCategoryKey === "KeySpielplatz").ConfOfferTypeCategory
            :{
                cardtitle: 'Spielplätze',
                cardimg: ConfigCards.ImgFfoSpielplatz,
                cardbody: 'Finden Sie Spielplätze in Ihrer Nähe',
                cardhandel_ffo: {
                  'ConfParentId':
                  Config.FAMFREUNDORTS_OFFERTYPE_CONFIG.find(item => item.ConfCategoryKey === "KeySpielplatz").ConfParentId
                }
            },
            "2"//Config.FAMFREUNDORTS_OFFERTYPE_CONFIG.find(item => item.ConfCategoryKey === "KeyWickelpunkt").ConfOfferTypeCategory
            :{
                cardtitle: 'Wickelpunkte',
                cardimg: ConfigCards.ImgFfoWickelpunkt,
                cardbody: 'Erhalten Sie eine Übersicht von Orten, Einrichtungen und Geschäften an denen Sie einen Wickelpunkt finden.',
                cardhandel_ffo:  {
                  'ConfParentId':
                  Config.FAMFREUNDORTS_OFFERTYPE_CONFIG.find(item => item.ConfCategoryKey === "KeyWickelpunkt").ConfParentId
                }
            },
            "3"//Config.FAMFREUNDORTS_OFFERTYPE_CONFIG.find(item => item.ConfCategoryKey === "KeyWickelpunkt").ConfOfferTypeCategory
            :{
                cardtitle: 'Stillpunkte',
                cardimg: ConfigCards.ImgFfoStillpunkt,
                cardbody: 'Erhalten Sie eine Übersicht von Orten, Einrichtungen und Geschäften an denen Sie einen Stillpunkt finden.',
                cardhandel_ffo:  {
                  'ConfParentId':
                  Config.FAMFREUNDORTS_OFFERTYPE_CONFIG.find(item => item.ConfCategoryKey === "KeyStillpunkt").ConfParentId
                }
            },
            "4"//Config.FAMFREUNDORTS_OFFERTYPE_CONFIG.find(item => item.ConfCategoryKey === "KeyToilette").ConfOfferTypeCategory
              :{
                  cardtitle: 'Öffentliche Toiletten',
                  cardimg: ConfigCards.ImgFfoToilette,
                  cardbody: 'Öffentlichen Toiletten (teilweise Barrierefrei) in Ihrer Stadt/Ihrem Landkreis.',
                  cardhandel_ffo:  {
                    'ConfParentId':
                     Config.FAMFREUNDORTS_OFFERTYPE_CONFIG.find(item => item.ConfCategoryKey === "KeyToilette").ConfParentId
                  }
              }
          }
    },

      'jugendarbeit':{
          cardheadertitle: 'Jugendarbeit & Ferienbörse',
          cardheaderbody: 'Weit hinten, hinter den Wortbergen, fern der Länder Vokalien und Konsonantien leben die Blindtexte. ',
          cardheaderimg: imgCardHomeJugendarbeit,
          cardhandel_searchcards: {
              "1":{
                  cardtitle: 'Samtgemeinde Brome',
                  cardimg: imgCardHomeJugendarbeit,
                  cardbody: 'Stöbern Sie in den Angeboten der Jugendarbeit der Samstgemeinde Brome.',
                  message: "Inhalte befinden sich im Aufbau."
              },
              "2":{
                  cardtitle: 'Kreisjugendförderung',
                  cardimg: imgCardHomeJugendarbeit,
                  cardbody: 'Schwerpunkte unserer Kinder- und Jugendarbeit im Landkreis Gifhorn.',
                  cardhandel_link_to: '/kreisjugendfoerderung/'
              },
              "3":{
                  cardtitle: 'Präventionsbündnis',
                  cardimg: imgCardHomeJugendarbeit,
                  cardbody: 'Nachrichten und Hinweise rund um das Thema Prävention.',
                  cardhandel_link_to_external: 'https://praeventionsbuendnis-gifhorn.jimdofree.com/'
              }
          }
      }


  };

  static SITE_BESONDERE_ORTE = {
    cardheadertitle: 'Besondere Orte',
    cardheaderbody: 'Ganz besondere Geheimtipps -<br>von Menschen -<br>für Menschen -<br>aus der Region',
    cardheaderimg: this.ImgHomeBesondereOrte,
    gmapuseregionfilter: false, //true
    gmapPinId: Config.GMAP_PIN_GOLD,
    cardhandel_filters: [
      {[Config.FILTER_OFFER_TYPES]: ['b1bfccb3-44b5-4c02-8512-4d4262384114']}
    ],
    //bis die Bildergallery implementiert wird, müssen die Bildverknüpfungen hier stattfinden
    //Achtung UpperCase !!! Hier eingegebene Reiehenfolge wird nicht in die Ansicht uebernommen
    carddatasetsdatas:{
      '9BECFBD2-1B31-48CE-8720-527A88FD7243': {
        //Millionen Glühwürmchen
        datasetImg: this.ImgCardsBesOrte_9becfbd2
      },
      'DC057E34-FF0A-496E-8213-5F422A4D1F09': {
        //Tankumsee
        datasetImg: this.ImgCardsBesOrte_dc057e34
      },
      '94C0A505-646D-49A5-B51C-8764FE5EC8A1': {
        datasetImg: this.ImgCardsBesOrte_94c0a505
      },
      'E56D0AE5-73DD-435F-B714-8CBD42E30502': {
        datasetImg: this.ImgCardsBesOrte_e56d0ae5
      },
      'DA79DBD0-9924-4586-886A-BB266761742F': {
        datasetImg: this.ImgCardsBesOrte_da79dbd0
      },
      'DE4E41F2-4B35-4DE8-A2FE-508B3C60C1D4': {
        //„Metropol“ Fallersleben
        datasetImg: this.ImgCardsBesOrte_de4e41f2
      },
        '78BA5014-663E-4DD9-BB42-52727BB31E16': {
          //Walderlebnispfad Zweidorfer Holz: Ganz besonders und mitten im Wald
          datasetImg: this.ImgCardsBesOrte_78ba5014
        }
    }
}

  static MENU_LINKS_ROUTE = [
        // { //Startseite ist immer dabei
        //   menu_title: 'Startseite',
        //   menu_callback: '?'
        // },
        {
          menu_title: 'Themenseiten',
          menu_untermenus: [
            {
              menu_title: this.CARDS_HOME['cardid_1'].cardtitle,
              menuhandel_filters: this.CARDS_HOME['cardid_1'].cardhandel_filters
            },
            {
              menu_title: this.CARDS_HOME['cardid_2'].cardtitle,
              menuhandel_filters: this.CARDS_HOME['cardid_2'].cardhandel_filters
            },
            {
              menu_title: this.CARDS_HOME['cardid_3'].cardtitle,
              menuhandel_filters: this.CARDS_HOME['cardid_3'].cardhandel_filters
            },
            {
              menu_title: this.CARDS_HOME['cardid_4'].cardtitle,
              menuhandel_filters: this.CARDS_HOME['cardid_4'].cardhandel_filters
            },
            {
              menu_title: this.CARDS_HOME['cardid_5'].cardtitle,
              menuhandel_filters: this.CARDS_HOME['cardid_5'].cardhandel_filters
            },
            {
              menu_title: this.CARDS_HOME['cardid_6'].cardtitle,
              menuhandel_filters: this.CARDS_HOME['cardid_6'].cardhandel_filters
            },
            {
              menu_title: this.CARDS_HOME['cardid_7'].cardtitle,
              menuhandel_filters: this.CARDS_HOME['cardid_7'].cardhandel_filters
            },
            {
              menu_title: this.CARDS_HOME['cardid_8'].cardtitle,
              menuhandel_filters: this.CARDS_HOME['cardid_8'].cardhandel_filters
            },
            {
              menu_title: this.CARDS_HOME['cardid_9'].cardtitle,
              menuhandel_filters: this.CARDS_HOME['cardid_9'].cardhandel_filters
            },
            {
              menu_title: this.CARDS_HOME['cardid_10'].cardtitle,
              menuhandel_filters: this.CARDS_HOME['cardid_10'].cardhandel_filters
            }
          ]
        },
        {
          menu_title: this.CARDS_HOME['cardid_11'].cardtitle, // Besondere Orte
          menu_link_to: this.CARDS_HOME['cardid_11'].cardhandel_redirect_to
        },
        {
          menu_title: this.CARDS_HOME['cardid_12'].cardtitle, // FFO
          menu_link_to: this.CARDS_HOME['cardid_12'].cardhandel_link_to
        },
        {
          menu_title: 'Kontakt',
          menu_link_to: '/kontakt'
        },
        {
          menu_title: 'Über Uns',
          menu_link_to: '/ueber_uns'
        }
      ];

}
