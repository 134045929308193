import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import AddWatermarkSharePartial from '../../common/components/AddWatermarkSharePartial';


/**
 * This is the main template component and renders the Header + Footer and the @children in between.
 *
 * @class      TemplateMain (name)
 * @prop 		{object} heroData - {img, title, body}
 */
class TemplateMain extends Component {

  render() {
    let addWatermark = '';
    if(this.props.p_addwatermark){
      addWatermark = (<AddWatermarkSharePartial />);
    }

    return(
    	<div id="wrapper" className="TemplateMain">
        {addWatermark}
  			<Header showHero={this.props.showHero}
                heroData={this.props.heroData}
                searchNavTop={this.props.searchNavTop}
                p_showSearchFormEnhanced={this.props.p_showSearchFormEnhanced}
        />
  			{this.props.children}
  			<Footer></Footer>
    	</div>
	);
  }
}

export default TemplateMain;
