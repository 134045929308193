import React, { Component } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';

import MessageSharePartial from '../../common/components/MessageSharePartial';
import ResultSet from './ResultSet';
import ResultSetFactory from './ResultSetFactory';
import SearchResultsWithMap from './SearchResultsWithMap';
import Select from 'react-select';

import {AppContext} from './AppContext';
import Config from '../../Config';

import { withRouter } from "react-router";



/**
 * @class      SearchResults (name)
 * @prop 		{json} results - json search results from API
 * @prop 		{function} filterCallBack -
 */

class CreateOffersFromProvider extends Component {

	constructor(props){
		super(props);

		this.state = {
			s_renderOffers: ''
		}
	}

	componentDidMount() {
		this.setOffersForRender();
	}
	componentDidUpdate(prevProps){
		if(prevProps._offersJsonData != this.props._offersJsonData)
		{
			this.setOffersForRender();
		}
	}
	setOffersForRender(){
		let lExtractOffers = [];
    //extract offers
    if(Array.isArray(this.props._offersJsonData))
    {
      lExtractOffers = ResultSetFactory.extractOffers(this.props._offersJsonData);
    }

		let renderResult = this.createRenderResults(this.props._offersJsonData, lExtractOffers);

		this.setState({
			s_renderOffers: renderResult
		});
	}

	/**
	 * Renders the json results through the ResultSetFactory into ResultSets.
	 *
	 * @param      {<type>}  json_data  The json data
	 * @return     {Array}   All ResultSets
	 */
	createRenderResults(json_data, pOffers){
		let results = null;
		let ndate = null;

		//check for error
		if(json_data.ID)
		{
			//show API Error
			results = (
				<div className="alert alert-danger mt-5" role="alert">
					  {json_data.ID}: {json_data.Description}
				</div>
				);
		}
		else if(pOffers && pOffers.length)
		{
			results = [];
			//create result sets
			results = ResultSetFactory.buildResultSets(pOffers, 0, pOffers.length, this.context.searchDetail, null);
		}
		else{
			results = (
					<MessageSharePartial>
						Diese Organisation hat zur Zeit keine öffentliche Angebote <br/>
					</MessageSharePartial>
				);
		}

		return results;
	}

	render() {
		//hole fertige Angebotskacheln für die Anzeige, siehe 'results' aus createRenderResults()
		let search_results = this.state.s_renderOffers;

		//add prop _cFixHight für className cFixHight in ResultSet, damit die Cards nach eine feste Höhe ausgerichtet werden
		let search_results_fixHight = [];
		if(search_results.length > 0 && search_results[0].type === ResultSet){
			search_results && search_results.map(item => (
				search_results_fixHight.push(React.cloneElement(
								      item,
								      {_cFixHight: 'cFixHight'}
								    ))
			));
		}else{
			search_results_fixHight = (<MessageSharePartial>Keine Angebote gefunden</MessageSharePartial>);
		}

		let active_filters = this.context.getActiveFiltersAsJSX();
		let active_filters_content;
		if(active_filters.length)
		{
			active_filters_content = <p className="active_filters"><strong>Filter:</strong>{active_filters}</p>
		}
		return(

			<div className="CreateResults">
        <ScrollableAnchor id='top'>
          <header className="createresults-header">
            <h2 className="title">Angebote dieses Anbieters</h2>
          </header>
        </ScrollableAnchor>
        <section className="result-sets-row">
          { search_results_fixHight }
				</section>
			</div>
		);
	}
}
CreateOffersFromProvider.contextType = AppContext;

export default withRouter(CreateOffersFromProvider);
