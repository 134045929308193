import React, { Component } from 'react';

//wird von GmapFfoPartial aufgerufen, falls andere styles gewünschr sind, bitte die klassen-ersetzung mit props implmentieren
export default class GmapWindowDetailPartial extends Component{

  constructor(props) {
      super(props);
      this.state = {
        s_children: '',
        s_styleGmapWindowDetailPartial: '',
        s_visible: "none"
      };
    }

    //https://github.com/kriasoft/react-starter-kit/issues/909#issuecomment-252969542
    // ?? componentDidMount() { this.props.onRef(this) }
    // ?? componentWillUnmount() { this.props.onRef(undefined) }

    //aktualisiere die Componente von ParentComponente
    updateChildrenComponentsFromParent(pNewChildrenComponents, pStyleGmapWindowDetailPartial) {
      this.setState({
        s_children: pNewChildrenComponents,
        s_styleGmapWindowDetailPartial: pStyleGmapWindowDetailPartial,
        s_visible: "block"
      });
    }
    setHide() {
      this.setState({s_visible: "none"});
    }

    render(){
      return(
        <div  className="cGmapWindowDetailPartialContainer"
              style={{display:this.state.s_visible, ...this.state.s_styleGmapWindowDetailPartial}}>
          <div className="cGampWindowDetailPartial">
            <span className="gmap_window_detail_close" title="schließen" onClick={() => this.setHide()}>&#10006;</span>
          	{this.state.s_children}
          </div>
        </div>
      );
    }

}
