import React, { Component } from 'react';
import { Fragment } from 'react'; // react version > 16.0
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

import ApiConnector from '../model/ApiConnector';
import Config from '../../Config';

const validEmailRegex =
RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

export default class KontaktFormSharePartial extends Component{
  constructor (props) {
    super(props);
    this.kommunenApi = '';
    this.state = {
      loaded: false,
      kommune: '',
      kommunenList: '',
      anrede: '',
      email: '',
      vorname: '',
      nachname: '',
      organisation: '',
      telefon: '',
      betreff: '',
      meinung: '',
      recaptchatoken: null,
      formErrors: {email: '', vorname: '', nachname: '', meinung: '', kommune: ''},
      emailValid: false,
      vornameValid: false,
      nachnameValid: false,
      meinungValid: false,
      kommuneValid: false,
      formValid:false
    }
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResetData = this.handleResetData.bind(this);
  }

  componentWillMount(){
    //get items for "Kommune"
    ApiConnector.getListItemsFromTable(Config.TABLE_KOMMUNEN, (api_data) => {
      this.kommunenApi = api_data;
      this.initState(this.kommunenApi);
    });
  }
  initState(pApiData){
    let lKommune = this.setKommune(pApiData);
    let lKommuneValid = lKommune !== '';

    this.setState({
      kommunenList: this.convertApiItemsToSearchOptions(pApiData)
      ,loaded: true
      ,kommune: lKommune
      ,kommuneValid: lKommuneValid
    });
  }
  convertApiItemsToSearchOptions(pApiData){
      let options = [];
      options.push(<option value='' key={-1}>Bitte wählen ...</option>);
      for(const [index, item] of pApiData.entries())
      {
          options.push(<option value={item.Token} key={index}>{item.Designation}</option>);
      }
      return options;
  }
  setKommune(pApiData){
    if(pApiData.length > 1){
      //es wird ein DropDownBox zum Auswahl eingeblendet
    }else
    if(pApiData.length == 1 ){
      //setze direkt den Wert auf den einzigen Token
      return pApiData[0].Token;
    }else{
      //TODO FEHLER/WARNUNG MELDEN !!
    }
    return '';
  }

  handleUserInput(e){
    const name = e.target.name;
    const value = e.target.value;

    this.setState({[name]: value},
                  () => { this.validateField(name, value) });
  }

  handleSubmit(event) {
      event.preventDefault();

      let mail_data = {
        Kommune: this.state.kommune,
        Anrede: this.state.anrede,
        Vorname: this.state.vorname,
        Nachname: this.state.nachname,
        Organisation: this.state.organisation,
        Emailadresse: this.state.email,
        Telefon: this.state.telefon,
        Betreff: this.state.betreff,
        Meinung: this.state.meinung,
        Recaptchatoken: this.state.recaptchatoken
      }

      ApiConnector.sendMail(mail_data, (result) => {
          if(result)
            window.alert('Ihre Nachricht wurde erfolgreich verschickt. Vielen Dank!');
          else
            window.alert('Leider gab es ein technisches Problem beim Versenden Ihrer Nachricht. Es tut uns leid, bitte versuchen Sie es später erneut.');
      });
    }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let kommuneValid = this.state.kommuneValid;
    let meinungValid = this.state.meinungValid;
    let emailValid = this.state.emailValid;
    let vornameValid = this.state.vornameValid;
    let nachnameValid = this.state.nachnameValid;

    switch(fieldName) {
      case 'email':
        emailValid = value.match(validEmailRegex);
        fieldValidationErrors.email = emailValid ? '' : ' ungültig';
        break;
      case 'vorname':
      vornameValid = value.length >= 2;
        fieldValidationErrors.vorname = vornameValid ? '': ' v ungültig';
        break;
      case 'nachname':
      nachnameValid = value.length >= 2;
        fieldValidationErrors.nachname = nachnameValid ? '': ' n ungültig';
        break;
      case 'meinung':
        meinungValid = value.length >= 10;
        fieldValidationErrors.meinung = meinungValid ? '': ' m ungültig';
        break;
      case 'kommune':
        // console.log("kommuneValue = " + value);
        kommuneValid = value.length > 0;
        fieldValidationErrors.kommune = kommuneValid ? '': ' k ungültig';
        break;
      default:
        break;
    }
    this.setState({ formErrors: fieldValidationErrors,
                    kommuneValid: kommuneValid,
                    emailValid: emailValid,
                    vornameValid: vornameValid ,
                    nachnameValid: nachnameValid,
                    meinungValid: meinungValid
                  }, () => {this.validateForm()});
  }

  validateForm() {

    this.setState({formValid: this.state.emailValid && this.state.vornameValid && this.state.nachnameValid && this.state.meinungValid && this.state.kommuneValid});

  }
  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }

  handleResetData(){
    this.setState({
      kommune: '',
      anrede: '',
      email: '',
      vorname: '',
      nachname: '',
      organisation: '',
      telefon: '',
      betreff: '',
      meinung: '',
      recaptchatoken: null,
      formErrors: {email: '', vorname: '', nachname: '', meinung: '', kommune: ''},
      emailValid: false,
      vornameValid: false,
      nachnameValid: false,
      meinungValid: false,
      kommuneValid: false,
      formValid:false
    });

    this.initState(this.kommunenApi);
  }
  render(){

    if(!this.state.loaded){
      return (
        <div> Das Kontaktformular wird geladen . . .  </div>
      );
    }

    let vKommunen = "";
    if(this.state.kommunenList && this.state.kommunenList.length !== 2){
      //1.Bitte wählen; 2.einzige auswahlsoption
      vKommunen =  (<Fragment><label>Kommune*: </label>
      <select className="custom-select" placeholder="Kommune" type="text" id="kommune" name="kommune" value={this.state.kommune} onChange={this.handleUserInput}
          required className={`custom-select ${this.errorClass(this.state.formErrors.kommune)}`}>
          {this.state.kommunenList}
      </select></Fragment>);
    }

    let vBtnReset = '';
    if(this.props.p_btnReset){
      vBtnReset = (<input id="submit"
                          name="submit"
                          type="button"
                          value="Auswahl zurücksetzen"
                          onClick={() => this.handleResetData()}/>
                  );
    }

    let vRecaptcha = (
                  <GoogleReCaptchaProvider reCaptchaKey={Config.CAPTCHA_KEY} language="de">
                    <GoogleReCaptcha onVerify={rectoken => {
                              this.setState({ recaptchatoken: rectoken });
                            }} />
                  </GoogleReCaptchaProvider>
                );
    return(
      <form onSubmit={this.handleSubmit}>

            {vKommunen}

            <label>Anrede: </label>
            <select className="custom-select" placeholder="Anrede" type="text" id="anrede" name="anrede" value={this.state.anrede} onChange={this.handleUserInput}>
            <option value="keine">-</option>
            <option value="Frau">Frau</option>
            <option value="Herr">Herr</option>
            </select>

            <label htmlFor="vorname">Vorname*</label>
                <input type="text"
                       required className={`vorname ${this.errorClass(this.state.formErrors.vorname)}`}
                       name="vorname"
                       placeholder="Vorname"
                       value={this.state.vorname}
                       onChange={this.handleUserInput}  />

            <label htmlFor="nachname">Nachname*</label>
                <input type="text"
                      required className={`nachname ${this.errorClass(this.state.formErrors.nachname)}`}
                      name="nachname"
                      placeholder="Nachname"
                      value={this.state.nachname}
                      onChange={this.handleUserInput}  />

            <label htmlFor="organisation">Organisation</label>
                <input type="text" className="organisation" name="organisation"
                      placeholder="Organisation"
                      value={this.state.organisation}
                      onChange={this.handleUserInput}  />


            <label htmlFor="email">Emailadresse*</label>
                <input type="email"
                      required className={`email ${this.errorClass(this.state.formErrors.email)}`}
                      name="email"
                      placeholder="Emailadresse"
                      value={this.state.email}
                      onChange={this.handleUserInput}  />

              <label htmlFor="telefon">Telefon</label>
                 <input type="tel" className="telefon" name="telefon"
                      placeholder="Telefon"
                      value={this.state.telefon}
                      onChange={this.handleUserInput}  />

              <label htmlFor="betreff">Betreff</label>
                  <input type="text"  className="betreff" name="betreff"
                      placeholder="Betreff"
                      value={this.state.betreff}
                      onChange={this.handleUserInput}  />

              <div className="w-100"></div>

              <label>Meinung*: </label><textarea
                    placeholder="Meinung"
                    type="text"
                    id="meinung"
                    name="meinung"
                    required className={`meinung ${this.errorClass(this.state.formErrors.meinung)}`}
                    value={this.state.meinung} onChange={this.handleUserInput} />

              {vRecaptcha}
              <div className="w-100"></div>

              {vBtnReset}
              <input id="submit" name="submit" type="submit" value="absenden" disabled={!this.state.formValid}
              className={`send-${this.state.formValid}`} />


      </form>
    );
  }
}
