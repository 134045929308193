import React, { Component } from 'react';
import { scroller } from 'react-scroll';
import { AppContext } from './AppContext';
import Config from '../../Config';
import ConfigCards from '../../ConfigCards';

import CreateSearchCard from './CreateSearchCard';
import CreateSearchCardsWrapper from './CreateSearchCardsWrapper';
import GmapDatasetsPartial from '../../common/components/GmapDatasetsPartial';
import GmapMarkerInfoModel from '../../common/model/GmapMarkerInfoModel';
import ResultHelper from '../../common/results/ResultHelper';
import Helper from '../../common/tools/Helper';

 export default class CreateResultCardsGmap extends Component {
  constructor(props){
    super(props);

    this.m_config = Config;
    this.m_static_ResultHelper = ResultHelper;

    this.m_configCardsBeondereOrte = this.props.p_pagedata;
    this.m_configCardsDatasetsDatas = this.m_configCardsBeondereOrte.carddatasetsdatas;
    this.m_config_resultIds = this.m_config.RESULT_IDS;

    // event-function für die Filterung aus den Kacheln
    this.handlerCardsUpdateFilterDatasetId = this.handlerCardsUpdateFilterDatasetId.bind(this);

    //0. State definieren
    this.state ={
      //'Filter'
      s_markersselected: null

      ,handlerCardsUpdateFilterDatasetId: this.handlerCardsUpdateFilterDatasetId
    }

    //1. CreateKacheln
    this.m_cardsffo = this.initCardsBesOrte();

    //2. InitMarkers
    this.m_markers = this.convertDatasetsToGmapMarkers(this.props.p_serachresults);
    //3. CreateGmap mit m_markers
    this.m_gmap = this.loadGmapOffers();

  }

  componentDidMount(prevProps, prevState) {
    scroller.scrollTo('scroll_start', {
      duration: 1000,
      smooth: true
    });
  }

  initCardsBesOrte(){
    let datasets = this.props.p_serachresults;

    //convert to cards_Struktur (siehe ConfigCards.js)
    // {   cardtitle: 'Spielplätze',
    //     cardimg: ConfigCards.ImgFfoSpielplatz,
    //     cardbody: 'Finden Sie Spielplätze in Ihrer Nähe',
    //     cardhandel_ffo: {
    //       'ConfParentId':
    //       Config.FAMFREUNDORTS_OFFERTYPE_CONFIG.find(item => item.ConfCategoryKey === "KeySpielplatz").ConfParentId
    //     }   }
    let search_cards = [];
    if(datasets && datasets.length > 0 ){
      let tmpCardsStruct = null;
      let kurzBeschreibungGuid = Config.RESULT_IDS.short_description;
      for(let i = 0; i < datasets.length; i++){
        let dataset = datasets[i];
        let datasetConfigCardsValue = this.m_configCardsDatasetsDatas[dataset.DatasetId.toUpperCase()];
        if(datasetConfigCardsValue){//Kurzbeschreibung findet sich immer in der erste Gruppe
          let shortDescriptionMerkmal = ResultHelper.searchMerkmalId(dataset.Groups[0], kurzBeschreibungGuid, true);
          let shortDescriptionValue = "";
          if(shortDescriptionMerkmal !== null && shortDescriptionMerkmal !== window.undefined){
            shortDescriptionValue = ResultHelper.getFirstValue(shortDescriptionMerkmal);
          }
          shortDescriptionValue = shortDescriptionValue ? shortDescriptionValue : "";
          tmpCardsStruct =
            {
              cardtitle: dataset.LongName,
              cardimg: datasetConfigCardsValue.datasetImg,
              cardbody: shortDescriptionValue
            };

          search_cards.push(<CreateSearchCard key={i+1}
                                        p_configCard={tmpCardsStruct}
                                        p_classextension={Config.KACHEL_COLUMNUMBERS_BESORTE}//"column-4"
                                        p_cardlinktext=""//hier gibt es keinen Link => reine InfoKachel
                                        p_callback={this.handlerCardsUpdateFilterDatasetId}
                                        p_callbackparam={dataset.DatasetId}
                            />);

        }
      }
    }
    return <CreateSearchCardsWrapper>{search_cards}</CreateSearchCardsWrapper>;;
  }
  loadGmapOffers(pMarkersSelected){
    console.log("loadGmapOffers", pMarkersSelected);
    if(this.props.p_serachresults){

    return (<GmapDatasetsPartial
                p_markers = {this.m_markers}
                p_markerSelected = {pMarkersSelected}
                options={{
                  mapTypeControl: false,
                  fullscreenControl: false,
                  streetViewControl: false
                }}
                mapContainerStyle={{
                  //width: 600, //ohne gesetzte width wird diese 100% gesetzt
                  height: 450		//ToDo StyleVariable für Mandanten ?
                }}
      />);
    }

    //return (<p>Google Map wird geladen</p>);
  }
  /* bereitet einmalig alle MarkersInfos als GmapMarkerInfoModel für die GmapFfoPartial vor **/
  convertDatasetsToGmapMarkers(pDatasets){
    if(pDatasets && pDatasets.length > 0 && this.props.p_pagedata){
      const constPinImg = this.m_configCardsBeondereOrte.gmapPinId[0].gmPinUrl;
      const constPinColor = this.m_configCardsBeondereOrte.gmapPinId[0].gmMenuFontColor;

      //create markers
      let markers = [];
      for(let i=0;i < pDatasets.length;i++){
        let dataset = pDatasets[i];
        let datasetConfigCardsValue = this.m_configCardsDatasetsDatas[dataset.DatasetId.toUpperCase()];

        if(datasetConfigCardsValue){
          let showAdress = true;
          let dsLat = 0, dsLong = 0;
          //Koordinate vom MerkmalType Lat auslesen
          dsLat = this.m_static_ResultHelper.searchMerkmalId(dataset, this.m_config.FAMFREUNDORTS_MERKMALID_LAT);
          //koordinate von der Adresse FlagIsLocation auslesen
          dsLat = this.m_static_ResultHelper.getFirstValue(dsLat);
          if(dsLat && dsLat !== "0"){
          //zuerst die Koordinaten aus den Merkmalen auslesen, falls diese gibt
             dsLong = this.m_static_ResultHelper.searchMerkmalId(dataset, this.m_config.FAMFREUNDORTS_MERKMALID_LONG);
             dsLong = this.m_static_ResultHelper.getFirstValue(dsLong);
             showAdress = false;
          }else{
            //sonst aus dem Header
            dsLat = dataset.Latitude;
            dsLong = dataset.Longitude;
          }

          let adressHtmlInfoDetails = '';
          let adressHtmlInfo = '';
          if(showAdress){
            let merkmalAdresse = this.m_static_ResultHelper.searchMerkmalId(dataset, this.m_config_resultIds['address']);
            let dsAdresse = this.m_static_ResultHelper.getAddress(merkmalAdresse);
            adressHtmlInfoDetails = (<div><strong>Adresse:</strong><p>{dsAdresse.street}<br/>{dsAdresse.city}</p></div>);
            adressHtmlInfo = (<p>{dsAdresse.street}<br/>{dsAdresse.city}</p>);
          }

          let merkmalRegion = this.m_static_ResultHelper.searchMerkmalId(dataset, this.m_config_resultIds['region']);
          let dsARegionValue = this.m_static_ResultHelper.getFirstValue(merkmalRegion);
          let dsARegionToken = this.m_static_ResultHelper.getFirstFieldData(merkmalRegion);

          // let merkmalOpeningHours = this.m_static_ResultHelper.searchMerkmalId(dataset, this.m_config_resultIds['offer_opening_hours']);
          // let dsOpeningHours = this.m_static_ResultHelper.getFirstValue(merkmalOpeningHours);
          // <p>Öffnungszeiten:</p>
          // <p>{dsOpeningHours}</p>
          // let merkmalphone = this.m_static_ResultHelper.searchMerkmalId(dataset, this.m_config_resultIds['offer_phone']);
          // let dsPhone = this.m_static_ResultHelper.getFirstValue(merkmalphone);
          // <p>Telefon:</p>
          // <p>{dsPhone}</p>
          let dsMarkerInfoDetails = <div className="cGmapMarkerInfoWindowDetails" p_stylegmapwindowdetailpartial={{borderTop: `10px solid ${constPinColor}`}}>
                                      <h3>{dataset.ShortName}</h3>
                                      {adressHtmlInfoDetails}
                                      <strong>Region</strong>
                                      <p>{dsARegionValue}</p>
                                    </div>;

          let imgForMarkerInfo = <img src={datasetConfigCardsValue.datasetImg} style={{height:'120px'}}/>;
          // style={/*{background: `white`,padding: '10px', }*/}
          let dsMarkerInfoHtml = <div className="cGmapMarkerInfoWindow"
                                            title="Navigation starten" className="link"
                                            style={{cursor:'pointer'}} onClick={(e) => Helper.openGmapNavigate(dsLat, dsLong)}>
                                    <h3>{dataset.ShortName}</h3>
                                    {imgForMarkerInfo}
                                    {adressHtmlInfo}
                                    {/*<a className="link" onClick={(e) => this.handlerChildOnClickShowWindowDetail(dsMarkerInfoDetails)} >
                                    mehr Informationen</a>*/}
                              </div>;


          let marker = new GmapMarkerInfoModel(
                          dataset.DatasetId,
                          dataset.ParentId,
                          dsLat,
                          dsLong,
                          dataset.ShortName,
                          constPinImg,
                          constPinColor,
                          dsMarkerInfoHtml,
                          dsMarkerInfoDetails,
                          dsARegionToken);
          markers.push(marker);
        }
      }
      return markers;
    }
    return null;
  }

  handlerCardsUpdateFilterDatasetId(pDatasetId){
    this.m_gmap = this.loadGmapOffers(pDatasetId);

    scroller.scrollTo('scroll_gmap', {
      duration: 1000,
      smooth: true
    });

    //call render()
    this.setState(
      {s_markersselected: pDatasetId}
		);
  }

  render() {
    console.log("render");

    return (
      <div className="body-stage-row">
        {this.m_cardsffo}
        <div name="scroll_gmap"/>
        {this.m_gmap}
      </div>);
   }
 }
 CreateResultCardsGmap.contextType = AppContext;
