import axios from 'axios';
import Helper from '../tools/Helper';
import Config from '../../Config';
import ConfigCards from '../../ConfigCards';

/**
 * This class makes all the API calls and returns the results, when ready.
 * The API url is read from the Config.js
 *
 * @class      ApiConnector (name)
 */
class ApiConnector{

	static getFamFreundOrteOffers(callback_result, callback_error)
	{
	    let jsonFamFreundOrteConfig = Config.FAMFREUNDORTS_OFFERTYPE_CONFIG;
	    if(!jsonFamFreundOrteConfig || jsonFamFreundOrteConfig.length === 0){
	      // handle error
	      console.log("BUG: API getFamFreundOrteOffers FAMFREUNDORTS_OFFERTYPE_CONFIG ist leer");
	      if(callback_error)
	        callback_error("Konfiguration fehlt");
	      else
	        ApiConnector.showError("Konfiguration fehlt");
	      return;
	    }

	    let offerTypeIds = [];
	    Helper.getValuesFromKeyJsonRecursiv(jsonFamFreundOrteConfig, "ConfOfferTypeId", offerTypeIds);
	    let filterOfferTypes = this.buildOfferTypesFilter(offerTypeIds);
	    let search_options = {
	            method: 'post',
	            url: Config.API_URL,
	            data: [filterOfferTypes],
	            headers: {
	            Authorization: 'Bearer ' + Config.BEARER_TOKEN
	            }
	        };

	    //make api call
	      axios(search_options)
	      .then(function (response) {
	          // handle success
	          callback_result(response.data.Body);
	      })
	      .catch(function (error) {
	          // handle error
	          console.error("API getFamFreundOrteOffers error");
	          if(callback_error)
	            callback_error(error);
	          else
	            ApiConnector.showError(error);

	      })
	  }

	// *******************************************************************
	static getBesondereOrte(callback_result, callback_error)
	{
			let configBesOrte = ConfigCards.SITE_BESONDERE_ORTE;
			if(!configBesOrte || configBesOrte.length === 0){
				// handle error
		    console.error("BUG: API getBesondereOrte SITE_BESONDERE_ORTE ist leer");
				if(callback_error)
					callback_error("Konfiguration fehlt");
				else
					ApiConnector.showError("Konfiguration fehlt");
				return;
			}
	    let filterOption = configBesOrte.cardhandel_filters;
	    if(!filterOption || filterOption.length === 0){
	      // handle error
	      console.error("BUG: API getFamFreundOrteOffers FAMFREUNDORTS_OFFERTYPE_CONFIG.cardhandel_filters ist leer");
	      if(callback_error)
	        callback_error("Konfiguration fehlt");
	      else
	        ApiConnector.showError("Konfiguration fehlt");
	      return;
	    }

			let valueOfferType = filterOption[0].offer_types;
	    let filterOfferTypes = this.buildOfferTypesFilter(valueOfferType);
	    let search_options = {
	            method: 'post',
	            url: Config.API_URL,
	            data: [filterOfferTypes],
	            headers: {
	            Authorization: 'Bearer ' + Config.BEARER_TOKEN
	            }
	        };

	    //make api call
	      axios(search_options)
	      .then(function (response) {
	          // handle success
	          callback_result(response.data.Body);
	      })
	      .catch(function (error) {
	          // handle error
	          console.log("API getBesondereOrte error");
	          if(callback_error)
	            callback_error(error);
	          else
	            ApiConnector.showError(error);

	      })
	  }



	/**
	 * Makes a search call to the api for a DatasetId
	 *
	 * @param      {string}    dataset_id       - The dataset identifier
	 * @param      {Function}  callback_result  - function is called when result arrives with result json as parameter
	 * @param      {Function}  callback_error   - error function
	 */
	static searchDatasetID(dataset_id, callback_result, callback_error){

		console.log('searchDatasetID: ' + dataset_id);

		let search_options = {
		        method: 'post',
		        url: Config.API_URL,
		        data: [
		            {
		              "Type": "DatasetId",
		              "MerkmalTypeIDs": [],
		              "Fields": [],
		              "Values": [ dataset_id ],
		              "OperatorType": ""
		            }
		            ],
		        headers: {
		        Authorization: 'Bearer ' + Config.BEARER_TOKEN
		        }
		    };

		//make api call
	    axios(search_options)
	    .then(function (response) {
	        // handle success
	        console.log("API searchDatasetID success");
	        callback_result(response.data.Body);
	    })
	    .catch(function (error) {
	        // handle error
	        console.log("API searchDatasetID error");
	        if(callback_error)
	        	callback_error(error);
	        else
	        	ApiConnector.showError(error);
	    })
	}

	/**
	 * Makes a search call to the api for a hashcode for the backend preview
	 *
	 * @param      {string}    dataset_id       - The dataset identifier
	 * @param      {Function}  callback_result  - function is called when result arrives with result json as parameter
	 * @param      {Function}  callback_error   - error function
	 */
	static searchHashcode(hashcode, callback_result, callback_error){

		console.log('searchHashcode: ' + hashcode);

		let search_options = {
		        method: 'post',
		        url: Config.API_URL,
		        data: [
					{
						"Type": "hashcode",
						"MerkmalTypeIDs": [],
						"Fields": [],
						"Values": [ hashcode ],
						"OperatorType": ""
					}
		            ],
		        headers: {
		        Authorization: 'Bearer ' + Config.BEARER_TOKEN
		        }
		    };

		//make api call
	    axios(search_options)
	    .then(function (response) {
	        // handle success
	        console.log("API searchHashcode success", response.data.Body);
	        callback_result(response.data.Body);
	    })
	    .catch(function (error) {
	        // handle error
	        console.log("API searchHashcode error",error);
	        if(callback_error)
	        	callback_error(error);
	        else
	        	ApiConnector.showError(error);

	    })
	}

	/**
	 * Makes a search call with a search string to the API
	 *
	 * @param      {string}    search_str                     The search string
	 * @param      {Function}  callback_result  			- function is called when result arrives with result json as parameter
	 * @param      {Function}  callback_error   			- error function
	 * @param      {Array || Object}    filter               - The search filters > these need to be made "API ready" with one of the ApiConnector.build... functions
	 * @param      {string}    [alternate_bearer_token=null]  - The funcion accepts an alternate_bearer_token for the quicksearch
	 */
	static searchText(search_str, callback_result, callback_error, filter, alternate_bearer_token = null){

		console.log('searchText: ' + search_str);

		let bearer_token = Config.BEARER_TOKEN;
		//check for alternate_bearer_token
		if(alternate_bearer_token)
			bearer_token = alternate_bearer_token;

		let search_options = {
		        method: 'post',
		        url: Config.API_URL,
		        data: [],
		        headers: {
		        	Authorization: 'Bearer ' + bearer_token
		        }
		    };

		//create textfilter
		if(search_str)
		{
			let textfilter = {
			              "Type": 'Text',
			              "MerkmalTypeIDs": [],
			              "Fields": [],
			              "Values": [ search_str ],
			              "OperatorType": ""
			            };

			search_options.data.push(textfilter);
		}

		//add filter
		if(filter)
		{
			console.log('search filter:',filter);

			if(Array.isArray(filter))
			{
				//merge arrays
				search_options.data = [...search_options.data, ...filter];
			}
			else{
				//we asume filter is a single object, that we can push into the array
				search_options.data.push(filter);
			}
		}

	    axios(search_options)
	    .then(function (response) {
	        // handle success
	        console.log("API searchText success",response.data);
	        callback_result(response.data.Body);
	    })
	    .catch(function (error) {
	        // handle error
	        console.log("API searchText error",error);
	        if(callback_error)
	        	callback_error(error);
	        else
	        	ApiConnector.showError(error);

	    })
	}

  static searchResultsWithOrFilter(pFiltersArray, callback_result, callback_error, alternate_bearer_token = null){
    console.log("searchResultsWithOrFilter pFiltersArray:", pFiltersArray);
		let bearer_token = Config.BEARER_TOKEN;
		//check for alternate_bearer_token
		if(alternate_bearer_token)
			bearer_token = alternate_bearer_token;
		let callAxiosOptions = {
		        method: 'post',
		        url: Config.API_URL,
		        data: [],
		        headers: {
		        	Authorization: 'Bearer ' + bearer_token
		        }
		};
    if(pFiltersArray){
      this.prCallApiSearch(callAxiosOptions, pFiltersArray, callback_result, callback_error);
    }

  }
  static prCallApiSearch(pCallAxiosOptions, pApiFilters, callback_result, callback_error){
    if(Array.isArray(pApiFilters))
    {
      //merge arrays
      pCallAxiosOptions.data = [...pCallAxiosOptions.data, ...pApiFilters];
    }
    else{
      //we asume pFilters is a single object, that we can push into the array
      pCallAxiosOptions.data.push(pApiFilters);
    }
    axios(pCallAxiosOptions)
    .then(function (response) {
        // handle success
        console.log("prCallApiSearch success", response.data);
        callback_result(response.data.Body);
    })
    .catch(function (error) {
        // handle error
        console.log("API searchText error", error);
        if(callback_error)
          callback_error(error);
        else
          ApiConnector.showError(error);

    })
  }



	static getListItems(merkmal_type_id, callback_result, callback_error)
	{
		console.log('getListItems:', merkmal_type_id);

		let search_options = {
		        method: 'post',
		        url: Config.API_URL,
		        data: [
						 {
						  "Type": "ListItems",
						  "MerkmalTypeIDs": [merkmal_type_id],
						  "Fields": "",
						  "Values": "",
						  "OperatorType": ""
						 }
		            ],
		        headers: {
		        Authorization: 'Bearer ' + Config.BEARER_TOKEN
		        }
		    };

		//make api call
	    axios(search_options)
	    .then(function (response) {
	        // handle success
	        console.log("getListItems success: ", response.data);
	        callback_result(response.data.Body);
	    })
	    .catch(function (error) {
	        // handle error
	        console.log("getListItems error: ", error);
	        if(callback_error)
	        	callback_error(error);
	        else
	        	ApiConnector.showError(error);
	    })
	}

  /**
   * Gets the list items from TableName, for Example KontaktFormular
   *
   * @param      {string}    tablename  - Tablename with datas
   * @param      {Function}  callback_result  - function is called when result arrives with result json as parameter
   * @param      {Function}  callback_error   - error function
  */
  static getListItemsFromTable(tablename, callback_result, callback_error)  {
  	console.log('getListItemsFromTable: ' + tablename);
  		let search_options = {
		        method: 'post',
		        url: Config.API_URL,
		        data: [
						 {
						  "Type": "TableData",
						  "MerkmalTypeIDs": "",
						  "Fields": "",
						  "Values": [tablename],
						  "OperatorType": "ListItems"
						 }
		            ],
		        headers: {
		        Authorization: 'Bearer ' + Config.BEARER_TOKEN
		        }
		    };

		//make api call
	    axios(search_options)
	    .then(function (response) {
	        // handle success
	        console.log("getListItemsFromTable success", response.data);
	        callback_result(response.data.Body);
	    })
	    .catch(function (error) {
	        // handle error
	        console.log("getListItemsFromTable error", error);
	        if(callback_error)
	        	callback_error(error);
	        else
	        	ApiConnector.showError(error);
  	    })
	}

	/**
	 * Gets the offer types.
	 *
	 * @param      {Function}  callback_result  - function is called when result arrives with result json as parameter
	 * @param      {Function}  callback_error   - error function
	 */
	static getOfferTypes(callback_result, callback_error)
	{
		console.log('getOfferTypes');

		let search_options = {
		        method: 'post',
		        url: Config.API_URL,
		        data: [
						{
							"Type": "OfferTypeItems",
							"MerkmalTypeIDs": [],
							"Fields": [],
							"Values": [],
							"OperatorType": ""
						}
		            ],
		        headers: {
		        Authorization: 'Bearer ' + Config.BEARER_TOKEN
		        }
		    };

		//make api call
	    axios(search_options)
	    .then(function (response) {
	        // handle success
	        console.log("API getOfferTypes success");
	        callback_result(response.data.Body);
	    })
	    .catch(function (error) {
	        // handle error
	        console.log("API getOfferTypes error");
	        if(callback_error)
	        	callback_error(error);
	        else
	        	ApiConnector.showError(error);

	    })
	}

	static getProviders(pProviderId, callback_result, callback_error)
  {
    console.log('getProviders');
    var filterProvider = "";
    if(pProviderId === window.undefined || pProviderId === null || pProviderId.length === 0){
      filterProvider = [
      {
        "Type": "ProviderListItems",
        "MerkmalTypeIDs": [],
        "Fields": [],
        "Values": [],
        "OperatorType": "ALL"
      }
          ]
    }
    else{
      filterProvider = [
      {
        "Type": "ProviderListItems",
        "MerkmalTypeIDs": [pProviderId],
        "Fields": [],
        "Values": [],
        "OperatorType": ""
      }
          ]
    }
    console.log("pProviderId='"+pProviderId+"'");
    let search_options = {
            method: 'post',
            url: Config.API_URL,
            data: filterProvider,
            headers: {
            Authorization: 'Bearer ' + Config.BEARER_TOKEN
            }
        };

    //make api call
      axios(search_options)
      .then(function (response) {
          // handle success
          console.log("API getProviders success");
          callback_result(response.data.Body);
      })
      .catch(function (error) {
          // handle error
          console.log("API getProviders error");
          if(callback_error)
            callback_error(error);
          else
            ApiConnector.showError(error);

      })
  }

	/**
	 * Sends a mail from the contact form.
	 * The sendmail url is read from the Config.js
	 *
	 * @param      {<type>}    mail_data  The mail data
	 * @param      {Function}  callback   The callback
	 */
	static sendMail(mail_data, callback)
	{
		let mail_options = {
		        method: 'post',
		        url: Config.MAIL_URL,
		        data: [mail_data],
		        headers: {
		        	Authorization: 'Bearer ' + Config.BEARER_TOKEN
		        }
		    };

		axios(mail_options)
	    .then(
	    	function (response) {
		        // handle success
		        console.log("Mail Server responds", response);
		        if(response.status === 202)
	    	    	callback(true);
	    	    else
	    	    	callback(false);
	    	},
	    	function (reason) {
		        // handle error
		        console.log("Mail send error", reason);
	    	    callback(false);
	    	}
	    );
	}

	/**
	 * Standard error function.
	 * The error is logged on the console and an error alert is displayed.
	 *
	 * @param      {Object}  error - The error object
	 */
	static showError(error){
		if(!ApiConnector.last_error || ApiConnector.last_error.message != error.message)
		{
			ApiConnector.last_error = error;
			console.log(ApiConnector.last_error);
			window.alert('Es gab einen Fehler mit der Serververbindung. Bitte versuchen Sie es später noch mal.');
		}
	}

	/**
	 * Builds a location filter for the API.
	 *
	 * @param      {string}  location  The location
	 * @param      {int}  radius    The radius
	 * @return     {Object}  The location filter.
	 */
	static buildLocationFilter(location, radius){
		return{
			    "Type": "Geography",
			    "MerkmalTypeIDs": [],
			    "Fields": [],
			    "Values": [ location, radius ],
			    "OperatorType": ""
			};
	}

	/**
	 * Builds a list filter for the API.
	 *
	 * @param      {<type>}  merkmal_type_id - The filter identifier
	 * @param      {<type>}  values - the option values
	 * @return     {Object}  The list filter.
	 */
	static buildListFilter(merkmal_type_id, values){
		return{
			    "Type": "List",
			    "MerkmalTypeIDs": [merkmal_type_id],
			    "Fields": [],
			    "Values": [ ...values ],
			    "OperatorType": ""
			};
	}

	/**
	 * Builds an offer types filter for the API.
	 *
	 * @param      {<type>}  values  The values
	 * @return     {Object}  The offer types filter.
	 */
	static buildOfferTypesFilter(values){
		return{
				"Type": "OfferType",
				"MerkmalTypeIDs": "",
				"Fields": "",
				"Values": values,
				"OperatorType": ""
			};
	}


	/**
	 * Builds a date filter for the API.
	 * date format "dd.mm.YYYY"
	 *
	 * @param      {string}  date_start  The date start
	 * @param      {string}  date_end    The date end
	 * @return     {Object}  The date filter.
	 */
	static buildDateFilter(date_start, date_end){
		return{
			    "Type": "EventDate",
			    "MerkmalTypeIDs": [],
			    "Fields": [],
			    "Values": [ date_start, date_end ],
			    "OperatorType": ""
			};
	}


  /**
  * Builds a Provider filter for the API.
  * OrganisationsId format "guid"
  *
  * @param      {string}  providerId    OrganisationsId
  * @return     {Object}  Organisationsfilter
  */
  static buildOrganisationFilter(providerId){
  	return{
  		    "Type": "ProviderId",
  		    "MerkmalTypeIDs": [providerId],
  		    "Fields": [],
  		    "Values": [],
  		    "OperatorType": ""
  		};
  }



}
ApiConnector.last_error = null;

export default ApiConnector;
